import { Fade } from "react-awesome-reveal";
import { Carousel } from "react-responsive-carousel";

const logo1 = [
  {
    title: "prulife",
    img: "/img/projects/logos/updated/1.png",
    width: '290px',
  },
  {
    title: "megawide",
    img: "/img/projects/logos/updated/2.png",
    width: '100%',
  },
  {
    title: "",
    img: "/img/projects/logos/updated/3.png",
    width: '220px',
  },
];
const logo2 = [
  {
    title: "",
    img: "/img/projects/logos/updated/4.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/5.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/6.png",
  },
];
const logo3 = [
  {
    title: "",
    img: "/img/projects/logos/updated/7.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/8.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/9.png",
  },
];
const logo4 = [
  {
    title: "",
    img: "/img/projects/logos/updated/16.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/17.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/18.png",
  },
];
const logo5 = [
  {
    title: "",
    img: "/img/projects/logos/updated/13.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/14.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/15.png",
  },
];
const logo6 = [
  {
    title: "",
    img: "/img/projects/logos/updated/19.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/20.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/21.png",
  },
];
const logo7 = [
  {
    title: "",
    img: "/img/projects/logos/updated/10.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/11.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/12.png",
  },
];

const logo8 = [
  {
    title: "",
    img: "/img/projects/logos/updated/22.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/23.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/HEROES-OF-HEARING.png",
  },
];

const logo9 = [
  {
    title: "",
    img: "/img/projects/logos/2023-01/BPI.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/GLOBE-BUSINESS.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/KLOOPIFT.png",
  },
];

const logo10 = [
  {
    title: "",
    img: "/img/projects/logos/2023-01/GAWAD.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/M-and-Millenium.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/BELO.png",
  },
];

const logo11 = [
  {
    title: "",
    img: "/img/projects/logos/2023-01/SUPPORT-NINJA.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/RIZOME-LOGO.png",
  },
];

const Bottom = () => {

  return (
    <section
      id="project_bottom_desktop"
      className="bg-[#FFDCC3] md:py-[2rem] md:mb-0 mb-16"
    >
      <Fade cascade={1000}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          stopOnHover={true}
          // autoPlay={true}
          infiniteLoop={true}
          interval={2500}
          emulateTouch={true}
          className="w-[60%] mx-auto"
        >
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo1.map((e, i) => (
                <div className="max-h-[80px]" key={i}>
                  <div style={{ width: e.width }}>
                    <img src={e.img} className="max-w-full" alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo2.map((e, i) => (
                <div className="max-h-[80px]">
                  <img
                    src={e.img}
                    className="max-w-full object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo3.map((e, i) => (
                <div className="max-h-[80px]">
                  <img
                    src={e.img}
                    className="max-w-full object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo4.map((e, i) => (
                <div className="max-h-[80px]">
                  <img
                    src={e.img}
                    className="max-w-full md:max-h-[80px] max-h-[35px] object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo5.map((e, i) => (
                <div className="max-h-[80px]">
                  <img
                    src={e.img}
                    className="max-w-full md:max-h-[80px] max-h-[35px] object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo6.map((e, i) => (
                <div className="max-h-[80px]">
                  <img
                    src={e.img}
                    className="max-w-full md:max-h-[80px] max-h-[35px] object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo7.map((e, i) => (
                <div className="max-h-[80px]">
                  <img
                    src={e.img}
                    className="max-w-full md:max-h-[80px] max-h-[35px] object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          {/* redeploy */}
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo8.map((e, i) => (
                <div className="max-h-[80px] ">
                  <img
                    src={e.img}
                    className="max-w-full md:max-h-[80px] max-h-[35px] object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo9.map((e, i) => (
                <div className="max-h-[80px] ">
                  <img
                    src={e.img}
                    className="max-w-full md:max-h-[80px] max-h-[35px] object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo10.map((e, i) => (
                <div className="max-h-[80px] ">
                  <img
                    src={e.img}
                    className="max-w-full md:max-h-[80px] max-h-[35px] object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo11.map((e, i) => (
                <div className="max-h-[80px] ">
                  <img
                    src={e.img}
                    className="max-w-full md:max-h-[80px] max-h-[35px] object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </Carousel>
      </Fade>
    </section>
  );
};
export default Bottom;