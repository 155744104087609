import { Fade } from "react-awesome-reveal";
import { Carousel } from "react-responsive-carousel";

const logo1 = [
  {
    title: "",
    img: "/img/projects/logos/updated/1.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/2.png",
  },
];
const logo2 = [
  {
    title: "",
    img: "/img/projects/logos/updated/3.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/4.png",
  },
];
const logo3 = [
  {
    title: "",
    img: "/img/projects/logos/updated/5.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/6.png",
  },
];
const logo4 = [
  {
    title: "",
    img: "/img/projects/logos/updated/7.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/8.png",
  },
];
const logo5 = [
  {
    title: "",
    img: "/img/projects/logos/updated/9.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/16.png",
  },
];
const logo6 = [
  {
    title: "",
    img: "/img/projects/logos/updated/17.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/18.png",
  },
];
const logo7 = [
  {
    title: "",
    img: "/img/projects/logos/updated/13.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/14.png",
  },
];
const logo8 = [
  {
    title: "",
    img: "/img/projects/logos/updated/15.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/19.png",
  },
];
const logo9 = [
  {
    title: "",
    img: "/img/projects/logos/updated/20.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/21.png",
  },
];

const logo10 = [
  {
    title: "",
    img: "/img/projects/logos/updated/10.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/11.png",
  },
];
const logo11 = [
  {
    title: "",
    img: "/img/projects/logos/updated/12.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/22.png",
  },
];
const logo12 = [
  {
    title: "",
    img: "/img/projects/logos/updated/23.png",
  },
];
const Bottom = () => {
  return (
    <section
      id="project_bottom_ipad"
      className="bg-[#FFDCC3] pt-[3rem]"
    >
      <Fade cascade={1000}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          stopOnHover={true}
          autoPlay={true}
          infiniteLoop={true}
          interval={2500}
          emulateTouch={true}
          className="w-[60%] mx-auto"

        >
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo1.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo2.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo3.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo4.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo5.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo6.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo7.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo8.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo9.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo10.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo11.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[80px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-[10px] h-full flex items-center justify-center">
            <div className="flex items-center gap-5 justify-center">
              {logo12.map((e, i) => (
                <div className="">
                  <img
                    src={e.img}
                    className="max-w-full max-h-[90px]  object-contain"
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          
        </Carousel>
      </Fade>
    </section>
  );
};
export default Bottom;
