import { useState } from "react";
import LengendaryBrandModal from "../modal/LengendaryBrandModal";
import ReactHtmlParser from "react-html-parser";
import { Fade } from "react-awesome-reveal";
import { useEffect } from "react";

const legendaryBrands = [
  {
    title: "BURNING MAN",
    description: "Final burning effigy of the \nBurning Man",
    long_description: `
        <p class="mb-5">From a small bonfire ritual, they created a grand ceremony that culminates in burning a giant effigy. The Burning Man generated a cult following by harnessing art, self-expression, and inclusive values, transcending into a yearly event that is attended by thousands.</p>
        <p>The National Public Radio in America famously said, "Once considered an underground gathering for bohemians and free spirits of all stripes, Burning Man has since evolved into a destination for social media influencers, celebrities and the Silicon Valley elite.</p>`,
    image: "/img/landing/Burning-Man-min.png",
  },
  {
    title: "APPLE",
    description: "The seamless Apple Store \nbuying experience",
    long_description: `<p class="mb-5">Apple is the largest technology company by revenue and is ranked as one of the world’s most valuable brands. A pillar in the industry, they generated a high net promoter score (NPS) by continually upholding their “Think Different” values throughout the years. They continue to create innovations with simplicity and convenience of users in mind.</p>
        <p>This cultural phenomenon has created a customer base that is rabidly loyal to their products and services.</p>`,
    image: "/img/landing/Apple-min.png",
  },
  {
    title: "STARBUCKS",
    description:
      "personalized the coffee cup by creating a ritual of asking consumers for their names and order",
    long_description: `
        <p>Starbucks personalized the coffee cup by creating a ritual of asking consumers for their names and order. This simple ritual of writing the consumer's preferred drink on the cup with their name became a global staple that everybody remembered.</p>`,
    image: "/img/landing/Starbucks-min.jpg",
  },
  {
    title: "FORWARD",
    description:
      "Doctor's offices of the future \nelevate the healthcare experience",
    long_description: `<p class="mb-5">Reinventing how healthcare is given to people is an unenviable task, yet Forward is here to do it. Dedicated to patient’s long-term health, this company made a giant leap in healthcare by providing state-of-the-art medical products to the world… for free.</p>
        <p>With a dose of Apple, Netflix, and AI in their system, they are currently servicing more than a billion people, and the rest of the industry is playing catch-up to them.</p>`,
    image: "/img/landing/Forward-min.png",
  },
];
const hallmarksBrands = [
  {
    title: "",
    description:
      // 'Crafting a powerful mythology to inspire <br className="md:block hidden" />the audience',
      "Powerful mythology is crafted and shared, inspiring their audiences.",
    image: "/img/landing/hb-1.svg",
  },
  {
    title: "",
    description:
      // 'Turning beliefs into values that <br className="md:block hidden" />consumers can relate to',
      "Beliefs are transformed into brand values that consumers relate with.",
    image: "/img/landing/hb-2.svg",
  },
  {
    title: "",
    description:
      // 'Building on rituals that engage the <br className="md:block hidden" />consumer, from start to finish.',
      "Consumers are engaged through brand rituals established inside the customer journey, from start to finish.",
    image: "/img/landing/hb-3.svg",
  },
  {
    title: "",
    description:
      // 'Transforming curious customers into <br className="md:block hidden" />religious followers.',
      "Casual and curious customers transform into brand fanatics and advocates.",
    image: "/img/landing//hb-4.svg",
  },
  {
    title: "",
    description:
      // 'Create an inclusive community to nurture <br className="md:block hidden" />the brand',
      "A community is created from these brand advocates, and maintained by continuous engagement and nurture.",
    image: "/img/landing//hb-5.svg",
  },
];
const Brands = () => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  const handleClick = (i) => {
    setShowModal(true);
    setSelected(legendaryBrands[i]);
  };

  const body = document.querySelector("body");
  useEffect(() => {
    if (showModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "initial";
    }
  }, [showModal]);

  return (
    <section
      id="brands"
      className="relative flex justify-center bg-[#9C1212] md:py-[8rem] py-4 px-5"
    >
      <div className="container mx-auto flex justify-center items-center flex-col py-[1rem]">
        <div className="mb-2">
          <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[4.5rem]">
            <h3 className="title md:text-3xl text-2xl text-center text-white md:before:-left-[100px] before:-left-[75px] md:after:-right-[100px] after:-right-[75px]">
              <span>FIND INSPIRATION BEHIND THEIR STORIES</span>
            </h3>
          </div>
        </div>
        <div className="text-white text-md text-center md:w-[60%] md:mb-14 mb-5">
          {/* take care of their consumers from attraction to purchase. These icons manifested brand rituals that <br className="md:block hidden" />make customers wanting more.  Do you recognize any of them? Hover over these examples now! */}
          These legendary brands became cultural icons by mastering and
          developing their brand rituals. These iconic, unique experiences
          created millions of customers, and kept them engaging and immersed in
          their brands. They harnessed a community of loyal fan bases that
          fueled their business from years to come. Do you recognize any of
          them?
          {/* These cultural icons manifested brand rituals by making unique
          experiences that customers <br className="md:block hidden" />
          can relate, engage, and finally immerse in—creating their loyal
          fanbase. Do you recognize any of them?{" "} */}
          {/* <br className="md:block hidden" />
          Check out these examples now! */}
        </div>
        <div className="grid sm:grid-cols-2 md:w-[60%] md:gap-x-14 gap-x-7 md:gap-y-8 gap-y-4 mb-10">
          {legendaryBrands.map((e, i) => {
            return (
              <div
                className="group __tile flex items-center justify-center"
                key={`legendary-brands-${i}`}
              >
                <div className="rounded-lg overflow-hidden p-2">
                  <img src={e.image} alt="" />
                </div>
                <div
                  className="group-hover:flex hidden flex-col rounded-lg items-center justify-center absolute top-1/2 left-1/2 -translate-y-1/2  -translate-x-1/2 w-[90%] h-[90%] top-1/2 left-1/2 z-10 animate-fade lg:px-12 px-4"
                  style={{
                    background:
                      "linear-gradient(0deg, rgba(55, 6, 79, 0.85), rgba(55, 6, 79, 0.85))",
                  }}
                >
                  <div className="text-white text-center">
                    <h4 className="lg:text-2xl text-lg">{e.title}</h4>
                    <div className="text-light mb-3 leading-[1rem]">
                      {e.description}
                    </div>
                    <button
                      type="button"
                      className={`__button button-3 flex items-center text-black py-1 lg:px-10 px-[1rem] bg-white rounded-xl md:text-normal text-sm inline mx-auto`}
                      onClick={() => handleClick(i)}
                    >
                      <span>VIEW THIS BRAND</span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mb-10">
          <a
            href="/projects"
            className={`__button button-3 flex items-center text-black md:py-2 py-1 md:px-10 px-4 bg-white rounded-xl lg:text-xl text-sm inline mx-auto`}
          >
            <span>VIEW MORE PROJECTS</span>
          </a>
        </div>
        <div className="text-xs text-white text-center mb-16">
          DISCLAIMER: All case studies above are only for the purpose of
          exemplifying what a legendary brand should be. Tagline 360 has no
          involvement in their making.
        </div>
        <div className="">
          <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[4.5rem]">
            <h3 className="title md:text-3xl text-2xl text-center text-white md:before:-left-[100px] before:-left-[75px] md:after:-right-[100px] after:-right-[75px]">
              <span>
                {/* THE <br className="sm:hidden flex" /> */}
                HALLMARKS OF <br className="sm:hidden flex" />
                LEGENDARY BRANDS
              </span>
            </h3>
          </div>
        </div>
        <div className="text-white text-center md:w-[70%] mb-10">
          Legendary brands develop their brand identities, craft powerful
          mythology around them, and provide consumers with elevated experiences
          best described as “Sacred”. When you look at legendary brands, they go
          through the following process:
          {/* They take their brand identities, craft
          powerful mythology around them, and provide consumers with elevated{" "}
          <br className="md:block hidden" />
          experiences best described as Sacred. When you look at legendary
          brands, they go through the following process: */}
        </div>
        <div className="flex justify-center w-10/12 flex-wrap gap-10 columns-2 md:mb-16 mb-10">
          {hallmarksBrands.map((e, i) => {
            return (
              <div
                className="flex flex-col items-center justify-start w-[325px] "
                key={`hallmark-brands-${i}`}
              >
                <div className="__brand mb-4 shrink-0 relative stained-glass--- stained-glass-hallmarks transform-gpu">
                  <img src={e.image} alt="" />
                </div>
                <div className="text-white text-center">
                  <div className="text-light">
                    {ReactHtmlParser(e.description)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="text-white text-center md:w-[70%] mb-10">
          {/* We provide engaging brand rituals to consumers to convert brands from
          undesirable to undeniable.
          <br className="md:block hidden" />
          Are you interested in altering the path of your brand to greatness? */}
          Our Brand Religion Blueprint Session will help you start developing
          your unique brand experiences. <br className="md:block hidden" />
          Schedule your free 30-minute session below.
        </div>
        <a
          className={`__button button-3 button-3-2 text-primary py-2 md:px-10 px-4 bg-white rounded-xl md:text-xl text-sm text-light inline`}
          target="_blank"
          href="https://meetings.hubspot.com/francis-miranda/free-brand-religion-audit?embed=true"
          rel="noopener noreferrer"
        >
          <span>START CRAFTING YOUR BRAND EXPERIENCES</span>
        </a>
      </div>
      {showModal && (
        <LengendaryBrandModal
          show={showModal}
          close={() => setShowModal(false)}
          selected={selected}
        />
      )}
    </section>
  );
};
export default Brands;
