import "./Brandology.css";

export default function Brandology() {
  return (
    <section id="brandology" className="relative">
      <div className="grid md:grid-cols-[45%_55%]">
        {/* <div className='w-full h-full' style={{ background: 'url(/img/landing/brandology.jpg) center/cover no-repeat' }}> */}
        <div className="w-full h-full">
          <div className="relative">
            <div className="">
              <img src="/img/landing/Brandology-background-min.png" alt="" />
            </div>
            <div className="stained-glass absolute top-[8.3%] left-[9%] w-[82%] h-[82%] rounded-full overflow-hidden">
              <img src="/img/landing/Brandology-logo-min.png" alt="" />
            </div>
          </div>
        </div>
        <div className="text-center content flex items-center justify-center flex-col md:px-2 px-5 py-[1rem] bg-[#ffdec2]">
          <div className="w-11/12">
            <div className="md:text-3xl text-2xl text-left text-[#6E1363] mb-3 font-ghaitsa text-center">
              Transform your Company<br className="md:block hidden" /> and
              join the ranks<br className="md:block hidden" /> of Legendary Brands.
            </div>
            <div className="md:text-lg text-md text-[#9C1212] mb-5 text-center">
              Tagline 360 has created a unique approach that allows brands to
              transform audiences to brand believers, and brand believers into
              brand fanatics. Harness the power of influential brand mythology,
              contagious brand rituals, and modern, but timeless influence for
              your brand.
            </div>
            {/* <a
              className={`__button button-2 text-white py-2 md:px-10 px-4 bg-primary rounded-xl md:text-xl text-sm text-light inline ipad-brandology`}
              target="_blank"
              href="https://meetings.hubspot.com/francis-miranda/free-brand-religion-audit?embed=true"
              rel="noopener noreferrer"
            >
              <span>Become a Legendary Brand Today</span>
            </a> */}
            <a
              className={`__button button-2 text-white py-2 md:px-10 px-4 bg-primary rounded-xl md:text-xl text-sm text-light inline ipad-brandology`}
              href="/projects"
              rel="noopener noreferrer"
            >
              <span>VIEW MORE PROJECTS</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
