import React, {useState, useEffect} from 'react';
import Vimeo from "@u-wave/react-vimeo";
import { FaRegEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";

import Manifestitions from 'src/components/about/Manifestitions';
import Bottom from 'src/components/Bottom';
import Messenger from 'src/components/about/Messenger';

// ** Modal Imports
import HubspotContactForm from 'src/components/forms/HubSpotForms/HubSpotContactForm';

function About() {
  // ** States
  const [showFormModal, setFormModal] = useState(false)
  const [region, setRegion] = useState("")
  const [portalId, setPortalId] = useState("")
  const [formId, setFormId] = useState("")

  const handleModal = (region, portalId, formId) => {
    setRegion(region)
    setPortalId(portalId)
    setFormId(formId)
    setFormModal(true)
  }

  const handleClose = () => {
    setFormModal(false)
  }

  return (
    <div className="max-w-[1920px] block mx-auto overflow-x-hidden">
      <div id="aboutUsPage" >
        <Messenger modal={handleModal} />
        <Manifestitions />
        <Bottom />
        <div id="topMost"></div>
      </div>
    </div>
    

  );
}

export default About;

