import React, {useState, useEffect} from 'react';

import Download from 'src/components/workWithUs/Download';
import Convert from 'src/components/workWithUs/Convert';
import Bottom from 'src/components/Bottom';

// ** Modal Imports
import DynamicFormModal from 'src/components/modal/DynamicFormModal'

function WorkWithUs() {
  // ** States
  const [showFormModal, setFormModal] = useState(false)
  const [region, setRegion] = useState("")
  const [portalId, setPortalId] = useState("")
  const [formId, setFormId] = useState("")
  const [header, setHeader] = useState("")
  const [subheader, setSubheader] = useState("")
  const [showModal, setShowModal] = useState(false)

  const handleModal = (region, portalId, formId, header, subheader) => {
    console.log(region)
    console.log(portalId)
    console.log(formId)
    setShowModal(false)
    setRegion(region)
    setPortalId(portalId)
    setFormId(formId)
    setHeader(header)
    setSubheader(subheader)
    setFormModal(true)
  }

  const handleClose = () => {
    setRegion('')
    setPortalId('')
    setFormId('')
    setHeader('')
    setSubheader('')
    setFormModal(false)
  }

  return (
    <div className="max-w-[1920px] block mx-auto overflow-x-hidden">
      <div id="workWithUs" >
        <Convert modal={handleModal} showModal={showModal} setShowModal={setShowModal}/>
        <Download modal={handleModal}/>
        <Bottom />
        <div id="topMost"></div>
        <DynamicFormModal 
          show={showFormModal} 
          close={() => handleClose()} 
          region={region} 
          portalId={portalId} 
          formId={formId}
          header={header}
          subheader={subheader} />
      </div>
    </div>
    

  );
}

export default WorkWithUs;

