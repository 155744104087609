import React, {useState, useEffect} from 'react';
import Vimeo from "@u-wave/react-vimeo";
import { FaRegEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";

import Header from 'src/components/navigations/Header';
import Banner from 'src/components/landing/Banner';
import Brandology from 'src/components/landing/Brandology';
import Brands from 'src/components/landing/Brands';
import Pillars from 'src/components/landing/Pillars';
import Evangelizers from 'src/components/landing/Evangelizers';
import Receive from 'src/components/landing/Receive';
// import Blogs from 'src/components/landing/Blogs';
import Bottom from 'src/components/Bottom';
import { Fade, Bounce } from "react-awesome-reveal";

// ** Modal Imports
import DynamicFormModal from 'src/components/modal/DynamicFormModal'

function Landing() {
  // ** States
  const [showFormModal, setFormModal] = useState(false)
  const [region, setRegion] = useState("")
  const [portalId, setPortalId] = useState("")
  const [formId, setFormId] = useState("")

  const handleModal = (region, portalId, formId) => {
    setRegion(region)
    setPortalId(portalId)
    setFormId(formId)
    setFormModal(true)
  }

  const handleClose = () => {
    setFormModal(false)
  }

  return (
    <div className="max-w-[1920px] block mx-auto">
      <div id="landingPage" >
        <Banner />
        <Brandology />
        <Brands />
        <Pillars />
        <Receive modal={handleModal}/>
        {/* <Blogs /> */}
        <Bottom />
        <div id="topMost"></div>
        <DynamicFormModal 
          show={showFormModal} 
          close={() => handleClose()} 
          region={region} 
          portalId={portalId}
          formId={formId}
          header='Talk to Us Today!'
          subheader='Fill in the details below and our brand evangelist will be in touch with you to discuss more about our price plans.' />
      </div>
    </div>
    

  );
}

export default Landing;

