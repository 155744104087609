import ReactHtmlParser from "react-html-parser";

const project = [
  {
    id: "1",
    title: "3Comply",
    website: "https://www.3comply.com",
    photos: "Use Stock Photos",
    img: "/img/projects/main/bbb.png",
    subheadline:
      "Explain it in 5 Seconds: Transforming 3Comply's Digital Presence to Drive Conversions ",
    introduction: `
    3Comply, a B2B business aiding companies struggling with government accreditation and data management faced significant challenges. The intricacy of their services made it difficult for potential clients to understand their offerings, leading to conversion difficulties. They needed a solution to simplify their complex services into digestible information for potential customers. <br><br>
    Tagline 360 tackled this challenge head-on, utilizing the brand religion methodology to create a strong, understandable, relatable brand story that resonates with the target audience.
        `,
    challenge: `
    3Comply's main challenge was the complexity of its services, which made it hard for potential customers to understand what they offered, leading to low conversion rates. They needed a way to break down their offerings into easy-to-understand formats to drive customer comprehension and, ultimately, conversions.
        `,
    solution: `
    Tagline 360 decided to take a multi-pronged approach to address 3Comply's challenges: <br>
    <div class="pl-5">
    1. Website Redesign: Tagline 360 designed a modern, intuitive website that broke down and explained 3Comply's services. The new design focused on user experience, ensuring potential customers could easily navigate the site and understand the services offered. <br>
    2. Use of Infographics: To further simplify the understanding of 3Comply's complex services, Tagline 360 created and integrated infographics into the web design. These visual aids allowed potential customers to grasp the services quickly, enhancing their comprehension. <br>
    3. Service Tiering System: To drive faster conversions, Tagline 360 worked with 3Comply to devise a tiering system for their services. By creating different products with various price points, potential customers could more easily engage with 3Comply, leading to increased conversions.
    </div>
    `,
    result: `
    Tagline 360's strategic approach significantly improved 3Comply's conversion rates. By simplifying the comprehension of potential customers through website redesign, use of infographics, and service tiering, 3Comply saw a notable increase in conversions due to the enhanced ability of consumers to understand their offerings quickly.
    `,
    conclusion: `
    This case study exemplifies Tagline 360's expertise in leveraging the brand religion methodology to transform a brand's digital presence and drive conversions. Through strategic planning and implementation, we were able to simplify 3Comply's complex services, making them easily understandable for potential customers and ultimately driving faster conversions. <br><br>
    By trusting in Tagline 360's expertise, 3Comply was able to overcome its challenges and significantly improve its conversion rates - a testament to our commitment to delivering results that matter to our clients.
    `,
  },
];

const Landing = () => {
  return (
    <>
      {project.map((e, i) => {
        return (
          <section
            id="titleSection"
            className="relative bg-[#FFDEC1] md:pb-[3rem]"
          >
            <div className="mx-auto bg-[#000000] md:mb-8">
              <div className="container- mx-auto max-w-[1080px]-">
                <div className="block overflow-hidden">
                  <img
                    src={e.img}
                    alt=""
                    srcset=""
                    className="object-cover object-top w-full"
                  />
                </div>
              </div>
            </div>
            <div className="container mx-auto flex justify-center items-center flex-col md:max-w-[80%] px-5">
              <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-10 mb-10">
                <h3 className="md:text-2xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                  <span>{ReactHtmlParser(e.title)}</span>
                </h3>
                <h4 className="md:text-xl text-lg text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                  <span>{ReactHtmlParser(e.subheadline)}</span>
                </h4>
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Introduction</span>
                <br />
                {ReactHtmlParser(e.introduction)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Challenge</span>
                <br />
                {ReactHtmlParser(e.challenge)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">The Solutions</span>
                <br />
                {ReactHtmlParser(e.solution)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">The Results</span>
                <br />
                {ReactHtmlParser(e.result)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Conclusion</span>
                <br />
                {ReactHtmlParser(e.conclusion)}
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default Landing;
