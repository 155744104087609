import { Fade } from "react-awesome-reveal";
import WorkWithUsModal from "../modal/WorkWithUsModal";
import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./whoweare.scss";
import Bottom from './Messenger-bottom';

const messengers = [
  {
    title: "JOVY RABELAS",
    subtitle:
      "Founder & Global President, <br class='block' />The Tagline Group",
    description:
      "Jovy Rabelas is the founder of Tagline Group of Companies—commanding 17 companies under it. With expertise spanning various industries, Jovy's versatile and visionary approach has led to the establishment of successful ventures, the latest of which is Tagline 360.<br><br>Before diving into the industry, Jovy took and finished an Executive Business Management Program at Zicklin School of Business in New York, and a Global Marketing Program at UCLA. Over the years, Jovy's efforts led to Tagline Group's expansion of services, establishment of a robust client network, and significant growth within the Philippine advertising industry. His work with market leaders and brand icons has garnered multinational awards and acclaim. A wanderlust and design enthusiast, Jovy also pursued  interior design  at the British Academy in London.",
    img: "/img/whoweare/1",
    srcset: "",
    class: "",
  },
  {
    title: "FRANCIS MIRANDA",
    subtitle: "CEO, Tagline 360",
    description:
      "Francis Miranda is the award-winning CEO of Tagline 360. He utilizes his brand religion methodology to transform companies into iconic brands. As the founder of the Church of Brandology, Francis's unwavering belief in the power of creativity and the ability to evoke emotions drive his approach to business, converting mere customers to true believers.<br><br>With experiences spanning over two decades, Francis has worked with different multinational advertising agencies in Manila, Barcelona, Boston, and Kuala Lumpur. He is also the CEO for Skills Simplified, facilitating comprehensive learning experiences for individuals and organizations. Creative at heart, he founded the #UnapologeticMovement, bringing positive change to his unapologetic community in an irreverent and funny way. Francis believes that when it comes to business—it's not about what you know but how you make others feel.",
    img: "/img/whoweare/2",
    srcset: "",
    class: "md:order-2",
  },
  // {
  //   title: "JOHN DELA CRUZ",
  //   subtitle: "Chief Creative Officer",
  //   description:
  //     "John Dela Cruz serves as the Chief Creative Officer, combining his strategic expertise with creative prowess to shape strategies for the network and its affiliated agencies. A true visionary, he champions creative engineering, and pushes for excellence through innovation.<br><br>An accomplished creative, he boasts a remarkable 25-year career in the advertising industry. He initially made his mark at Saatchi & Saatchi, working on the Philippines largest telecommunications firm PLDT and global giant, Petron. Later, he embarked on a series of groundbreaking campaigns with Brand Asia, Ltd., working on prestigious brands such as Nissan, Red Bull, Bosch, and Bayer. He is also a passionate film maker, and also delved into directing and production of short stories and television commercials.",
  //   img: "/img/whoweare/3",
  //   srcset: "",
  //   class: "",
  // },
  {
    title: "Anika Punzalan",
    subtitle: "Digital Group Head",
    description:
      "Anika Punzalan is an experienced marketing communications professional, serving as the Group Managing Director for Digital. She has over 15 years of experience and success in business strategy, operations, and integrated marketing communications, working for the top Philippine conglomerate SM Prime Holdings, Inc. and global financial leader AIA.<br><br>A digital transformation advocate, she champions creative innovations to bridge market gaps and nurture meaningful engagements, helping brands craft their marketing architecture for maximum impact. Some of her works have gained local and international distinctions from the Asia Pacific as well as International Business Awards, Anvil, Quill, and even the Philippine and Asia Pacific Property Awards. Under her wing, the Tagline Group has been recognized as the Digital Transformation Agency for the Philippines in the 2021 Southeast Asian Business Awards.",
    img: "/img/whoweare/4",
    srcset: "",
    class: "",
  },
  {
    title: "Mennen Aracid",
    subtitle: "Business Management Consultant",
    description:
      "Mennen Aracid has been the group’s business consultant since 2009. A Strengths Strategy Certified Coach, he helps Tagline 360’s leadership team tap into and discover, apply, and be excellent in each of their 34 leadership competencies as featured in Clifton Strengths Finder. He also facilitates leadership and strategic planning sessions, and conducts surveys around Engagement, Organizational Climate, Customer Satisfaction, and Net Promoter Scores.<br><br>Mennen finished his BS in Legal Management at the Ateneo de Manila University and Masters in Business Administration at Xavier University. He is also currently a faculty member at the Ateneo Graduate School of Business.",
    img: "/img/whoweare/5",
    srcset: "",
    class: "md:order-2",
  },
  {
    title: "Liz Capants",
    subtitle: "Business Development Manager",
    description:
      "Liz Capants identifies and creates new market possibilities for Tagline 360. She analyzes market trends and competitor activities to stay ahead in the industry and drive innovation within the organization.<br><br>Liz’s educational journey started at St. Bonaventure University with a Bachelor’s degree in Mass Communication/Media. She then followed it up with a Business Management Certificate at New York University, and an International Baccalaureate at Marymount International School London. With a dedicated approach and a unique strategy, she has had the privilege of working with organizations in talent acquisition and executives at various career stages. ",
    img: "/img/whoweare/6",
    srcset: "",
    class: "",
  },
  {
    title: "Therlande Louissaint",
    subtitle: "Event Manager",
    description:
      "A highly motivated interdisciplinary professional, Therlande Louissaint bring in her imaginative perspective to build functions and activations that tailor-fit the objectives and expectations of our partners.<br><br>Therlande’s aptitude in community building started with her stint as the creative stage director, choreographer, and movement coach for Berklee College of Music. She also taught a Movement for Musicians course as part-time faculty, while developing the majority of the college’s shows. With years of experience as a creative director and producer, Therlande also co-founded SIDE—an events company that emphasizes on uplifting small businesses in Black, Indigenous, and People of Color (BIPOC) communities.",
    img: "/img/whoweare/7",
    srcset: "",
    class: "md:order-2",
  },
  {
    title: "Marlyn Urquiza",
    subtitle: "Event Manager",
    description:
      "With a wealth of experience in producing diverse events of varying scales and complexities, Marlyn Urquiza brings an art and culture-driven standpoint to the projects she has helped manage.<br><br>After gaining a Psychology degree from Newbury College, she delved into property management—honing her administrative, organizational, and supervisory skills. Marlyn is one of the founders of SIDE, where she curates experiences that inspire individuals to embrace their creativity and celebrate their uniqueness. With a tenure reaching multiple industries, she goes beyond conventional event management to create transformative experiences for any kind of audience.",
    img: "/img/whoweare/8",
    srcset: "",
    class: "",
  },
  {
    title: "Walter Derrig",
    subtitle: "Event Manager",
    description:
      "Walter Derrig’s passion includes  entertainment and fitness, enabling him to create movement-based experiences that get audiences to participate in an engaging and active way.<br><br>He also serves as Director of Clients Events for Bounce Sporting Club, leading in-house events on entertainment and VIP services. Excelling in budget management, business development, and venue logistics, Walter provides prompt and composed solutions to ensure that events run smoothly while meeting the expectations of our partners.",
    img: "/img/whoweare/9",
    srcset: "",
    class: "md:order-2",
  },
];
const Messenger = (props) => {
  // ** Props
  const { modal, showModal, setShowModal } = props;

  // ** States
  // const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  const handleClick = (i) => {
    setShowModal(true);
    setSelected(messengers[i]);
  };

  return (
    <section
      id="aboutUs"
      className="overflow-hidden relative flex justify-center bg-[#FFDEC1] md:py-[8rem] py-4 px-5"
    >
      <div className="upper-left-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="left"
          >
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="upper-right-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="right"
          >
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="container mx-auto flex justify-center items-center flex-col w-[100vw] pb-[4.5rem]">
        <div className="ipad-header w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-16">
          <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
            <span>Meet the Congregation <br class="block"/>of Brand Religion</span>
          </h3>
        </div>
        <div className="ipad-subheader sm:text-lg text-md text-[#9C1212] text-center mb-16">
        Introducing the revered leaders and seasoned experts who guide businesses on an enlightening path towards industry transcendence.
        <br class="block"/>
        Through their devotion to the divine principles of Brandology, they elevate brands that penetrate culture and consciousness.
          </div>
        {/* <div className="sm:text-lg text-md text-[#9C1212] text-center mb-16">
          Tagline 360 is a creative collective, composed of brand evangelists performing various liturgies
          <br className="md:block hidden"/>
          to elevate your follower experience.
        </div> */}
        <Fade duration={2000} cascade={true} damping={1}>
          <div className="md:mb-16 max-w-[1040px] mt-10">
            {messengers.map((e, i) => {
              return (
                <div key={e.title} className="grid md:grid-cols-12 mb-10">
                  <div
                    className={`md:col-span-6 col-span-12 stained-glass-- stained-glass-messenger relative transform-gpu ${e.class}`}
                  >
                    <img
                      src={`${e.img}.png`}
                      className="mx-auto lg:max-w-[400px]"
                      sizes="(max-width: 575px) 300px, 400px"
                      srcSet={`${e.img}.png 400w, ${e.img}.png 300w`}
                      alt=""
                    />
                  </div>
                  <div
                    className={`ipad-whoweare col-span-6 flex items-center md:text-left text-center mx-5 md:ml-[80px]`}
                  >
                    {/* <div className="scrollbar-section">
                      <h4 className="text-[#6E1363] leading-2 uppercase md:text-[25px] text-xl">
                        {ReactHtmlParser(e.title)}
                      </h4>
                      <h5 className="text-[#6E1363] leading-2 uppercase md:text-[20px] text-md mb-4">
                        {ReactHtmlParser(e.subtitle)}
                      </h5>
                      <div className="sm:text-lg text-md h-[250px] w-[350px] mb-5 text-[#9C1212] mx-auto mobile-whoweare leading-2 overflow-y-scroll">
                        {ReactHtmlParser(e.description)}
                      </div>
                    </div> */}

                    <div className="scrollbar-section">
                      <h4 className="text-[#6E1363] leading-2 uppercase md:text-[25px] text-xl">
                        {ReactHtmlParser(e.title)}
                      </h4>
                      <h5 className="text-[#6E1363] leading-2 uppercase md:text-[20px] text-md mb-4">
                        {ReactHtmlParser(e.subtitle)}
                      </h5>
                      <div className="sm:text-lg text-md h-[250px] mb-5 text-[#9C1212] mx-auto mobile-whoweare leading-2 overflow-y-scroll">
                        {ReactHtmlParser(e.description)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <Bottom/>
          </div>
        </Fade>
      </div>
      <div className="bottom-left-star z-9 w-[100%] h-[150px]">
        <div className="relative ">
          <Fade cascade={true} fraction={1} damping={1} duration={500}>
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7888-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7887-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7889-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="bottom-right-star z-9 w-[100%] h-[150px]">
        <div className="relative ">
          <Fade cascade={true} fraction={1} damping={1} duration={500}>
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7885-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7884-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7886-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      {showModal && (
        <WorkWithUsModal
          show={showModal}
          close={() => setShowModal(false)}
          selected={selected}
          modal={modal}
        />
      )}
    </section>
  );
};
export default Messenger;
