import React, { useState, useEffect, useRef } from "react";
import { Fade, Bounce } from "react-awesome-reveal";
import ReactHtmlParser from "react-html-parser";
import "./carousel.scss";
import items from "./data";

import Modal from "./modal";
import Video from "./video";

const LandingCarousel = () => {
    // ** Refs
    const carouselLink = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const [carouselActive, setCarouselActive] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState();

    let currIndex = activeIndex ? activeIndex : 0;

    const prev = () => {
        currIndex--;

        var carouselItems = document.querySelectorAll(
            ".carousel .__carousel-item"
        );
        if (currIndex < 0) {
            console.log("enter");
            currIndex = carouselItems.length - 1;
        }
        setShowModal(false);

        loadImage(currIndex);
    };
    const next = () => {
        currIndex++;

        var carouselItems = document.querySelectorAll(
            ".carousel .__carousel-item"
        );
        if (currIndex > carouselItems.length - 1) {
            currIndex = 0;
        }

        if (currIndex < 0) {
            currIndex = carouselItems.length - 1;
        }
        setShowModal(false);

        loadImage(currIndex);
    };

    const loadImage = (x) => {
        const image = new Image();
        image.onload = function () {};
        image.src = "";

        setActiveIndex(x);
        setShowContent(false);

        setTimeout(() => {}, 0);
    };

    const handleBlur = (e) => {
        setCarouselActive(false);
    };

    const handleFocus = (e) => {
        const currentTarget = e.currentTarget;
        if (currentTarget === document.activeElement) {
            setCarouselActive(true);
        }
    };

    useEffect(() => {
        // console.log(activeIndex);
        setTimeout(() => {
            setShowContent(true);
        }, 1000);
    }, [activeIndex]);

    useEffect(() => {
        const close = (e) => {
            if (carouselActive) {
                if (e.key === "ArrowRight") {
                    next();
                } else if (e.key === "ArrowLeft") {
                    prev();
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [carouselActive]);

    const handleClick = (i) => {
        setShowModal(true);
        setSelected(items[i]);
        console.log("here");
    };

    const playButton = "/img/production/play-button.png";

    return (
        <div className="relative" id="production">
            <div className="carousel">
                <div className="__carousel-inner md:pb-[100px] pb-[50px] stained-glass-- stained-glass-messenger">
                    {items.map((e, i) => {
                        return (
                            <div
                                className={`__carousel-item cursor-pointer  ${
                                    activeIndex === i ? "active" : ""
                                }`}
                                key={e.title}
                                onClick={() => {
                                    handleClick(i);
                                }}
                            >
                                <Fade>
                                    <div className="group relative">
                                        <div className="lg:max-w-[760px] ipad-pro">
                                            {!Array.isArray(e.image) && (
                                                <img src={e.image} alt="" />
                                            )}
                                        </div>
                                        <img
                                            src={playButton}
                                            alt=""
                                            className="absolute top-[35%] left-[45%] z-20 playButton"
                                        />
                                    </div>
                                </Fade>
                            </div>
                        );
                    })}
                    <div
                        className={`video-content-container  ${showModal ? "video-play" : "video-pause"}`}
                    >
                        {showModal ? (
                            <video
                                id="video"
                                className="video-content"
                                controls
                                autoPlay
                                preload="yes"
                                playsInline
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                }}
                            >
                                <source src={selected.video} type="video/mp4" />
                            </video>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="__carousel-nav">
                    <div className="__prev" onClick={prev}>
                        <span></span>
                    </div>
                    <div className="__next" onClick={next}>
                        <span></span>
                    </div>
                </div>
                {/* <ol className="__carousel-indicator">
          <li
            className={activeIndex === 0 ? "active" : ""}
            data-slide-to="1"
            onClick={() => loadImage(0)}
          ></li>
          <li
            className={activeIndex === 1 ? "active" : ""}
            data-slide-to="2"
            onClick={() => loadImage(1)}
          ></li>
          <li
            className={activeIndex === 2 ? "active" : ""}
            data-slide-to="3"
            onClick={() => loadImage(2)}
          ></li>
          <li
            className={activeIndex === 3 ? "active" : ""}
            data-slide-to="4"
            onClick={() => loadImage(3)}
          ></li>
          <li
            className={activeIndex === 4 ? "active" : ""}
            data-slide-to="5"
            onClick={() => loadImage(4)}
          ></li>
          <li
            className={activeIndex === 5 ? "active" : ""}
            data-slide-to="6"
            onClick={() => loadImage(5)}
          ></li>
          <li
            className={activeIndex === 6 ? "active" : ""}
            data-slide-to="7"
            onClick={() => loadImage(6)}
          ></li>
          <li
            className={activeIndex === 7 ? "active" : ""}
            data-slide-to="8"
            onClick={() => loadImage(7)}
          ></li>
          <li
            className={activeIndex === 8 ? "active" : ""}
            data-slide-to="9"
            onClick={() => loadImage(8)}
          ></li>
          <li
            className={activeIndex === 9 ? "active" : ""}
            data-slide-to="10"
            onClick={() => loadImage(9)}
          ></li>
          <li
            className={activeIndex === 10 ? "active" : ""}
            data-slide-to="11"
            onClick={() => loadImage(10)}
          ></li>
          <li
            className={activeIndex === 11 ? "active" : ""}
            data-slide-to="12"
            onClick={() => loadImage(11)}
          ></li>
          <li
            className={activeIndex === 12 ? "active" : ""}
            data-slide-to="13"
            onClick={() => loadImage(12)}
          ></li>
          <li
            className={activeIndex === 13 ? "active" : ""}
            data-slide-to="14"
            onClick={() => loadImage(13)}
          ></li>
          <li
            className={activeIndex === 14 ? "active" : ""}
            data-slide-to="15"
            onClick={() => loadImage(14)}
          ></li>
          <li
            className={activeIndex === 15 ? "active" : ""}
            data-slide-to="16"
            onClick={() => loadImage(15)}
          ></li>
        </ol> */}
            </div>
            {/* {showModal && (
                <Modal
                    show={showModal}
                    close={() => setShowModal(false)}
                    selected={selected}
                />
            )} */}
        </div>
    );
};
export default LandingCarousel;
