import React, {useState, useEffect} from 'react';
import Vimeo from "@u-wave/react-vimeo";
import { FaRegEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";

import Main from "src/components/projects/item/consultancy";
import { Fade, Bounce } from "react-awesome-reveal";

// ** Modal Imports
import DynamicFormModal from 'src/components/modal/DynamicFormModal'

function Landing() {
  // ** States
  const [showFormModal, setFormModal] = useState(false)
  const [region, setRegion] = useState("")
  const [portalId, setPortalId] = useState("")
  const [formId, setFormId] = useState("")

  const handleModal = (region, portalId, formId) => {
    setRegion(region)
    setPortalId(portalId)
    setFormId(formId)
    setFormModal(true)
  }

  const handleClose = () => {
    setFormModal(false)
  }

  return (
    <div className="max-w-[1920px] block mx-auto">
      <div id="consultancy" >
        <Main/>
      </div>
    </div>
    

  );
}

export default Landing;

