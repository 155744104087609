import React, {useEffect} from 'react';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import ReactHtmlParser from 'react-html-parser';
import { Fade, Bounce } from "react-awesome-reveal";
import ResponsiveEmbed from 'react-responsive-embed';
const customStyles = {
 content: {
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // minWidth: '768px',
  // minHeight: '90vh',
  // overflow: 'hidden',
  padding: '0'
 },
 overlay: {
  backgroundColor: 'rgba(0,0,0,0.85)',
  zIndex: 1050,
 }
}

Modal.setAppElement('#root');


export default class BlogModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      loading: false,
      selected: this.props.selected
    }
    this.OpenModal = this.OpenModal.bind(this);
    this.CloseModal = this.CloseModal.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
  }
  
  // componentDidMount() {
  //   this.state.selected = JSON.stringify(this.props.selected)
  //   console.log(this.state.selected)
  // }

  OpenModal() {
    this.setState({modalIsOpen:true})
  }
  CloseModal() {
    this.setState({modalIsOpen: false})
  }
  toggleLoader() {
    this.setState({ loading: this.state.loading ? false:true })
  }

  render() {
    // const { ...result } = this.state.selected;
    return (
      <>
        <Modal 
          isOpen={this.props.show}
          style={customStyles}
          className="w-4/5 max-h-[90vh] overflow-auto md:absolute relative top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]"
        >
          <Fade>
            <div className="w-full h-full block mx-auto bg-[#FFF3EA]">
              <div className="relative w-xl">
                <div className="absolute right-3 top-3 z-20">
                  <button className="text-4xl text-[#C2082A] z-20" onClick={this.props.close}>
                    <AiFillCloseCircle />
                  </button>
                </div>
                <div className='overflow-scroll relative z-10'>
                  <div>
                    {/* <img src={result.image2x} height={300} className="w-full h-[300px] object-cover" alt="" /> */}
                  </div>
                  <div className="block mx-auto">
                    <div className="embed-hspt-blog">
                      {/* <h3 className="title text-3xl leading-7">{ result.title }</h3> */}
                      <ResponsiveEmbed src={this.state.selected} allowfullscreen ratio='4:3' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
}
