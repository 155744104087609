import React, { useState, useEffect, useRef } from "react";
import { Fade, Bounce } from "react-awesome-reveal";
import ReactHtmlParser from "react-html-parser";

const items = [
  {
    title: "Scale your business through predictable growth!",
    description:
      "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
    buttonText: "Start Growing My Brand",
    link: "#",
    image: [
      "/img/landing/banner/1-2-min.jpg",
      "/img/landing/banner/2-2-min.png",
      "/img/landing/banner/4-min.PNG",
      "/img/landing/banner/3-min.PNG",
    ],
  },
  {
    title: "Design customer service experiences that convert!",
    description:
      "Win consumer loyalty through memorable and contagious brand experiences unique to YOU. <br class='lg:block hidden' />Create loyalty from current followers, while creating new relationships for nurture.",
    buttonText: "Start Converting My Customers",
    link: "#",
    image: "/img/landing/Carousel-2-2-min.jpg",
  },
  {
    title: "Transform audiences into believers!",
    description:
      "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
    buttonText: "Start Transforming My Audience",
    link: "#",
    image: "/img/landing/Carousel-3-3-min.jpg",
  },
];

const LandingCarousel = () => {
  // ** Refs
  const carouselLink = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [carouselActive, setCarouselActive] = useState(false);

  let currIndex = activeIndex ? activeIndex : 0;

  const prev = () => {
    currIndex--;

    var carouselItems = document.querySelectorAll(".carousel .__carousel-item");
    if (currIndex < 0) {
      console.log("enter");
      currIndex = carouselItems.length - 1;
    }

    loadImage(currIndex);
  };
  const next = () => {
    currIndex++;

    var carouselItems = document.querySelectorAll(".carousel .__carousel-item");
    if (currIndex > carouselItems.length - 1) {
      currIndex = 0;
    }

    if (currIndex < 0) {
      currIndex = carouselItems.length - 1;
    }

    loadImage(currIndex);
  };

  const loadImage = (x) => {
    const image = new Image();
    image.onload = function () {};
    image.src = "";

    setActiveIndex(x);
    setShowContent(false);

    setTimeout(() => {}, 0);
  };

  const handleBlur = (e) => {
    setCarouselActive(false)
  };

  const handleFocus = (e) => {
    const currentTarget = e.currentTarget;
    if (currentTarget === document.activeElement) {
      setCarouselActive(true)
    }
  }

  useEffect(() => {
    // console.log(activeIndex);
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, [activeIndex]);

  useEffect(() => {
      const close = (e) => {
        if (carouselActive) {
          if (e.key === "ArrowRight") {
            next()
          } else if (e.key === "ArrowLeft") {
            prev()
          }
        }
      }
      window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[carouselActive])

  return (
    <div className="relative" id="mainContentStart">
      <div className="carousel">
        <div className="__carousel-inner">
          {items.map((e, i) => {
            return (
              <div
                className={`__carousel-item ${
                  activeIndex === i ? "active" : ""
                }`}
                key={e.title}
              >
                <Fade>
                  <div className="group relative">
                    <div className="">
                      {Array.isArray(e.image) && (
                        <Fade
                          cascade={true}
                          triggerOnce={true}
                          direction="up"
                          duration={450}
                          className="custom-image-animation"
                        >
                          {e.image.map((image, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  index > 0
                                    ? `absolute top-0 w-full h-full`
                                    : ""
                                }
                              >
                                <img src={image} alt="" />
                              </div>
                            );
                          })}
                        </Fade>
                      )}
                      {!Array.isArray(e.image) && <img src={e.image} alt="" />}
                    </div>
                    <div
                      className={`__carousel-content flex absolute border top-0 left-0 w-full h-full items-center justify-center flex-col ${
                        showContent ? "show" : ""
                      }`}
                      style={{
                        background:
                          "linear-gradient(0deg, rgba(55, 6, 79, 0.85), rgba(55, 6, 79, 0.85))",
                      }}
                    >
                      <div className="md:w-3/4 w-4/5 text-center md:mb-16 mb-4">
                        <h2 className="title text-white md:text-4xl text-xl leading-[1.5rem] font-black mb-2 mt-5">
                          { ReactHtmlParser(e.title) }
                        </h2>
                        <div className="description md:text-lg text-md leading-[1.35rem] text-white text-center md:leading-5">
                          { ReactHtmlParser(e.description) }
                        </div>
                      </div>
                      <a
                        className={`__button button-1 md:py-2 py-1 md:px-10 px-4 border-2 font-bold border-white bg-white rounded-xl lg:text-xl text-xs text-light inline mb-5`}
                        target="_blank"
                        href="https://meetings.hubspot.com/francis-miranda/free-brand-religion-audit?embed=true"
                        rel="noopener noreferrer"
                        onBlur={e => handleBlur(e)}
                        onFocus={e => handleFocus(e)}
                      >
                        <span className="text-primary">{e.buttonText}</span>
                      </a>
                    </div>
                  </div>
                </Fade>
              </div>
            );
          })}
        </div>
        <div className="__carousel-nav">
          <div className="__prev" onClick={prev}>
            <span></span>
          </div>
          <div className="__next" onClick={next}>
            <span></span>
          </div>
        </div>
        <ol className="__carousel-indicator">
          <li data-slide-to="1"></li>
          <li data-slide-to="2"></li>
          <li data-slide-to="3"></li>
        </ol>
      </div>
    </div>
  );
};
export default LandingCarousel;
