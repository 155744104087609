import { Fade, Bounce } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import "./Receive.css";

const services = [
  {
    title: "Crafting Conversion-Centered Customer Journeys",
    description:
      "Retain your customers, while growing a new customer base from audiences through seamless, end-to-end brand experiences. ",
    li: [
      "Website Development",
      "Social Media Management",
      "Content and Communication Strategy",
      "Media Planning and Buying",
    ],
  },
  {
    title: "Content and Communication Strategy",
    description:
      "Maintain customer relationships through holistic and continuous engagement with your customers.",
    li: ["CRM Tools", "CRM Strategy"],
  },
  {
    title: "Establishing Meaningful Brand Interactions",
    description:
      "Influence your team and create a space for learning inside your business. Develop alignment for your company and your brand experiences through on-ground or virtual training for your team.",
    li: [
      "Virtual Events and Activations Platform (VEAP)",
      "Training provided by Skills Simplified",
    ],
  },
  {
    title: "Creating Immersive and Powerful Brand Activations",
    description:
      "Create contagious and memorable events that drive interest and engagement from audiences, and customers alike. Go down in history and be remembered for trendsetting, immersive, and powerful events.",
    li: ["Events Management", "Brand Activations"],
  },
];
const Receive = (props) => {
  // ** Props
  const { modal } = props;

  return (
    <section
      id="receive"
      className="relative flex justify-center bg-[#9C1212] md:py-[8rem] py-4 px-5"
    >
      <div className="upper-left-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="left"
          >
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="upper-right-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="right"
          >
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="container mx-auto flex justify-center items-center flex-col w-[100vw] pt-[6rem] pb-[4.5rem] overflow-x-hidden">
        <div className="ipad-header -mt-[90px] w-fit mb-3 md:pr-0 md:pl-0 px-[4.5rem]">
          <h3 className="title md:text-3xl text-2xl text-center text-white md:before:-left-[100px] before:-left-[75px] md:after:-right-[100px] after:-right-[75px] uppercase">
            <span>CORE PLATFORM INTEGRATIONS OF BRANDOLOGY</span>
          </h3>
        </div>
        <div className="text-white text-center w-full md:mb-10 mb-5">
          {/* We bring forth contagious experiences that light the path of consumers
          towards <br className="md:block hidden" />
          the land of brand loyalty. By the power of these illuminating gifts,
          we integrate <br className="md:block hidden" />
          rituals across strategically-effective marketing platforms: */}
          Powerful brand experiences have to be aligned in all of your brand
          platforms. <br className="md:block hidden" />
          Tagline 360 takes Brandology to the next level by incorporating your
          brand rituals <br className="md:block hidden" />
          into strategically-effective marketing platforms unique to your
          business:
        </div>
        <Fade duration={2000} cascade={true} damping={1}>
          <div className="grid md:grid-cols-2 md:gap-10 gap-5 md:mb-16 mb-10 max-w-[1200px]">
            {services.map((e, i) => {
              return (
                <div
                  key={e.title}
                  // className="relative stained-glass stained-glass-recieve lg:w-[400px] lg:h-[400px] md:w-[300px] md:h-[300px] w-[300px] h-[295px] flex flex-col justify-center md:py-10 py-6 md:px-[50px] px-[30px] lg:overflow-initial overflow-scroll"
                  className="relative stained-glass stained-glass-recieve lg:w-[400px] lg:h-[400px] md:w-[350px] md:h-[350px] w-[300px] h-[295px] md:py-10 py-6 md:px-[40px] px-[30px] lg:overflow-initial overflow-scroll"
                  style={{
                    backgroundImage:
                      "url('img/landing/receive/Ask-and-you-shall-receive-min.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="font-bold text-center lg:text-[22px] text-lg text-[20px] lg:mb-5 mb-2 md:mt-5">
                    {e.title}
                  </div>
                  <div className="lg:text-[16px] text-lg text-[10px] text-sm md:mb-5 mb-2 md:leading-6">
                    {e.description}
                  </div>
                  <ul className="pl-5 list-disc">
                    {e.li &&
                      e.li.map((li, i) => {
                        return (
                          <li
                            key={i}
                            className="lg:text-[16px] text-lg text-[10px] text-sm"
                          >
                            {li}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
          </div>
        </Fade>
        <a
          href="/work-with-us"
          className={`__button button-3 z-10 hover:text-[#9C1212] hover:bg-[#FFFFFF] ease-in-out duration-300 flex items-center md:py-2 py-1 bg-white md:px-10 px-2 rounded-xl md:text-xl text-sm text-primary inline`}
        >
          EXPLORE OUR AFFORDABLE PACKAGES
        </a>
      </div>
      <div className="bottom-left-star z-9 w-[100%] h-[150px]">
        <div className="relative ">
          <Fade cascade={true} fraction={1} damping={1} duration={500}>
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7888-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7887-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7889-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="bottom-right-star z-9 w-[100%] h-[150px]">
        <div className="relative ">
          <Fade cascade={true} fraction={1} damping={1} duration={500}>
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7885-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7884-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7886-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[150px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
    </section>
  );
};
export default Receive;
