import { useState } from "react";
import BlogModal from "../modal/BlogModal";
import { Fade } from "react-awesome-reveal";
import axios from "axios";
import config from "../config";
import { useEffect } from "react";
import { Bars } from "react-loader-spinner";
import {} from "react-dom";
import { set } from "nprogress";

const Blogs = () => {
//   const footer = frame.contentDocument.querySelector("body");
  //   footer.remove();
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(true);
  const [sectionVisible, setSectionVisible] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [loadMoreUrl, setLoadMoreUrl] = useState(
    "https://blogs.tagline360.com/"
  );

  const handleClick = async (url) => {
    setShowModal(true);
    // setSelected( blogs[i] );
    setSelected(url);
    console.log(url);
  };
  useEffect(() => {
    const hostname = window.location.hostname;
    const origin = "";

    // if (hostname === "localhost") {
    //     origin =
    // }

    if (sectionVisible) {
      // axios.get(`http://api.tagline360.test:8000/sanctum/csrf-cookie`)
      axios
        .get(`https://api.tagline360.com/sanctum/csrf-cookie`)
        .then((response) => {
          //   axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/getPosts`)
          // axios.get(`http://api.tagline360.test:8000/api/getPosts`)
          axios
            .get(`https://api.tagline360.com/api/getPosts`)
            .then((response) => {
              const data = response.data.data.objects;
              const blogs = data
                .map((item) => item)
                .filter((item) => item.is_published);

              setBlogs(blogs);

              try {
                var published_post_count = 0,
                  max_post_limit = 2;

                blogs.map((post) => {
                  published_post_count = ++published_post_count;
                });

                if (published_post_count > max_post_limit) {
                  const { blog_url } = response.data.data;
                  setLoadMoreUrl(blog_url);
                }

                setTimeout(() => {
                  setLoading(false);
                }, 200);
              } catch (error) {
                console.error(error);
              }
            });
        });
    }
  }, [sectionVisible]);
  return (
    <>
      <section
        id="blogs"
        className="relative justify-center bg-[#FFDCC3] h-[100vh] -mt-[1px]"
      >
        <iframe
            id="hubspot-content"
          src={loadMoreUrl}
          frameborder="0"
          className="w-full h-full -mb-[100px]"
        ></iframe>
      </section>
    </>
  );
};
export default Blogs;
