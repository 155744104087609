export default {
  /**
   * It is used to fetch "all" records in cases where pagination is unnecessary.
   */
  arbitraryQueryLimit: 1000,
  app: {
    name: 'Tagline360',
    companyName: '',
    contact: {
      email: '',
      adminEmail: 'support@tagline360.com',
    },
    api: process.env.REACT_APP_API_ENDPOINT
  },
}
