import { useState } from "react"
import ReactDOM from 'react-dom/client'
import { Link } from 'react-router-dom'
import { PrimaryMenu } from '../menus';
import style from 'src/css/_header.module.scss'
import Landing from 'src/pages/Home';
import { Fade } from "react-reveal";

const listItems = PrimaryMenu.map((items) => {
    return (
        <li className={style.menuItem} key={items.label}>
            <Link to={items.path} aria-labelledby={`Open ${items.label} page`}> {items.label} </Link>
        </li>
    )
});

const mobileListItems = PrimaryMenu.map((items) => {
    return (
        <li className="border-b border-gray-400 my-5 uppercase" key={items.label}>
            <a href={items.path} aria-labelledby={`Open ${items.label} page`}>{items.label}</a>
        </li>
    )
});

const Header = props => {
    // ** Props
    const {handleBlur, handleFocus, location} = props
    const [isNavOpen, setIsNavOpen] = useState(false);

    return (
        <header className="relative bg-primary py-3 z-10">
            <div className="__header-wrapper flex justify-between sm:px-10 items-center">
                <div className="brand sm:px-0 px-[1rem] flex flex-row justify-center items-center">
                    <Link to="/" aria-label="Home">
                        <img src="/img/tagline360-logo-light-min.png" width={150} alt="" className="max-w-full" />
                    </Link>
                    {/* <div className="ml-10 px-5 py-3">
                        <a href={location.pathname === '/work-with-us' ? `#convertWithUs` : `#mainContentStart`} aria-label="Skip to content" className="text-white underline" onBlur={e => handleBlur(e)} onFocus={e => handleFocus(e)}>
                            <div className="cursor-pointer t360-skip-links">
                                <div className="text-[15px] text-white font-medium">Skip to content</div>
                            </div>
                        </a>
                    </div> */}
                </div>
                <nav className="__navigation">
                    {/* desktop nav */}
                    <div className="flex items-center gap-6">
                        <ul className={`hidden lg:flex ${style.menu}`}>
                            { listItems }
                        </ul>
                        <div className="hidden lg:flex __hubspot-partner shrink-0">
                            <img src="/img/hubspot-partner-logo-light.png" width={90} alt="" className="" />
                        </div>
                    </div>
                    {/* mobile nav */}
                    <section className="flex lg:hidden sm:mx-0 mx-5">
                        {/* hamburger menu */}
                        <div
                            className="space-y-2"
                            onClick={() => setIsNavOpen((prev) => !prev)}
                        >
                            <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
                            <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
                            <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
                        </div>

                        <div className={isNavOpen ? "showMenuNav transition transition-opacity ease-in-out delay-150" : "hideMenuNav"}>
                            <Fade duration={500} >
                                <div className=" shrink-0 z-20">
                                    <img src="/img/hubspot-partner-logo.png" width={90} alt="" className="max-w-full" />
                                </div>
                                {/* cross icon */}
                                <div
                                className="absolute top-0 right-0 px-8 py-8"
                                onClick={() => setIsNavOpen((prev) => !prev)}
                                >
                                <svg
                                    className="h-8 w-8 text-gray-600"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                                </div>
                                <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]">
                                    { mobileListItems }
                                </ul>
                            </Fade>
                        </div>
                    </section>
                </nav>
            </div>
        </header>
    );
}

export default Header;