import { Fade } from "react-awesome-reveal";
import WorkWithUsModal from "../modal/WorkWithUsModal";
import { useState } from "react";
import ReactHtmlParser from "react-html-parser";

const messengers = [
  {
    title: "Blessing Brands <br/>with Authenticity",
    description:
      "We aim to create powerful brand experiences—rooted in human <br class='lg:block hidden' />insight—designed to help grow brands by converting casual fans <br class='lg:block hidden' />into loyal followers through ingenuity and creativity.",
    img: "/img/about/blessing",
    srcset: "",
    class: "",
  },
  {
    title: "Our Universal Purpose <br class='md:block hidden' />is Clear",
    description:
      "We mold brands into our brand rituals resulting <br class='lg:block hidden' />into unforgettable experiences that increases <br class='lg:block hidden' />follower interest. We initiate congregations <br class='lg:block hidden' />and penetrate culture to materialize the <br class='lg:block hidden' />callback that you desire.",
    img: "/img/about/purpose",
    srcset: "",
    class: "md:order-2",
  },
  {
    title: "Our Larger-than-Life Vision <br class='md:block hidden' />is Near",
    description:
      "We create growth-driving and contagious brand <br class='md:block hidden' />experiences that cause ripples and waves into cultural <br class='md:block hidden' />consciousness. Our goal is to manifest your rituals in <br class='md:block hidden' />people’s lifestyles, pop culture, and behavior, leading to <br class='md:block hidden' />your brand’s holistic development.",
    img: "/img/about/vision",
    srcset: "",
    class: "",
  },
];
const Messenger = (props) => {
  // ** Props
  const { modal, showModal, setShowModal } = props;

  // ** States
  // const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  const handleClick = (i) => {
    setShowModal(true);
    setSelected(messengers[i]);
  };

  return (
    <section
      id="aboutUs"
      className="relative flex justify-center bg-[#FFDEC1] md:py-[8rem] py-4 px-5"
    >
      <div className="upper-left-star w-[100%] h-[150px]">
        <div className="relative">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="left"
          >
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="upper-right-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="right"
          >
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="container mx-auto flex justify-center items-center flex-col w-[100vw] pb-[4.5rem]">
        <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-16">
          <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
            <span>The Messengers of <br className="sm:hidden block" />Real are Here</span>
          </h3>
        </div>
        <div className="sm:text-lg text-md text-[#9C1212] text-center mb-16">
          Tagline 360 is a creative collective, composed of brand evangelists performing various liturgies
          <br className="md:block hidden"/>
          to elevate your follower experience.
        </div>
        <Fade duration={2000} cascade={true} damping={1}>
          <div className="md:mb-16 mb-10 max-w-[1200px]">
            {messengers.map((e, i) => {
              return (
                <div key={e.title} className="grid md:grid-cols-12">
                  <div className={`md:col-span-6 col-span-12 relative stained-glass--- stained-glass-messenger transform-gpu ${e.class}`}>
                    <img
                      src={`${e.img}-2x.png`}
                      className="mx-auto lg:max-w-[400px]"
                      sizes="(max-width: 575px) 300px, 400px"
                      srcSet={`${e.img}-2x.png 400w, ${e.img}-1x.png 300w`}
                      alt=""
                    />
                  </div>
                  <div className={`col-span-6 flex items-center md:text-left text-center`}>
                    <div>
                      <h4 className="text-[#6E1363] leading-2 uppercase md:text-[24px] text-xl mb-4">  
                        {ReactHtmlParser(e.title)}
                      </h4>
                      <div className="sm:text-lg text-md h-[100px] mb-5 text-[#9C1212] leading-2">
                        {ReactHtmlParser(e.description)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Fade>
        <a
          className={`w-fit __button button-2 z-10 hover:text-[#FFFFFF] hover:bg-[#9C1212] ease-in-out duration-300 flex items-center text-white py-2 lg:px-10 px-2 bg-primary rounded-xl md:text-xl text-sm text-light mx-auto`}
          target="_blank"
          href="https://meetings.hubspot.com/francis-miranda/free-brand-religion-audit?embed=true"
        >
          <span>BECOME A LEGENDARY BRAND TODAY!</span>
        </a>
      </div>
      <div className="bottom-left-star z-9 w-[100%] h-[150px]">
        <div className="relative ">
          <Fade cascade={true} fraction={1} damping={1} duration={500}>
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7888-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7887-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7889-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="bottom-right-star z-9 w-[100%] h-[150px]">
        <div className="relative ">
          <Fade cascade={true} fraction={1} damping={1} duration={500}>
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7885-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7884-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7886-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      {showModal && (
        <WorkWithUsModal
          show={showModal}
          close={() => setShowModal(false)}
          selected={selected}
          modal={modal}
        />
      )}
    </section>
  );
};
export default Messenger;
