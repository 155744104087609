const Download = (props) => {
  // ** Props
  const { modal } = props;

  return (
    <section
      id="manifestitions"
      className="relative flex justify-center bg-[#9C1212] md:py-[8rem] py-4 px-5"
    >
      <div className="container mx-auto flex justify-center items-center flex-col py-[1rem]">
        <div className="mb-2">
          <div className="w-fit mb-3 md:pr-0 md:pl-0 md:px-[4.5rem] px-[4.5rem]">
            <h3 className="title md:text-3xl text-2xl text-center text-white md:before:-left-[100px] before:-left-[75px] md:after:-right-[100px] after:-right-[75px] uppercase">
              <span>Get the Good News for FREE</span>
            </h3>
          </div>
        </div>
        <div className="text-white text-center md:w-[60%] md:mb-8 mb-5">
          {/* The process of enlightenment is accentuated with more reading
          references. <br className="md:block hidden" />
          Subscribe now and illuminate your mind with legendary brand knowledge! */}
          The process of enlightenment is accentuated with more reading
          references. <br className="md:block hidden" />
          Subscribe now and get your monthly newsletters with case studies,
          e-books, articles <br className="md:block hidden" />
          that will illuminate your mind with legendary brand knowledge!
        </div>
        <div className="mb-2">
          <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[4.5rem]">
            <button
              type="button"
              onClick={() =>
                modal(
                  "na1",
                  "21865908",
                  "5c56d501-e87b-4f99-a31d-65321b98bab0",
                  "Get the Good News for FREE!",
                  "Fill in the details below to subscribe:"
                )
              }
              className={`__button button-3 flex items-center text-black md:py-2 py-1 md:px-10 px-4 bg-white rounded-xl lg:text-xl text-sm inline mx-auto`}
            >
              <span>SUBSCRIBE NOW</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Download;
