import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { Fade } from 'react-awesome-reveal';
import NotifModal from  './NotifModal';
import ReactHtmlParser from 'react-html-parser';
import HubspotContactForm from 'src/components/forms/HubSpotForms/HubSpotContactForm';

const customStyles = {
  content: {
    border: '0'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.85)',
    zIndex: 1050,
  }
}

Modal.setAppElement('#root');

// Check whether the popup already show
// if show display the popup
// upon submit make sure that user identity is stored so that we can verify the record if user refresh the page


export default function DynamicFormModal ({show, close, region, portalId, formId, header, subheader,}) {

  const [subscribe, setSubscribe] = useState();
  const [notif, setNotif] = useState(false);
  const [submitted, setSubmitted] = useState(false)

  const handleClose = () => {
    setSubmitted(false)
    close()
  }

  return (
    <>
      <Modal 
        isOpen={show}
        style={customStyles}
        className="max-w-[768px] m-auto max-h-[90vh] overflow-auto md:absolute relative top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]"
      >
        <Fade>
          <div className="w-full h-full block mx-auto bg-[#FFF3EA]">
            <div className="relative lg:min-h-[610px]">
              <div className="flex justify-end w-full">
                <button className="text-4xl text-[#C9BBBB] p-3 z-20" onClick={handleClose}>
                  <IoClose />
                </button>
              </div>
              <div className='overflow-hidden'>
                <div className="overflow-scroll max-h-[550px]">
                  <div className="block mx-auto md:p-16 p-5 min-h-[400px]">
                    <div className="mb-5">
                      <h3 className="title text-3xl text-[#6E1363] leading-7 text-center">{ header }</h3>
                    </div>
                    <div className="description text-left mb-5 text-[#9C1212]">
                      {
                        ReactHtmlParser(subheader)
                      }
                    </div>
                    <div className='w-full my-5'>
                      <HubspotContactForm
                        region={region}
                        portalId={portalId}
                        formId={formId}
                        setSubmitted={setSubmitted}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <NotifModal show={notif} dismiss={() => setNotif(false)} />
    </>
  );
}
