import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import ReactHtmlParser from "react-html-parser";
import { Fade, Bounce } from "react-awesome-reveal";
import Vimeo from "src/components/vimeo";

const customStyles = {
    content: {
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        // minWidth: '768px',
        // minHeight: '90vh',
        // overflow: 'hidden',
        padding: "0",
        outline: "none",
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.85)",
        zIndex: 1050,
    },
};

Modal.setAppElement("#root");

export default class WorkWithUsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true,
            loading: false,
            selected: this.props.selected,
            formModalOpen: false,
        };
        // this.OpenModal = this.OpenModal.bind(this);
        // this.CloseModal = this.CloseModal.bind(this);
        this.toggleLoader = this.toggleLoader.bind(this);
        this.handleFormDisplay = this.handleFormDisplay.bind(this);
    }

    // componentDidMount() {
    //   this.state.selected = JSON.stringify(this.props.selected)
    //   console.log(this.state.selected)
    // }

    // OpenModal() {
    //   this.setState({modalIsOpen:true})
    // }
    // CloseModal() {
    //   this.setState({modalIsOpen: false})
    // }
    toggleLoader() {
        this.setState({ loading: this.state.loading ? false : true });
    }
    setIsFormDisplayed() {
        this.setState({ loading: this.state.loading ? false : true });
    }
    handleFormDisplay() {
        this.setState({ formModalOpen: true });
    }




    componentDidMount(selected) {
        // console.log(123, this.state.selected)

        // const video = new 

    }

    render() {
        const { ...result } = this.state.selected;

        return (
            <>
                <Modal
                    isOpen={this.props.show}
                    style={customStyles}
                    className="flex items-center justify-center w-full h-full"
                >
                    <div className="md:min-w-[700px] md:min-h-[325px] md:max-w-[768px] border relative">
                        <div className="absolute top-0 right-0 z-20">
                            <button
                                className="text-4xl text-[#C9BBBB] z-20 border rounded-full bg-[#FFF3EA] m-2"
                                onClick={this.props.close}
                            >
                                <IoClose />
                            </button>
                        </div>
                        <div className="">
                            {result.video && (
                                <div className="">
                                    <div className="video-modal-wrapper">
                                        <video
                                            id="video"
                                            className="video-content"
                                            controls
                                            autoPlay
                                            preload="none"
                                            style={{height: '100%', width: '100%', objectFit: 'cover'}}
                                        >
                                            <source
                                                src={result.video}
                                                type="video/mp4"
                                            />
                                        </video>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}
