// sample
export const Items = [
    {
        title: "Dusit D2 Main",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/3-a.png",
        video: "https://videos.tagline360.com/dusit.mp4",
    },
    {
        title: "Lenovo Yoga",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/9.png",
        video: "https://videos.tagline360.com/lenovo.mp4",
    },
    {
        title: "Samsung",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/12.png",
        video: "https://videos.tagline360.com/samsung.mp4",
    },
    {
        title: "Hydrogen",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/6.png",
        video: "https://videos.tagline360.com/hydrogen.mp4",
    },
    {
        title: "Victor - Miss Universe Vietnam",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/16.png",
        video: "https://videos.tagline360.com/victor-miss-universe-vietnam.mp4",
    },
    {
        title: "Le Flaire",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/8.png",
        video: "https://videos.tagline360.com/le-flaire.mp4",
    },
    {
        title: "Kihon",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/7.png",
        video: "https://videos.tagline360.com/kihon-final.mp4",
    },
    {
        title: "Spark Samar",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/15.png",
        video: "https://videos.tagline360.com/spark-samar.mp4",
    },
    {
        title: "Dencio's",
        description:
            "Win consumer loyalty through memorable and contagious brand experiences unique to YOU. <br class='lg:block hidden' />Create loyalty from current followers, while creating new relationships for nurture.",
        buttonText: "Start Converting My Customers",
        link: "#",
        image: "/img/production/thumbnails/2.png",
        video: "https://videos.tagline360.com/dencio.mp4",
    },
    {
        title: "Greencross",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/5.png",
        video: "https://videos.tagline360.com/greencross.mp4",
    },
    {
        title: "Chef MB",
        description:
            "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
        buttonText: "Start Growing My Brand",
        link: "#",
        image: "/img/production/thumbnails/17.png",
        video: "https://videos.tagline360.com/chefb1.mp4",
    },
    {
        title: "Dropkicks",
        description:
            "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
        buttonText: "Start Growing My Brand",
        link: "#",
        image: "/img/production/thumbnails/18.png",
        video: "https://videos.tagline360.com/feb-dk-main.mp4",
    },
    {
        title: "Makira",
        description:
            "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
        buttonText: "Start Growing My Brand",
        link: "#",
        image: "/img/production/thumbnails/19.png",
        video: "https://videos.tagline360.com/makira.mp4",
    },
    {
        title: "Milan Mou",
        description:
            "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
        buttonText: "Start Growing My Brand",
        link: "#",
        image: "/img/production/thumbnails/20.png",
        video: "https://videos.tagline360.com/milan mou.mp4",
    },
    {
        title: "NJW Full",
        description:
            "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
        buttonText: "Start Growing My Brand",
        link: "#",
        image: "/img/production/thumbnails/21.png",
        video: "https://videos.tagline360.com/njw-full.mp4",
        //di pa tapos
    },
    {
        title: "Xoia",
        description:
            "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
        buttonText: "Start Growing My Brand",
        link: "#",
        image: "/img/production/thumbnails/22.png",
        video: "https://videos.tagline360.com/xaio.mp4",
    },
    {
        title: "Splash Winter Collection",
        description:
            "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
        buttonText: "Start Growing My Brand",
        link: "#",
        image: "/img/production/thumbnails/23-min.png",
        video: "https://videos.tagline360.com/splash-winter.mp4",
    },
    {
        title: "Daily C",
        description:
            "Legendary brands don’t happen overnight. <br class='lg:block hidden' />The answer lies behind a strategically planned customer care process, from start to finish. <br class='lg:block hidden' />We’re here to walk with you through this process.",
        buttonText: "Start Growing My Brand",
        link: "#",
        image: "/img/production/thumbnails/1.png",
        video: "https://videos.tagline360.com/daily-c.mp4",
    },
    {
        title: "Rex Bookstore",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/11.png",
        video: "https://videos.tagline360.com/rex.mp4",
    },
    {
        title: "Sidebar",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/14.png",
        video: "https://videos.tagline360.com/sidebar.mp4",
    },
    {
        title: "San Miguel Pale Pilsen",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/13.png",
        video: "https://videos.tagline360.com/san-miguel.mp4",
    },
    {
        title: "Modelhunt Int'l NY",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/10.png",
        video: "https://videos.tagline360.com/modelhunt.mp4",
    },
    {
        title: "GoWifi",
        description:
            "Engaging customer experiences increase your chances <br class='lg:block hidden' />of developing audiences, from first-time buyers, into wildly raving fans. <br class='lg:block hidden' />Are you ready to start converting your audience?",
        buttonText: "Start Transforming My Audience",
        link: "#",
        image: "/img/production/thumbnails/4.png",
        video: "https://videos.tagline360.com/gowifi.mp4",},
];

export default Items;
