import { useNavigate } from 'react-router-dom'

export default function GoBack() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }

    return (
        <button
            className={`w-[175px] __button button-2 z-10 hover:text-[#FFFFFF] hover:bg-[#9C1212] ease-in-out duration-300  text-white py-2 lg:px-10 px-2 bg-primary rounded-xl md:text-xl text-sm text-light mx-auto flex gap-2 items-center justify-center`}
            onClick={goBack}
        >
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='white'>
                <path d="M360-200 80-480l280-280 56 56-183 184h647v80H233l184 184-57 56Z" />
            </svg>
            <span>Back</span>
        </button>
    )
}