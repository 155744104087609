import { Carousel } from 'react-responsive-carousel';
import { Fade, Bounce } from "react-awesome-reveal";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const evangelizers = [
    {
        image: 'img/landing/evangelizers/Ellipse-5.png'
    },
    {
        image: 'img/landing/evangelizers/Ellipse-5-(1).png'
    },
    {
        image: 'img/landing/evangelizers/Ellipse-5-(2).png'
    },
]

const Evangelizers = () => {
    return (
        <section id="evangelizers" className='bg-blue-mosaic relative flex justify-center md:py-[8rem] py-4 px-5'>
            <div className='flex container mx-auto justify-center items-center flex-col md:w-[550px] w-[450px] md:h-auto h-[350px]'>
                <div className='grid grid-cols-3 lg:gap-x-4 gap-x-2 lg:mb-10 mb-2 max-w-[1200px]'>
                    {
                        evangelizers.map((e,i) => {
                            return (
                                <div className='evangelizers-border flex items-center justify-center md:w-[200px] md:h-[200px] sm:w-[130px] sm:h-[130px] w-[80px] h-[80px]' key={i}>
                                    <img src={e.image} className="md:w-[125px] sm:w-[80px] w-[50px]" alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='w-fit md:mb-5 mb-2'>
                    <h3 className='md:text-3xl sm:text-2xl text-[18px] leading-1 text-center text-dark before:-left-[100px] after:-right-[100px]'>
                        <span>The Brand Evangelizers are Here</span>
                    </h3>
                </div>
                <div className='text-dark text-center lg:mb-8 mb-2 md:text-lg text-[12px]'>
                    Tagline 360 is a creative collective, composed of brand evangelizers— <br className="sm:block hidden"/>sharing the good word on how to elevate your follower experience.
                </div>
                <div className='text-dark font-bold text-center md:w-[40%] lg:mb-10 mb-2 md:text-xl text-[12px] uppercase'>
                    Coming Soon...
                </div>
            </div>
            {/* <div className='flex sm:hidden container mx-auto justify-center items-center flex-col'>
                <Fade duration={1200}>
                    <Carousel 
                        showArrows={false} 
                        showStatus={false}
                        showThumbs={false}
                        stopOnHover={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        interval={2000}
                        emulateTouch={true}
                        className="w-[100vw] overflow-hidden"
                    >
                        <div className='w-full p-[4.5rem]'>
                            <div className='mb-2'>
                                <h3 className='text-2xl text-center text-dark before:-left-[100px] after:-right-[100px]'>
                                    <span>The Brand Evangelizers are Here</span>
                                </h3>
                            </div>
                            <div className='text-dark text-center mb-2'>
                                Tagline 360 is a creative collective, composed of brand evangelizers— <br/>sharing the good word on how to elevate your follower experience.
                            </div>
                            <div className='text-dark font-bold text-center mb-2 uppercase'>
                                Coming Soon...
                            </div>
                        </div>
                        {
                            evangelizers.map((e,i) => {
                                return (
                                    <div className='evangelizers-border flex items-center justify-center w-[200px] h-[200px] mx-auto sm:mt-[2rem] mt-[4rem]' key={i}>
                                        <img src={e.image} alt="" className='evangelizers-dp-mobile padding-[2.3rem]'/>
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </Fade>
            </div> */}
        </section>
    )
}
export default Evangelizers;