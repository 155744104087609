import React, { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import ReactHtmlParser from "react-html-parser";
import { Fade, Bounce } from "react-awesome-reveal";

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // minWidth: '768px',
    // minHeight: '90vh',
    // overflow: 'hidden',
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)",
    zIndex: 1050,
  },
};

Modal.setAppElement("#root");

export default class WorkWithUsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      loading: false,
      selected: this.props.selected,
      formModalOpen: false,
    };
    // this.OpenModal = this.OpenModal.bind(this);
    // this.CloseModal = this.CloseModal.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleFormDisplay = this.handleFormDisplay.bind(this);
  }

  // componentDidMount() {
  //   this.state.selected = JSON.stringify(this.props.selected)
  //   console.log(this.state.selected)
  // }

  // OpenModal() {
  //   this.setState({modalIsOpen:true})
  // }
  // CloseModal() {
  //   this.setState({modalIsOpen: false})
  // }
  toggleLoader() {
    this.setState({ loading: this.state.loading ? false : true });
  }
  setIsFormDisplayed() {
    this.setState({ loading: this.state.loading ? false : true });
  }
  handleFormDisplay() {
    this.setState({ formModalOpen: true });
  }

  render() {
    const { ...result } = this.state.selected;
    return (
      <>
        <Modal
          isOpen={this.props.show}
          style={customStyles}
          className="max-w-[768px] m-auto max-h-[90vh] overflow-auto md:absolute relative top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]"
        >
          <Fade>
            <div className="w-full h-full block mx-auto bg-[#FFF3EA]">
              <div className="relative">
                <div className="flex justify-end w-full">
                  <button
                    className="text-4xl text-[#C9BBBB] p-3 z-20"
                    onClick={this.props.close}
                  >
                    <IoClose />
                  </button>
                </div>
                <div className="overflow-hidden">
                  <div className="overflow-scroll max-h-[550px]">
                    <div className="block mx-auto md:py-5 md:px-16 p-5">
                      <div className="mb-5">
                        <h3 className="title text-3xl text-[#6E1363] leading-7 text-center">
                          {result.title}
                        </h3>
                      </div>
                      <div className="description text-left mb-5 text-[#9C1212]">
                        {ReactHtmlParser(result.long_description)}
                      </div>
                      <div className="text-center text-[#6E1363] mb-5 leading-1">
                        {ReactHtmlParser(result.price)}
                      </div>
                      <a
                        className="rounded-full bg-[#155D86] text-white mx-auto hover:bg-black px-5 py-1 block w-40 transition ease-in duration-200 z-[99] mb-5 text-center"
                        target="_blank"
                        href="https://meetings.hubspot.com/francis-miranda/free-brand-religion-audit?embed=true"
                      >
                        <span>AVAIL NOW</span>
                      </a>

                      {/* <button
                        onClick={() =>
                          this.props.modal(
                            `${result.region}1`,
                            result.portalId,
                            result.formId,
                            "Talk to Us Today!",
                            "Fill in the details below and our brand evangelist will be in touch with you to discuss more about our price plans."
                          )
                        }
                        className="rounded-full bg-[#155D86] text-white mx-auto hover:bg-black px-5 py-1 block w-40 transition ease-in duration-200 z-[99] mb-5"
                      >
                        <span>Avail Now</span>
                      </button> */}
                      <div className="note text-[#6E1363] text-center">
                        Note: These are just sample services. The package can depend
                        <br className="md:block hidden" />on the result of the brand blueprint session
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
}
