import { useState, useEffect } from 'react'
import Header from "src/components/navigations/Header"
import Footer from 'src/components/Footer';
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

import 'src/css/_landing.scss'
import RouteProgress from 'src/components/routes/RouteProgress';

// ** Modal Imports
import SubscriptionModal3 from 'src/components/modal/SubscriptionModal3'

export default function Layout({children}) {
    const [showSubscriptionModal3, setShowSubscriptionModal3] = useState(false)

    // ** Hooks
    const location = useLocation()

    const handleBlur = (e) => {
        const currentTarget = e.currentTarget;
        currentTarget.querySelector('div.t360-skip-links').style.visibility = "hidden"
    };

    const handleFocus = (e) => {
        const currentTarget = e.currentTarget;
        if (currentTarget === document.activeElement) {
        currentTarget.querySelector('div.t360-skip-links').style.visibility = "visible"
        }
    }

    useEffect(() => {
        console.log(location);
    }, []);

    return (
        <>  
            <RouteProgress />
            <Header handleFocus={handleFocus} handleBlur={handleBlur} location={location} />
            <div id="topMost"></div>
            <Outlet />
            <Footer modal={() => setShowSubscriptionModal3(true)}/>
            <SubscriptionModal3 show={showSubscriptionModal3} close={() => setShowSubscriptionModal3(false)} />
        </>
    )
}