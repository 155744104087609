import ReactHtmlParser from "react-html-parser";
import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useEffect } from "react";
import Vimeo from "src/components/vimeo";
import Project from "src/pages/Projects/Project";
import { Carousel } from "react-responsive-carousel";
// import Image1 from "/img/whoweare/under4";
// import "./project.scss";

const project = [
  {
    id: "1",
    title: "Magical Sunset",
    website: "https://www.magicalsunset.com",
    photos: "Use Stock Photos",
    img: "/img/projects/main/ddd.png",
    subheadline: "Revenge Travel: A Strategy for Magical Sunset",
    introduction: `
    Magical Sunset, a travel company specializing in bespoke luxury catamaran tours in Greece, had a unique product and a goal to shift from the B2C to the B2B market with a focus on travel agencies. The challenge was to generate increased inquiries for their product and establish partnerships with travel agencies.
        `,
    challenge: `
    Magical Sunset had developed a fantastic product that provided travelers with a unique, non-touristy experience of Greece through engaging Greek Mythology and Storytelling. However, they needed help transitioning from a B2C to a B2B market and a powerful marketing strategy to attract travel agencies to their product.
        `,
    solution: `
    Tagline 360 developed a comprehensive strategy to address Magical Sunset's challenges: <br>
    <div class="pl-5">
    1. Email Marketing Campaign: We created a powerful email marketing campaign designed to highlight the unique features of Magical Sunset's tours. Our emails targeted traditional travel agencies, proposing that Magical Sunset's tours should be an essential part of their product line-up. <br>
    2. Focus on Revenge Travel: Our communication strategy focused on the growth of revenge travel — the trend of people traveling more extensively after being restricted due to the pandemic. This approach resonated with travel agencies looking to offer their customers unique experiences post-pandemic. <br>
    3. Tactical Effort for LGBT Travel Agencies: In collaboration with Magical Sunset, we developed a unique product exclusively designed for the LGBT community. We tested a tactical effort to target LGBT travel agencies, positioning Magical Sunset's tour as an inclusive and appealing option for their customers.
    </div>
    `,
    result: `
    The strategies implemented by Tagline 360 increased brand partnerships with travel agencies, preparing Magical Sunset for a successful summer season in 2024. The email marketing campaign, the focus on revenge travel, and the targeted effort towards LGBT travel agencies effectively boosted inquiries for Magical Sunset's product. They solidified their transition to a B2B market.    `,
    conclusion: `
    This case study demonstrates Tagline 360's expertise in creating communication, branding, and marketing strategies that deliver results. By understanding the client's needs and developing a tailored strategy, we positioned Magical Sunset as a desirable partner for travel agencies, resulting in increased brand partnerships and setting the stage for a successful season.
    `,
    imgsrc: "/img/projects/main/activations./1",
  },
  {
    id: "2",
    title: "Modelhunt International LA",
    website: "https://www.magicalsunset.com",
    photos: "Use Stock Photos",
    img: "/img/projects/main/g.png",
    subheadline: "Reviving Diversity and Inclusivity",
    introduction: `
    Modelhunt International is an organization committed to celebrating the rich tapestry of diversity and fostering inclusivity within the modeling industry. Their mission is to offer a stage where aspiring models of all backgrounds, ages, sizes, and appearances can have their talents acknowledged and cherished. Following their triumphant launch of Modelhunt International in New York City (NYC), the organization set its sights on rekindling the spirit of diversity and inclusivity in its birthplace - Los Angeles (LA).
        `,
    challenge: `
    The challenge at hand was a two-fold endeavor. Firstly, the aim was to reintroduce Modelhunt International to its hometown of LA, showcasing a commitment to diversity and inclusivity within the modeling sphere. Secondly, Tagline 360 was entrusted with the task of organizing an exceptional event for Modelhunt International LA that would not only spotlight model diversity but also attract a wide range of models to partake in the competition. This dual challenge necessitated a comprehensive approach, blending event planning with social media and email marketing strategies.
        `,
    solution: `
    Tagline 360 embarked on an integrated strategy that blended both the event management and digital marketing aspects. Recognizing the influence of digital platforms, they formulated a marketing campaign tailored to resonate with Modelhunt International's audience. Social media marketing initiatives underscored the benefits of participating in Modelhunt International LA, emphasizing exposure to modeling agencies, specialized training, professional photo sessions, and the potential to feature in magazines. Email marketing complemented these efforts, creating a multi-channel approach to engaging with aspiring models. <br><br>
    The event itself was conceptualized as a full-day fashion extravaganza, celebrating the diversity of models from various backgrounds. The event was segmented into three distinct shows, each dedicated to specific model categories such as petite, mature, edgy, mainstream, and more. This meticulous division provided a dedicated platform for every model type, amplifying the spirit of inclusivity and diversity within the fashion industry. <br><br>
    The Hotel Roosevelt in West Hollywood, LA, was the chosen venue for this spectacular event. Its iconic location set the stage for a memorable evening that attracted over 1500 model applicants, with more than 50 finalists selected to participate. Tagline 360 orchestrated a seamless transition between each segment of the fashion show, ensuring that the diversity and inclusivity message shone brightly throughout.
    `,
    result: `
    The event was nothing short of a triumph. Over 1500 models applied for the competition, and more than 50 finalists were selected, highlighting the overwhelming response and enthusiasm. The Hotel Roosevelt in West Hollywood, LA, provided the ideal backdrop, and the three distinct fashion shows showcased models of every kind, ranging from petite to mature, edgy, mainstream, and more.
`,
    conclusion: `
    Through meticulous planning and execution, Tagline 360 successfully resurrected Modelhunt International in LA. The annual fashion show served as a tribute to diversity and inclusivity, exemplifying the institution's unwavering commitment to these values. Modelhunt International's success not only revitalized its presence but also paved the way for aspiring models to embrace their dreams while reinforcing the fashion industry's need for greater representation and inclusivity. This event marked a significant step towards a more diverse and inclusive future in the world of fashion.
    `,
    imgsrc: "/img/projects/main/activations./2",
  },
  {
    id: "3",
    title: "FROW FASHION WEEK",
    website: "https://www.magicalsunset.com",
    photos: "Use Stock Photos",
    img: "/img/projects/main/f.png",
    subheadline: "Revolutionizing Fashion through Innovation",
    introduction: `
    FROW FASHION WEEK has emerged as a trailblazing platform, committed to propelling innovative designers and artists to the forefront of the fashion landscape. This visionary endeavor was birthed through the collaboration of Errol Isip, a seasoned fashion event specialist, and Jovito Rabeals, the Global President of Tagline 360. Their joint mission was to ignite a fashion revolution by providing a prominent stage for visionary designers and artists to shine.
        `,
    challenge: `
    Tagline 360 encountered a series of multifaceted challenges, each holding the key to the success of FROW FASHION WEEK:<br>
    <div class='pl-5'>
    1. Conceptualization to Execution: Tagline 360 assumed the monumental task of conceptualizing and meticulously executing every aspect of the event. This encompassed crafting a distinctive event identity, overseeing intricate organizational logistics, and ensuring the flawless realization of FROW FASHION WEEK.<br>
    2. Digital Marketing: The agency was tasked with kindling anticipation and awareness surrounding FROW FASHION WEEK while simultaneously thrusting the spotlight onto seven groundbreaking fashion designers. To this end, an organic social media campaign was devised, harnessing the immense reach and influence of various online platforms.<br>
    3. Talent Management: Tagline 360 undertook the critical role of scouting models who could breathe life into the designers' creative visions. This intricate casting process required a delicate balance to ensure that the chosen models harmoniously embodied the essence of the showcased fashion.<br>
    4. Public Relations: The agency was entrusted with curating a robust public relations campaign. This initiative was instrumental in elevating the profiles of the participating designers, increasing their visibility within the fashion realm.
    </div>
        `,
    solution: `
    In response to these multifaceted challenges, Tagline 360 orchestrated a comprehensive strategy:<br><br>
Conceptualization to Execution: Tagline 360 meticulously crafted the event's distinctive identity and diligently oversaw the intricate organizational facets. The selection of the Pacific Design Center as the event's venue exemplified a commitment to sophistication and innovation, making it the perfect canvas for the fashion showcase. <br><br>
Digital Marketing: Leveraging an organic social media marketing approach, Tagline 360 effectively kindled anticipation and enthusiasm for FROW FASHION WEEK. The campaign harnessed the expansive influence of social media platforms, generating buzz and interest around the event. <br><br>
Talent Management: The agency undertook the intricate task of model scouting, ensuring that each model seamlessly embodied the creative vision of the participating designers. This casting process was executed with precision, resulting in models that perfectly complemented the showcased fashion. <br><br>
Public Relations: A robust public relations campaign, masterminded by Tagline 360, skillfully directed the spotlight onto the participating designers. This initiative significantly enhanced their profiles within the fashion industry, amplifying their presence at the event.
    `,
    result: `
    The culmination of these collaborative efforts yielded resounding success: <br>
The Frow Fashion Week Event: This remarkable event witnessed the gathering of over 500 attendees who were captivated by the groundbreaking designs and artistic innovations on display. With six distinct shows featuring seven visionary designers, FROW FASHION WEEK solidified its position as an emblem of creative excellence in the fashion universe.
`,
    conclusion: `
    The partnership between FROW FASHION WEEK and Tagline 360 stands as a testament to the transformation of visionary aspirations into triumphant realities. The event's seamless execution, the anticipation generated through digital marketing, the flawless talent management, and the diligent public relations campaign all underscore a shared commitment to ushering innovation and inclusivity into the fashion sphere. FROW FASHION WEEK is more than just an event; it signifies the remarkable achievements that materialize when creative ingenuity, visionary zeal, and meticulous execution converge, heralding an exciting new era in the world of fashion.
    `,
    imgsrc: "/img/projects/main/activations./3",
  },
];

const Manifestitions = () => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();

  const body = document.querySelector("body");
  useEffect(() => {
    if (showModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "initial";
    }
  }, [showModal]);

  return (
    <>
      <section
        id="manifestation"
        className="relative bg-[#FFDEC1] md:py-[8rem] py-4 overflow-hidden"
      >
        <div className="upper-left-star w-[100%] h-[150px]">
          <div className="relative ">
            <Fade
              cascade={true}
              fraction={1}
              damping={2}
              duration={300}
              direction="left"
            >
              <img
                src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                alt=""
              />
              <img
                src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                alt=""
              />
              <img
                src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                alt=""
              />
            </Fade>
          </div>
        </div>
        <div className="upper-right-star w-[100%] h-[150px]">
          <div className="relative ">
            <Fade
              cascade={true}
              fraction={1}
              damping={2}
              duration={300}
              direction="right"
            >
              <img
                src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                alt=""
              />
              <img
                src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                alt=""
              />
              <img
                src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                alt=""
              />
            </Fade>
          </div>
        </div>
        <div
          className="container mx-auto flex justify-center items-center flex-col px-5"
          id="project_header"
        >
          <div
            className="md:w-[40%] w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] md:mt-0 mt-[80px]"
            id
          >
            <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
              <span>MANIFESTATIONS OF OUR SUCCESS</span>
            </h3>
          </div>
          <div className="sm:text-lg text-md text-[#9C1212] text-center mb-16">
            Our 20-year expedition of performing miracles, particularly our
            rituals and experiences that push brands
            <br class="md:block hidden" />
            into cultural consciousness, have taught us to understand the
            rapidly-evolving world around us.
            <br class="md:block hidden" />
            Here are the good works that we were able to spread through our
            brand messengers!
          </div>
          {/* <div className="sm:text-lg text-md text-[#9C1212] text-center mb-16">
            Our 20-year expedition of performing miracles, particularly our rituals and experiences that push brands 
            <br className="md:block hidden"/>
            into cultural consciousness, have taught us to understand the rapidly-evolving world around us.
            <br className="md:block hidden"/> Here are the good works that we were able to spread through our brand messengers!
          </div> */}
        </div>

        {/* <div className="mx-auto bg-[#9C1212] mb-16">
          <div className="grid md:grid-cols-2">
            <div className="block lg:min-h-[390px]">
              <div className="video-container overflow-hidden h-full" data-ref="">
                <Vimeo 
                  id="ceVideo"
                  video="816970611"
                  controls={true}
                  loop={true}
                  responsive={true}
                  playsInline={true}
                  muted={false}
                  autoplay={false}
                  quality='720p'
                  height="500"
                  width="500"
                  className="w-full h-full grid bg-white"
                />
              </div>
            </div>
            <div className="flex justify-center flex-col md:p-16 p-5">
              <div className="mb-5">
                <h3 className="md:text-3xl text-xl text-white">
                  <span>KALAYAAN 2021</span>
                </h3>
              </div>
              <div className="sm:text-lg text-md text-white">
                VEAP is the official venue partner of the 
                <br className="lg:block hidden" />123rd Philippines Independence Day celebration 
                <br className="lg:block hidden" />in partnership with Consulate General of the 
                <br className="lg:block hidden" />Philippines New York and Philippine 
                <br className="lg:block hidden" />Center New York.
              </div>
              <div className="mt-10">
                <a
                  className={`__button button-3 button-3-2 text-primary py-2 md:px-10 px-4 bg-white font-bold rounded-xl text-md text-light inline`}
                  href="/projects/kalayaan-2021"
                  rel="noopener noreferrer"
                >
                  <span>View this project</span>
                </a>
              </div>
            </div>
          </div>
        </div> */}
        <div className="max-w-[1920px] block mx-auto">
          <div
            id="projects"
            className="flex flex-wrap justify-center md:gap-x-16 gap-x-7 md:gap-y-8 gap-y-4 md:mx-8 mx-5"
          >
            {project.map((e, i) => {
              return (
                <div key={e.title} className="md:w-auto">
                  <div className="relative stained-glass-- stained-glass-messenger flex flex-col w-[100%] mx-auto">
                    <a className="icon-wrapper text-center" href={e.src}>
                      <img
                        src={`${e.imgsrc}.png`}
                        className="mx-auto lg:max-w-[300px]"
                        sizes="(max-width: 575px) 300px, 400px"
                        srcSet={`${e.img}.png 400w, ${e.img}.png 300w`}
                        alt=""
                      />
                    </a>
                  </div>
                  {/* <div>
                    <a
                      href="/projects"
                      className={`__button button-3 flex items-center text-center text-black md:py-2 py-1 md:px-[50px] px-4 bg-white rounded-xl lg:text-lg text-[1rem] inline mx-auto`}
                    >
                      <span>VIEW MORE PROJECTS</span>
                    </a>
                  </div> */}
                </div>
                // <section
                //   id="titleSection"
                //   className="relative bg-[#FFDEC1] md:py-[3rem]"
                // >
                //   <div className="mx-auto bg-[#000000] md:mb-16">
                //     <div className="container- mx-auto max-w-[1080px]-">
                //       <div className="block overflow-hidden">
                //         {e.type === "video" && (
                //           <div className="video-container " data-ref="">
                //             <Vimeo
                //               id="ceVideo"
                //               video={e.src}
                //               controls={true}
                //               loop={true}
                //               responsive={true}
                //               playsInline={true}
                //               muted={false}
                //               autoplay={false}
                //               quality="720p"
                //               className=""
                //             />
                //           </div>
                //         )}
                //         {e.type === "carousel" && (
                //           //  e.src.map((f, g) => {
                //           //   <h1>Hellow world!</h1>
                //           //  })
                //           <CarouselItems data={e} />
                //         )}
                //         {e.type === "image" && (
                //           <img src={e.src} alt="" srcset="" />
                //         )}
                //         {/* {e.type === "video1" && (
                //           <video
                //             autoplay={false}
                //             muted="muted"
                //             loop="loop"
                //             controls="on"
                //             class="video-container"
                //           >
                //             <source
                //               src="https://player.vimeo.com/external/613323420.hd.mp4?s=6045b21476e92fbdb8fb055de72ed4d5effac0f9&profile_id=175"
                //               type="video/mp4"
                //             ></source>
                //           </video>
                //         )} */}
                //       </div>
                //     </div>
                //   </div>
                //   <div className="container mx-auto flex justify-center items-center flex-col md:max-w-[80%] px-5">
                //     <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-10 mb-10">
                //       <h3 className="md:text-2xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                //         <span>{ReactHtmlParser(e.headline)}</span>
                //       </h3>
                //       <h4 className="md:text-xl text-lg text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                //         <span>{ReactHtmlParser(e.subheadline)}</span>
                //       </h4>
                //     </div>
                //     <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-16">
                //       {ReactHtmlParser(e.description)}
                //     </div>
                //   </div>
                // </section>
              );
            })}
            <div className="desktop-logo">
              <div className="bottom-left-star z-9 w-[100%] h-[150px]">
                <div className="relative ">
                  <Fade cascade={true} fraction={1} damping={1} duration={500}>
                    <img
                      src="/img/landing/stained-glass/lower-left/IMG_7888-min.PNG"
                      className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                      alt=""
                    />
                    <img
                      src="/img/landing/stained-glass/lower-left/IMG_7887-min.PNG"
                      className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                      alt=""
                    />
                    <img
                      src="/img/landing/stained-glass/lower-left/IMG_7889-min.PNG"
                      className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                      alt=""
                    />
                  </Fade>
                </div>
              </div>
              <div className="bottom-right-star z-9 w-[100%] h-[150px]">
                <div className="relative ">
                  <Fade cascade={true} fraction={1} damping={1} duration={500}>
                    <img
                      src="/img/landing/stained-glass/lower-right/IMG_7885-min.PNG"
                      className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                      alt=""
                    />
                    <img
                      src="/img/landing/stained-glass/lower-right/IMG_7884-min.PNG"
                      className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                      alt=""
                    />
                    <img
                      src="/img/landing/stained-glass/lower-right/IMG_7886-min.PNG"
                      className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                      alt=""
                    />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Manifestitions;
