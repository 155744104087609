import { Fade } from "react-awesome-reveal";
import "./Bottom.css";

const Bottom = () => {
  return (
    <section id="bottom" className="bg-[#FFDCC3] md:pb-[8rem] pb-[3rem]">
      <Fade cascade={1000}>
        <div className="grid md:grid-cols-[45%_55%] ipad-bottom">
          <div className="w-full h-full">
            <div className="relative ipad-bottom-picture">
              <div>
                <img src="/img/landing/Bottom-background-min-2.png" alt="" />
              </div>
              <div className="stained-glass stained-glass-bottom absolute top-[21%] left-[3%] w-[94%] h-[58.5%] overflow-hidden">
                <img src="/img/landing/Bottom-laptop-min-2.png" className="w-full" alt="" />
              </div>
            </div>
          </div>
          <div className="content flex items-center justify-center flex-col md:px-10 px-5 lg:py-0 py-5 bg-[#ffdec2]">
            <div className="md:w-10/12">
              <h4 className="md:text-3xl text-2xl text-[#6E1363] mb-3 lg:text-left text-center">
                DISCOVER WHAT WE CAN DO FOR YOU!
              </h4>
              <div className="text-xl font-bold text-[#9C1212] mb-4">
                Book your FREE Tagline 360 Brand Religion Blueprint Session.
              </div>
              <div className="text-lg text-[#9C1212]">
                Schedule a 30-minute session with our brand evangelists and
                start your brand’s journey to achieving legendary status. This
                session comes at no cost, and no commitment. During this call,
                you will receive your own Brand Religion Blueprint which
                contains:{" "}
              </div>
              <ul
                className="px-10 py-2 text-lg text-[#9C1212]"
                style={{ listStyleType: "unset" }}
              >
                <li className="mb-3">
                  <div className="font-bold">Brand Religion Statement</div>
                  <div>
                    We help you define your core purpose and envision how your
                    brand can transform consumer’s lives.
                  </div>
                </li>
                <li>
                  <div className="font-bold">
                    Brand Religion Challenges and Solutions
                  </div>
                  <div>
                    We identify your brand’s challenges and discover the
                    opportunities you can utilize to achieve your business goals
                    and desired results.
                  </div>
                </li>
                <li>
                  <div className="font-bold">
                    Unique Experiential Proposition
                  </div>
                  <div>
                    We strategically analyze your current brand position to plan
                    the unique experience that you can offer to your consumers.
                  </div>
                </li>
              </ul>
              <div className="py-3">
                {/* <input
                            type="text"
                            className="form-control
                                block
                                w-full
                                px-5
                                py-3
                                mb-3
                                text-base
                                font-normal
                                text-[#C18583]
                                bg-[#EFC6BD] bg-clip-padding
                                rounded-xl
                                transition
                                ease-in-out
                                m-0
                                placeholder-[#C18583]
                                focus:text-[#C18583] focus:bg-white focus:border-[#C18583] focus:outline-none
                            "
                            id="name"
                            placeholder="Name*"
                            />
                        <input
                            type="email"
                            className="form-control
                                block
                                w-full
                                px-5
                                py-3
                                mb-3
                                text-base
                                font-normal
                                text-[#C18583]
                                bg-[#EFC6BD] bg-clip-padding
                                rounded-xl
                                transition
                                ease-in-out
                                m-0
                                placeholder-[#C18583]
                                focus:text-[#C18583] focus:bg-white focus:border-[#C18583] focus:outline-none
                            "
                            id="email"
                            placeholder="Email*"
                            />
                        <input
                            type="number"
                            className="form-control
                                block
                                w-full
                                px-5
                                py-3
                                mb-3
                                text-base
                                font-normal
                                text-[#C18583]
                                bg-[#EFC6BD] bg-clip-padding
                                rounded-xl
                                transition
                                ease-in-out
                                m-0
                                placeholder-[#C18583]
                                focus:text-[#C18583] focus:bg-white focus:border-[#C18583] focus:outline-none
                            "
                            id="contact_number"
                            placeholder="Contact Number*"
                            /> */}
              </div>
              <a
                className={`mx-auto w-fit __button button-2 z-10 hover:text-[#FFFFFF] hover:bg-[#9C1212] ease-in-out duration-300 flex items-center text-white md:py-2 py-1 md:px-10 px-4 bg-primary rounded-xl md:text-xl text-sm text-light`}
                target="_blank"
                href="https://meetings.hubspot.com/francis-miranda/free-brand-religion-audit?embed=true"
              >
                <span>GET MY BRAND RELIGION BLUEPRINT</span>
              </a>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};
export default Bottom;
