import ReactHtmlParser from "react-html-parser";

const project = [
  {
    title: "Support Ninja",
    img: "/img/projects/main/aaa.png",
    subheadline: "Training that You Enjoyed and Not Endured: Support Ninja",
    introduction: `
    Support Ninja, one of the largest process outsourcing companies in the Philippines, was looking to revolutionize its customer service training program. They wanted to create an engaging and enjoyable training experience that would resonate with thousands of employees. Tagline 360 and Skill Simplified were brought on board to leverage their expertise in conceptualizing, creating, and producing robust educational training programs. 
        `,
    challenge: `
    The challenge was transforming a traditional customer service training program into an engaging and enjoyable experience that would increase employee participation and comprehension. The training needed to be dynamic, resonating with the diverse learning styles of the thousands of employees.
    `,
    solution: `
    Tagline 360 and Skill Simplified partnered to create a unique training program: <br>
    <div class="pl-5">
    1. Audience Research: We started by researching the audience to identify their learning styles. This allowed us to tailor the training program to the employees, ensuring it would be engaging and effective. <br>
    2. Use of Metaphors: We used metaphors that resonated with the audience to make the training relatable and exciting. This innovative approach turned the training into an experience that employees enjoyed rather than endured. <br>
    3. Production of Materials and Videos: We produced all the necessary materials and videos, creating a comprehensive training program that catered to different learning styles and preferences. The training program was integrated seamlessly into the client's Learning Management System.
    </div>
    `,
    result: `
    The revamped training program led to increased team participation and improved course comprehension. Employees were more engaged in the training, leading to a more effective learning experience. This improved the quality of customer service and boosted employee satisfaction.
    `,
    conclusion: `
    This case study demonstrates Tagline 360 and Skill Simplified's expertise in transforming traditional training programs into engaging learning experiences. Our innovative approach, tailored to the audience's learning styles, resulted in a training program that employees enjoyed and benefited from, leading to improved customer service. <br><br>
    By partnering with Tagline 360 and Skill Simplified, Support Ninja revolutionized their customer service training, resulting in increased participation and comprehension. 
    `,
  },
];

const Landing = () => {
  return (
    <>
      {project.map((e, i) => {
        return (
          <section
            id="titleSection"
            className="relative bg-[#FFDEC1] md:pb-[3rem]"
          >
            <div className="mx-auto bg-[#000000] md:mb-16">
              <div className="container- mx-auto max-w-[1080px]-">
                <div className="block overflow-hidden">
                  {/* {e.type === "video" && (
                <div className="video-container " data-ref="">
                  <Vimeo
                    id="ceVideo"
                    video={e.src}
                    controls={true}
                    loop={true}
                    responsive={true}
                    playsInline={true}
                    muted={false}
                    autoplay={false}
                    quality="720p"
                    className=""
                  />
                </div>
              )} */}
                  {/* {e.type === "carousel" && (
                //  e.src.map((f, g) => {
                //   <h1>Hellow world!</h1>
                //  })
                <CarouselItems data={e} />
              )} */}
                  <img src={e.img} alt="" srcset="" className="object-cover object-top w-full"/>
                  {/* {e.type === "video1" && (
                          <video
                            autoplay={false}
                            muted="muted"
                            loop="loop"
                            controls="on"
                            class="video-container"
                          >
                            <source
                              src="https://player.vimeo.com/external/613323420.hd.mp4?s=6045b21476e92fbdb8fb055de72ed4d5effac0f9&profile_id=175"
                              type="video/mp4"
                            ></source>
                          </video>
                        )} */}
                </div>
              </div>
            </div>
            <div className="container mx-auto flex justify-center items-center flex-col md:max-w-[80%] px-5">
              <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-10 mb-10">
                <h3 className="md:text-2xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                  <span>{ReactHtmlParser(e.title)}</span>
                </h3>
                <h4 className="md:text-xl text-lg text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                  <span>{ReactHtmlParser(e.subheadline)}</span>
                </h4>
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Introduction</span>
                <br />
                {ReactHtmlParser(e.introduction)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Challenge</span>
                <br />
                {ReactHtmlParser(e.challenge)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">The Solutions</span>
                <br />
                {ReactHtmlParser(e.solution)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">The Results</span>
                <br />
                {ReactHtmlParser(e.result)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Conclusion</span>
                <br />
                {ReactHtmlParser(e.conclusion)}
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default Landing;
