import React, { useState, useEffect } from "react";

import Messenger from "src/components/whoweare/Messenger";

export default function WhoWeAre() {
  const [showFormModal, setFormModal] = useState(false);
  const [region, setRegion] = useState("");
  const [portalId, setPortalId] = useState("");
  const [formId, setFormId] = useState("");

  const handleModal = (region, portalId, formId) => {
    setRegion(region);
    setPortalId(portalId);
    setFormId(formId);
    setFormModal(true);
  };

  const handleClose = () => {
    setFormModal(false);
  };
  return (
    <div className="max-w-[1920px] block mx-auto overflow-x-hidden">
      <div id="aboutUsPage">
        <Messenger modal={handleModal} />
        
      </div>
    </div>
  );
}
