import ReactHtmlParser from "react-html-parser";

const project = [
  {
    title: "The Joint",
    website: "https://thejointusa.com/",
    photos: "Use Stock Photos",
    img: "/img/projects/main/ccc.png",
    subheadline: "Resonating with Values to Grow The Joint Dispensary",
    introduction: `
    The Joint Dispensary, a cannabis dispensary operated by members of the Seneca Community in Salamanca, New York, was struggling in a highly competitive market. With numerous dispensaries in the area, differentiation became a significant issue. 
        `,
    challenge: `
    The Joint Dispensary was in a saturated market with little to no differentiation among competitors. They needed to define their brand and establish a unique selling proposition to stand out and attract customers.
        `,
    solution: `
    Tagline 360 implemented a multi-faceted strategy to tackle The Joint Dispensary's challenges: <br>
    <div class="pl-5">
    1. Brand Definition: We started by defining what The Joint Dispensary stands for, connecting the brand to traditional Senecan values. We communicated these through the value proposition of a 5-star Dispensary, where all products are community-reviewed, and only 5-star products are carried. <br>
    2. Website Creation: Recognizing the importance of a solid online presence, we swiftly created a website communicating The Joint Dispensary's values and product offerings. The user-friendly design and easy navigation enhanced the overall customer experience. <br>
    3. Social Media Strategy: To drive foot traffic to the dispensary, we crafted a social media strategy not focusing on products but focusing instead on community building through weekly events and offers targeted toward adults. This strategy increased brand visibility and fostered a sense of community around the dispensary.
    </div>
    `,
    result: `
    Implementing our strategies led to a consistent increase in foot traffic at The Joint Dispensary. The brand saw its highest sales ever during its 4/20 day, demonstrating the effectiveness of our approach in driving customer engagement and boosting sales.
    `,
    conclusion: `
    This case study illustrates Tagline 360's expertise in applying the brand religion methodology to define a brand's identity, differentiate it from competitors, and drive customer engagement. Our strategic approach helped The Joint Dispensary overcome its challenges and achieve record-breaking sales. <br> <br>
    By partnering with Tagline 360, The Joint Dispensary established a unique brand identity rooted in traditional Senecan values, created a solid online presence, and implemented a successful social media strategy that increased foot traffic and boosted sales. 
    `,
  },

  {
    title: "Rosetta Languages",
    website: "https://rosettalanguages.org/",
    photos: "Use Stock Photos",
    img: "/img/projects/main/ccc1.png",
    subheadline: "Interpreting a Vision for Success: Rosetta",
    introduction: `
    Rosetta Languages, an interpretation and translation service catering to local government organizations and various language communities, desired to expand its footprint and communicate its identity and services. Tagline 360 was brought on board to harness its branding and website creation expertise to achieve these goals.        `,
    challenge: `
    Rosetta Languages needed to effectively communicate who they were, explain their services to potential customers, and increase their market presence. They required a powerful brand mythology and a website to guide potential customers through a systematic path to purchase.        `,
    solution: `
    Tagline 360 implemented a multi-pronged strategy:
    <div class="pl-5">
    1. Brand Mythology: We began by discovering the inspiring story of Rosetta Language's founder and his love for languages. This powerful narrative was woven into the brand's mythology, forming the foundation of our branding efforts. <br>
    2. Website Design: We designed a user-friendly website that systematically guided potential customers from identifying their problems to finding solutions offered by Rosetta Languages. The website was also developed to establish Rosetta Languages as experts in their field. <br>
    3. Relationship Building: To foster trust and build relationships, we prominently featured the faces of Rosetta Languages' translators and interpreters on the website. This human touch added authenticity and relatability to the brand. <br>
    4. Visual Representation of Company Pillars: We created compelling imagery to represent the four pillars of Rosetta Languages in an easy-to-understand manner, making the company's values and mission clear to potential customers.
    </div>
    `,
    result: `
    The result was a new, easy-to-navigate website primed to convert potential customers. The brand mythology resonated with visitors, while the systematic path-to-purchase, relationship-building features, and clear visual representation of the company's pillars made the website an effective tool for attracting and retaining customers.
    `,
    conclusion: `
    This case study showcases Tagline 360's expertise in branding and creating powerful, creative websites. By understanding Rosetta Languages' needs and aligning our strategies accordingly, we created a strong brand identity and an effective website that increased their market footprint. <br><br>
    Our work with Rosetta Languages underlines our commitment to delivering results for our clients, further establishing Tagline 360 as a trusted partner in the industry.
    `,
  },
  {
    title: "MODELHUNT INTERNATIONAL",
    website: "https://www.magicalsunset.com",
    photos: "Use Stock Photos",
    img: "/img/projects/main/g.png",
    subheadline:
      "Modelhunt International NYC - A Journey Towards Diversity and Inclusivity",
    introduction: `
    Modelhunt International is a modeling search dedicated to celebrating diversity and inclusivity. Their mission is to provide a platform for aspiring models of all ages, sizes, and looks to be discovered and appreciated. With a desire to expand to New York, one of the world's top fashion capitals, Modelhunt International aimed to organize an annual fashion show event featuring a diverse range of models. They partnered with Tagline 360, an experienced event planning and digital marketing company, to ensure a successful launch of their institution in New York.
    `,
    challenge: `
    The challenge at hand was multifaceted: to establish Modelhunt International's presence in the competitive New York fashion market while advocating for diversity and inclusivity in the fashion industry. They needed to launch an annual fashion show that not only celebrated diversity but also conveyed a powerful message about representation. Tagline 360 was tasked with organizing the event, executing digital marketing strategies, and attracting participants who shared their commitment to inclusivity.
    `,
    solution: `
    Tagline 360 devised a strategy that engaged Modelhunt International's existing Instagram followers and newsletter subscribers. Through a digital awareness campaign, they highlighted the benefits of participating in Modelhunt International NYC, including exposure to modeling agencies, specialized training, professional photo shoots, and the chance to be featured in magazines. Social media and email marketing generated excitement, drawing over 1700 applicants. <br><br>
    The event concept centered on a full-day fashion show celebrating models from diverse backgrounds. It was divided into three segments, each featuring specific categories such as Plus Size and Edgy models, Petite and Mature models, and Mainstream models. This approach provided a platform for all types of models to shine, emphasizing diversity and uniqueness. The event successfully promoted inclusivity in the fashion industry. <br><br>
    The Metropolitan Pavilion in Manhattan was chosen as the venue due to its central location and spaciousness. It attracted attendees from all over New York, contributing to the event's success. Tagline 360 partnered with The Make-up Academy to ensure models looked their best on the runway, enhancing the overall fashion presentation. Strong relationships with event suppliers allowed Tagline 360 to secure high-quality elements for the event while adhering to budget constraints.
    `,
    result: `
    The fashion show event drew 300 attendees across the three segments, serving as a testament to changing industry standards that emphasize inclusivity and diversity. Modelhunt International gained recognition in New York, with models featured in New York Fashion Week shows and signed by leading agencies. The institution's mission inspired aspiring models to break barriers and pursue their dreams.
    `,
    conclusion: `
    Through strategic planning, impeccable execution, and a dedication to diversity and inclusivity, Tagline 360 successfully launched Modelhunt International in New York. The annual fashion show showcased a diverse array of models, reinforcing the institution's commitment to inclusivity. Modelhunt International's success underscored the importance of diversity within the fashion industry, opening doors for aspiring models and paving the way for greater representation in the world of fashion.    `,
  },
];

const Landing = () => {
  return (
    <>
      {project.map((e, i) => {
        return (
          <section
            id="titleSection"
            className="relative bg-[#FFDEC1] md:pb-[3rem]"
          >
            <div className="mx-auto bg-[#000000] md:mb-8">
              <div className="container- mx-auto max-w-[1080px]-">
                <div className="block overflow-hidden">
                  {/* {e.type === "video" && (
                <div className="video-container " data-ref="">
                  <Vimeo
                    id="ceVideo"
                    video={e.src}
                    controls={true}
                    loop={true}
                    responsive={true}
                    playsInline={true}
                    muted={false}
                    autoplay={false}
                    quality="720p"
                    className=""
                  />
                </div>
              )} */}
                  {/* {e.type === "carousel" && (
                //  e.src.map((f, g) => {
                //   <h1>Hellow world!</h1>
                //  })
                <CarouselItems data={e} />
              )} */}
                  <img
                    src={e.img}
                    alt=""
                    srcset=""
                    className="object-cover object-top w-full"
                  />
                  {/* {e.type === "video1" && (
                          <video
                            autoplay={false}
                            muted="muted"
                            loop="loop"
                            controls="on"
                            class="video-container"
                          >
                            <source
                              src="https://player.vimeo.com/external/613323420.hd.mp4?s=6045b21476e92fbdb8fb055de72ed4d5effac0f9&profile_id=175"
                              type="video/mp4"
                            ></source>
                          </video>
                        )} */}
                </div>
              </div>
            </div>
            <div className="container mx-auto flex justify-center items-center flex-col md:max-w-[80%] px-5">
              {/* <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-16">
                <span>Photos: {ReactHtmlParser(e.photos)}</span>
                {e.website && (
                  <>
                    <br />
                    <span>
                      Website:{" "}
                      <a href={e.website} target="_blank" className="underline">
                        {e.website}
                      </a>
                    </span>
                  </>
                )}
              </div> */}
              <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-10 mb-10">
                <h3 className="md:text-2xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                  <span>{ReactHtmlParser(e.title)}</span>
                </h3>
                <h4 className="md:text-xl text-lg text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                  <span>{ReactHtmlParser(e.subheadline)}</span>
                </h4>
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Introduction</span>
                <br />
                {ReactHtmlParser(e.introduction)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Challenge</span>
                <br />
                {ReactHtmlParser(e.challenge)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">The Solutions</span>
                <br />
                {ReactHtmlParser(e.solution)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">The Results</span>
                <br />
                {ReactHtmlParser(e.result)}
              </div>
              <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span className="font-bold">Conclusion</span>
                <br />
                {ReactHtmlParser(e.conclusion)}
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default Landing;
