import { Carousel } from 'react-responsive-carousel';
import Vimeo from 'src/components/vimeo';

const CarouselItems = ({ data:post }) => {
    var indents = [];
    for (let i = 1; i <= post.src; i++) {
        indents.push(
            <div key={i}>
                <img src={`${post.gallerySrc}${i}.jpg`} alt={`${post.title} Banner`} />
                {/* {post.gallerySrc}{i} */}
            </div>
        )
    }
    return (
        <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            stopOnHover={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={2000}
            emulateTouch={true}
            className="overflow-hidden"
        >
            {indents}
        </Carousel>
    );
}

const Media = ({ data }) => {
    switch (data.type) {
        case 'video':
            return (
                <div className="video-container " data-ref="">
                    <Vimeo
                        id="ceVideo"
                        video={data.video}
                        controls={true}
                        loop={true}
                        responsive={true}
                        playsInline={true}
                        muted={false}
                        autoplay={false}
                        quality='720p'
                        className=""
                    />
                </div>
            )
        case 'carousel':
            return <CarouselItems data={data} />
        default:
            return <img src={data.banner} alt="" srcSet="" className="object-cover object-top w-full" />
    }
}
export default Media;
