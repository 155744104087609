import React, { useState, useEffect } from "react";

export default class WorkWithUsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true,
            loading: false,
            selected: this.props.selected,
            formModalOpen: false,
        };
    }

    render() {
        const { ...result } = this.state.selected;

        return (
            <>
                {result.video && (
                    // <div className="video-modal-wrapper">
                    <video
                        id="video"
                        className="video-content lg:max-w-[760px]"
                        controls
                        autoPlay
                        preload="none"
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    >
                        <source src={result.video} type="video/mp4" />
                    </video>
                    // </div>
                )}
            </>
        );
    }
}
