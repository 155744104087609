import { useState } from "react";
import LengendaryBrandModal from "../modal/LengendaryBrandModal";
import ReactHtmlParser from "react-html-parser";
import { Fade } from "react-awesome-reveal";
import { useEffect } from "react";

const manifestitations = [
  {
    title: "KALAYAAN 2021",
    description: "",
    long_description: ``,
    image: "/img/about/manifestitions/_kalayaan.png",
  },
  {
    title: "FARA 2021",
    description: "",
    long_description: ``,
    image: "/img/about/manifestitions/_FARA-2.png",
  },
  {
    title: "ALAB NG LAHI",
    description: "",
    long_description: ``,
    image: "/img/about/manifestitions/_Alab.png",
  },
  {
    title: "ETIHAD",
    description: "",
    long_description: ``,
    image: "/img/about/manifestitions/_Etihad.png",
  },
  {
    title: "BAILE FILIPINA",
    description: "",
    long_description: ``,
    image: "/img/about/manifestitions/_sininglahi.png",
  },
];
const Manifestitions = () => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  const handleClick = (i) => {
    setShowModal(true);
    setSelected(manifestitations[i]);
  };

  const body = document.querySelector("body");
  useEffect(() => {
    if (showModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "initial";
    }
  }, [showModal]);

  return (
    <section
      id="brands"
      className="relative flex justify-center bg-[#9C1212] md:py-[8rem] py-4 px-5"
    >
      <div className="container mx-auto flex justify-center items-center flex-col py-[1rem] max-w-[1280px]">
        <div className="mb-2">
          <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[4.5rem]">
            <h3 className="title md:text-3xl text-2xl text-center text-white md:before:-left-[100px] before:-left-[75px] md:after:-right-[100px] after:-right-[75px]">
              <span>MANIFESTATIONS OF OUR SUCCESS</span>
            </h3>
          </div>
        </div>
        <div className="text-white text-center md:w-[60%] md:mb-14 mb-5">
          Our 20-year expedition of performing miracles, particularly our rituals and experiences that push brands <br className="md:block hidden" />
          into cultural consciousness, have taught us to understand the rapidly-evolving world around us.<br className="md:block hidden" />
          Here are the good works that we were able to spread through our brand messengers!
        </div>
        <div className="flex flex-wrap justify-center md:gap-x-14 gap-x-7 md:gap-y-8 gap-y-4 md:mb-16 mb-5">
          {manifestitations.map((e, i) => {
            return (
              <div
                className="group __tile flex items-center justify-center md:w-[350px]"
                key={`legendary-brands-${i}`}
              >
                <div className="rounded-lg overflow-hidden p-2">
                  <img src={e.image} alt="" />
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <a
            href="/projects"
            className={`__button button-3 flex items-center text-black md:py-2 py-1 md:px-10 px-4 bg-white rounded-xl lg:text-xl text-[1rem] inline mx-auto`}
          >
            <span>VIEW MORE PROJECTS</span>
          </a>
        </div>
      </div>
      {showModal && (
        <LengendaryBrandModal
          show={showModal}
          close={() => setShowModal(false)}
          selected={selected}
        />
      )}
    </section>
  );
};
export default Manifestitions;
