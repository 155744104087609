const ProjectPosts = [
    {
        key: "consultancy",
        title: "consultancy",
        img: "/img/projects/main/consultancy.png",
        src: "/projects/consultancy",
        posts: [
            {
                key: "belo",
                src: "/projects/consultancy/belo",
                img: "/img/projects/main/consultancy/belo.png",
            },
            {
                key: "buskowitz",
                src: "/projects/consultancy/buskowitz",
                img: "/img/projects/main/consultancy/buskowitz.png",
            },
            {
                key: "magical-sunset",
                src: "/projects/consultancy/magical-sunset",
                img: "/img/projects/main/consultancy/magical-sunset.png",
            },
            {
                key: "modelhunt-consultancy",
                src: "/projects/consultancy/modelhunt-consultancy",
                img: "/img/projects/main/consultancy/modelhunt-consultancy.png",
            },
            {
                key: "ncpaca",
                src: "/projects/consultancy/ncpaca",
                img: "/img/projects/main/consultancy/ncpaca.png",
            },
            {
                key: "roxbury-and-providence",
                src: "/projects/consultancy/roxbury-and-providence",
                img: "/img/projects/main/consultancy/roxbury-and-providence.png",
            },
            {
                key: "support-ninja",
                src: "/projects/consultancy/support-ninja",
                img: "/img/projects/main/platform-development/support-ninja.png",
            },
            {
                key: "rizome-consultancy",
                src: "/projects/consultancy/the-filipino-story",
                img: "/img/projects/main/consultancy/rizome.png",
            },
        ],
    },
    {
        key: "platform-development",
        title: "platform development",
        img: "/img/projects/main/platform-development.png",
        src: "/projects/platform-development",
        posts: [
            {
                key: "3comply",
                src: "/projects/platform-development/3comply",
                img: "/img/projects/main/platform-development/3comply.png",
            },
            {
                key: "the-joint-platform",
                src: "/projects/platform-development/the-joint-platform",
                img: "/img/projects/main/platform-development/platform-the-joint.png",
            },
            //   {
            //     key: "magical-sunset-platform",
            //     src: "/projects/platform-development/magical-sunset-platform",
            //     img: "/img/projects/main/platform-development/platform-magical-sunset.png",
            //   },
            {
                key: "rosetta-languages-platform",
                src: "/projects/platform-development/rosetta-languages-platform",
                img: "/img/projects/main/platform-development/platform-rosetta-languages.png",
            },
            {
                key: "rizome-platform",
                src: "/projects/platform-development/rizome-bamboo",
                img: "/img/projects/main/platform-development/rizome.png",
            },
            {
                key: "reset-wellness",
                src: "/projects/platform-development/reset-wellness",
                img: "/img/projects/main/platform-development/wellness.png",
            },
        ],
    },
    {
        key: "branding",
        title: "branding",
        img: "/img/projects/main/branding.png",
        src: "/projects/branding",
        posts: [
            {
                key: "millennium-center",
                src: "/projects/branding/millennium-center",
                img: "/img/projects/main/branding/millennium-center.png",
            },
            {
                key: "modelhunt-international",
                src: "/projects/branding/modelhunt-international",
                img: "/img/projects/main/branding/modelhunt-international.png",
            },
            {
                key: "rosetta-languages",
                src: "/projects/branding/rosetta-languages",
                img: "/img/projects/main/branding/rosetta-languages.png",
            },
            {
                key: "the-joint",
                src: "/projects/branding/the-joint",
                img: "/img/projects/main/branding/the-joint.png",
            },
            {
                key: "gawad-branding",
                src: "/projects/branding/gawad-branding",
                img: "/img/projects/main/branding/gawad-branding.png",
            },
            {
                key: "hiraya-branding",
                src: "/projects/branding/hiraya-branding",
                img: "/img/projects/main/branding/hiraya-branding.png",
            },
            {
                key: "fara-branding",
                src: "/projects/branding/fara-branding",
                img: "/img/projects/main/branding/fara-branding.png",
            },
            {
                key: "rizome-branding",
                src: "/projects/branding/rizome-branding",
                img: "/img/projects/main/branding/rizome-branding.png",
            },
            {
                key: "ingrid-denter",
                src: "/projects/branding/ingrid-denter",
                img: "/img/projects/main/branding/ingrid-denter.png",
            },
        ],
    },
    {
        key: "activations",
        title: "activations",
        img: "/img/projects/main/activations.png",
        src: "/projects/activations",
        posts: [
            {
                key: "frow",
                src: "/projects/activations/frow",
                img: "/img/projects/main/activations/frow.png",
            },
            {
                key: "magical-sunset",
                src: "/projects/activations/magical-sunset",
                img: "/img/projects/main/activations/magical-sunset.png",
            },
            {
                key: "modelhunt-la",
                src: "/projects/activations/modelhunt-la",
                img: "/img/projects/main/activations/modelhunt-la.png",
            },
        ],
    },
    {
        key: "social-and-content-creation",
        title: "SOCIAL AND CONTENT CREATION",
        img: "/img/projects/main/social-and-content-creation2.png",
        src: "/projects/social-and-content-creation",
        posts: [
            {
                key: "heroes-of-hearing",
                src: "/projects/social-and-content-creation/heroes-of-hearing",
                img: "/img/projects/main/social-and-content-creation/heroes-of-hearing.png",
            },
            {
                key: "national-diversity-fairs",
                src: "/projects/social-and-content-creation/national-diversity-fairs",
                img: "/img/projects/main/social-and-content-creation/national-diversity-fairs.png",
            },
            {
                key: "kloopify",
                src: "/projects/social-and-content-creation/kloopify",
                img: "/img/projects/main/social-and-content-creation/kloopify.png",
            },
            {
                key: "rizome",
                src: "/projects/social-and-content-creation/rizome",
                img: "/img/projects/main/social-and-content-creation/rizome.png",
            },
            {
                key: "the-joint-social",
                src: "/projects/social-and-content-creation/the-joint-social",
                img: "/img/projects/main/social-and-content-creation/the-joint-social.png",
            },
            {
                key: "millennium",
                src: "/projects/social-and-content-creation/millenium-eye-center",
                img: "/img/projects/main/social-and-content-creation/millennium.png",
            },
            {
                key: "optometry",
                src: "/projects/social-and-content-creation/optometry-divas",
                img: "/img/projects/main/social-and-content-creation/divas.png",
            },
            {
                key: "remarkable",
                src: "/projects/social-and-content-creation/remarkable-parenting",
                img: "/img/projects/main/social-and-content-creation/remarkable.png",
            },
            {
                key: "the-filipino-story",
                src: "/projects/social-and-content-creation/the-filipino-story",
                img: "/img/projects/main/the-filipino-story/thumb.png",
            },
        ],
    },
    {
        key: "social-and-content-creation",
        title: "SOCIAL AND CONTENT CREATION",
        img: "/img/projects/main/coaching-and-training.png",
        // src: `${process.env.REACT_APP_WEB_URL}/courses`,
        src: "https://skills-simplified.com/courses",
        target: "_blank",
    },
];

const ProjectPostInner = [
    {
        key: "fara",
        banner: [],
        headline: "FILIPINO AMERICANS FOR RACIAL ACTION",
        subheadline:
            `INVICIBLE NOT INVISIBLE: RALLY AGAINST HATE CRIMES`,
        description: `
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Objective</span>
                    <br />
                    Empower the Filipino American community to combat rising anti-Asian violence and racism, by providing a platform for victims to voice their stories and advocate for legislative change.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Challenge</span>
                    <br />
                    The alarming increase in anti-Asian violence, particularly towards Filipino Americans, highlighted the need for a collective response. Social distancing protocols during the pandemic further complicated efforts to physically gather and rally against these hate crimes.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Solution</span>
                    <br />
                    Attorney Lara Gregory founded the Filipino Americans for Racial Action (FARA) to provide a legal and advocacy platform for minority communities. FARA aimed to create a safe space for victims to share their experiences, educate the public on Filipino-American history, and push for legislative reforms. To overcome the challenge of physical distancing, FARA partnered with the Virtual Events and Activations Platform (VEAPtv) to host a virtual campaign launch, ensuring widespread participation and engagement.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Execution</span>
                    <br />
                    The campaign was launched through VEAPtv, an all-in-one live streaming platform designed for a seamless user experience. With its intuitive interface, it allowed participants to easily access information about FARA, engage with content, and connect with other Filipino Americans through chat and surveys. The single-page design emphasized important content, ensuring that the audience could focus on the movement’s objectives.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Results</span>
                    <br />
                    The virtual event successfully united Filipino Americans across the United States, enabling them to take collective action against anti-Asian hate. Participants were able to engage meaningfully with the content and with each other, despite the physical distance.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Impact</span>
                    <br />
                    The campaign demonstrated the resilience of the Filipino American community in the face of adversity. By leveraging technology, FARA not only empowered victims of racial assault but also inspired broader participation in the fight against racism and xenophobia. The movement highlighted that, even during challenging times, communities can remain connected and influential, leading the charge for justice and equality.
                </div>
            `,
        type: "video",
        // video: "https://vimeo.com/1003978464",
        video: "https://download-video.akamaized.net/v3-1/playback/298f4739-917d-4064-ade3-5e3ea1de4463/ed4ea67a-65e4a0ff?__token__=st=1728031697~exp=1728046097~acl=%2Fv3-1%2Fplayback%2F298f4739-917d-4064-ade3-5e3ea1de4463%2Fed4ea67a-65e4a0ff%2A~hmac=ffeaaa67fd1846350885d90d2806bede50de8c1648e709b518b324297b714bdf&r=dXMtY2VudHJhbDE%3D",
        videoThumb: "/img/posts/thumbnails/fara.png",
        // src: 1,
        gallerySrc: ["/img/projects/main/banners/fara/gallery/"],
    },
    {
        key: "kalayaan",
        banner: [],
        headline: "PHILIPPINE CONSULATE IN NEW YORK",
        subheadline:
            `CELEBRATION OF 123RD INDEPENDENCE DAY`,
        description: `
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Objective</span>
                    <br />
                    Commemorate the 123rd Philippine Independence Day by creating a virtual event that embodies national pride and unity, despite the challenges posed by COVID-19 restrictions in New York City.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Challenge</span>
                    <br />
                    With COVID-19 restrictions in place, traditional large-scale Independence Day gatherings were impossible, posing a significant challenge for the Philippine Consulate General in New York City, which had hoped to host a grand celebration. The challenge was to find an alternative way to bring the spirit of national pride to Filipinos across the digital sphere.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Solution</span>
                    <br />
                    The Virtual Events and Activations Platform (VEAP) proposed moving the celebration online, creating "Kalayaan 2021: 123rd Philippine Independence Day." The theme, "Diwa ng Kalayaan sa Pagkakaisa at Paghilom ng Bayan," was designed to not only honor the history of freedom but also to inspire unity and healing during challenging times.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Execution</span>
                    <br />
                    VEAP executed the virtual celebration by curating a rich program that included interactive booths showcasing Filipino heritage in Visual Arts, Culinary, and Fashion sectors. A virtual concierge from the Philippine Consulate General was set up to provide visitors with information about its services. An online video wall featured Independence Day greetings from Filipinos across the U.S. Northeast, while a partners’ booth offered insights into the Consulate’s corporate and organizational sponsors. To engage visitors, VEAP included features like a fun photobooth, breakout chat rooms, OPM playlists, and a program section for live performances. The event also featured raffles, quizzes, and surveys to enhance user engagement.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Results</span>
                    <br />
                    The event attracted 5,990 visitors and generated 8,742 user engagements, marking it as the largest and most successful virtual event hosted by VEAP. The celebration successfully brought Filipinos together in a digital space, allowing them to experience the essence of Philippine Independence Day despite the physical limitations imposed by the pandemic.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Impact</span>
                    <br />
                    The success of "Kalayaan 2021" solidified VEAP’s reputation for delivering immersive virtual events. It demonstrated the platform’s ability to adapt to challenges and deliver a meaningful experience that resonates with Filipinos worldwide, fostering a sense of unity and national pride even in the digital realm.
                </div>
            `,
        type: "video",
        video: "https://player.vimeo.com/progressive_redirect/playback/1003978424/rendition/1080p/file.mp4?loc=external&log_user=0&signature=3a96555de016f2d264e9dca7a310ad99b11d624ad6ea4dbd1348e87824fc70a0",
        videoThumb: "/img/posts/thumbnails/PCGNYC.png",
        // src: 1,
        gallerySrc: ["/img/projects/main/banners/kalayaan/gallery/"],
    },
    {
        key: "remarkable-parenting",
        banner: [],
        headline: "Remarkable Parenting Academy",
        subheadline:
            `Transforming Fear into Empowerment: The Remarkable Parenting Academy Campaign`,
        description: `
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Objective</span>
                    <br />
                    Elevate Allan Ting as the go-to parenting expert and increase enrollment in his signature program at the Remarkable Parenting Academy, which aims to equip parents with strategies to motivate their children towards achievement.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Challenge</span>
                    <br />
                    The market is saturated with parenting coaches and speakers, each promoting similar messages on child motivation and achievement. The key challenge was distinguishing Allan Ting in a crowded space, making his approach and programs stand out to potential clients.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Insight Discovery</span>
                    <br />
                    The Tagline 360 team embarked on a journey to understand the core concerns of parents deeply. Through extensive interviews, a striking insight emerged: parents' greatest fear wasn't their child's failure but rather the potential for rebellion manifesting in various forms such as academic disinterest, behavioral issues, reluctance towards extracurricular activities, and resistance to healthy eating habits.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Creative Solution</span>
                    <br />
                    Inspired by the theme of rebellion, Tagline 360 devised an innovative campaign titled "Propaganda." This campaign cleverly repurposed the visual style of historical propaganda posters, known for their bold imagery and emotive power, into a parent-friendly context. The designs featured animated cartoons of children engaging in acts of 'rebellion'—from demanding more screen time to refusing homework and music lessons. This approach not only captured attention with its vibrant and relatable visuals but also resonated deeply with parents facing these very challenges.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Execution</span>
                    <br />
                    The "Propaganda" campaign was launched across social media platforms, employing the eye-catching images to cut through the digital noise and engage directly with the target audience. The visuals served as a humorous yet poignant reflection of parental fears, inviting them to explore Allan Ting's programs as a solution.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Results</span>
                    <br />
                    The campaign achieved remarkable success, significantly increasing engagement on social media and driving a surge in sign-ups for one-on-one sessions with Allan Ting. This influx of interest translated into substantial growth for the Remarkable Parenting Academy, with more parents enrolling in the signature program to learn effective strategies for motivating their children and navigating the challenges of rebellion.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Impact</span>
                    <br />
                    By tapping into the underlying fears and challenges faced by parents, the "Propaganda" campaign demonstrated the power of understanding and addressing consumer pain points. The creative and empathetic approach not only differentiated Allan Ting from other parenting experts but also established a strong connection with his audience, proving that insightful, visually compelling campaigns can lead to tangible business results and positive outcomes for families.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    This case study showcases how Tagline 360's strategic thinking, coupled with a deep understanding of target audiences and innovative creative execution, can transform common fears into empowering solutions, driving engagement and business growth.
                </div>
            `,
        type: "carousel",
        src: 3,
        gallerySrc: ["/img/projects/main/banners/remarkable/"],
    },
    {
        key: "optometry-divas",
        banner: [],
        headline: "Optometry Divas",
        subheadline:
            `"See Those Who See You" - Empowering Female Optometrists with Optometry Divas`,
        description: `
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Objective</span>
                    <br />
                    Enhance engagement within the female optometrist community and boost attendance for the annual Optometrist Retreat in Orlando, Florida. This event focuses on aiding female optometrists in managing their practices, enhancing business acumen, and expanding their knowledge base.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Challenge</span>
                    <br />
                    The primary challenge was to resonate with the unique experiences of female optometrists. Recognizing that optometrists dedicate their careers to ensuring others see clearly, often without receiving acknowledgment for their own visibility and needs, Tagline 360 sought to address this disparity.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Insight</span>
                    <br />
                    Delving into the professional lives of optometrists, Tagline 360 uncovered a poignant insight: while optometrists are experts in vision, their own visibility and needs often go unnoticed. This realization led to the campaign's central message: "See those who see you," affirming that optometrists, too, deserve recognition and support.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Solution</span>
                    <br />
                    Building on the idea that optometrists deserve to be seen and supported, Tagline 360 launched the "See Those Who See You" campaign. Utilizing imagery familiar to optometrists, such as eye exam charts and optometric tools, the campaign crafted a series of engaging social media posts and ads. These creative assets not only highlighted the importance of acknowledging optometrists' contributions but also extended an invitation to the Optometrist Retreat, promising a space for growth, learning, and mutual recognition.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Execution</span>
                    <br />
                    The campaign was deployed across various digital platforms, with a significant focus on social media channels and targeted advertising. To foster community engagement, Tagline 360 implemented strategies within Optometry Divas' Facebook group, encouraging discussions and sharing experiences. Calls to action were strategically placed, urging female optometrists to register for the retreat.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Results</span>
                    <br />
                    The "See Those Who See You" campaign generated an immediate increase in inquiries and registrations for the Optometrist Retreat. The resonant message and tailored engagement strategy effectively mobilized the target audience, leading to a significant uptick in anticipated attendance numbers.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Impact</span>
                    <br />
                    Thanks to the innovative approach of the "See Those Who See You" campaign, Optometry Divas is on track to achieve its attendance goals for the upcoming retreat. This campaign not only succeeded in fostering a sense of community and visibility among female optometrists but also underscored the importance of recognizing and supporting those who dedicate their lives to helping others see the world more clearly.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    This case study exemplifies how a deep understanding of an audience's unique challenges and needs can lead to a powerful and effective marketing strategy. By making optometrists feel seen and understood, Tagline 360 has set a new standard for engaging and empowering professional communities.
                </div>
            `,
        type: "carousel",
        src: 3,
        gallerySrc: ["/img/projects/main/banners/optometry/"],
    },
    {
        key: "millenium-eye-center",
        banner: [],
        headline: "Millennium Eye Center",
        subheadline:
            `Illuminating Vision Care with "Maybe It's Your Eyes" Campaign for Millennium Eye Center`,
        description: `
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Objective</span>
                    <br />
                    Raise awareness for Millennium Eye Center and increase optometry appointments, while establishing Dr. Lauretta Justin as a leading expert in Digital Eye Care.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Challenge</span>
                    <br />
                    Many symptoms related to digital eye strain—such as backaches, headaches, migraines, anxiety, and attention deficit disorder—are not commonly associated with eye health issues. Our task was to bridge this knowledge gap and connect potential patients with Millennium Eye Center’s specialized services.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Solution</span>
                    <br />
                    Tagline 360 devised the "Maybe It's Your Eyes" campaign, a clever initiative aimed at highlighting the often-overlooked connection between everyday discomforts and eye health. The campaign utilized striking visuals featuring individuals grappling with pain, characterized by oversized eyes, to visually articulate the message that these common ailments might indeed be eye-related.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Execution</span>
                    <br />
                    The campaign rolled out across various social media platforms and digital advertising channels. The choice of exaggerated imagery served a dual purpose: it not only captured audience attention but also provoked thought regarding the root cause of their symptoms, steering them towards considering eye health as a potential factor.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Results</span>
                    <br />
                    The "Maybe It's Your Eyes" campaign was a resounding success. It significantly boosted engagement on social media, leading to a noticeable increase in walk-in consultations at Millennium Eye Center. Patients began to approach the center, curious if their symptoms were eye-related, demonstrating the campaign’s effectiveness in linking everyday health concerns with the need for professional eye care.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Impact</span>
                    <br />
                    Beyond achieving its initial objectives, the campaign's success has led to its adoption for outdoor advertising, further amplifying its reach. This expansion stands as a testament to the campaign's impact, effectively positioning Dr. Lauretta Justin and Millennium Eye Center at the forefront of addressing digital eye strain and related issues.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    This case study exemplifies how insightful, visually arresting campaigns can transform public perception and behavior, turning overlooked symptoms into opportunities for professional care and treatment.
                </div>
            `,
        type: "carousel",
        src: 3,
        gallerySrc: ["/img/projects/main/banners/millennium/"],
    },
    {
        key: "reset-wellness",
        banner: "/img/projects/main/banners/reset-wellness.png",
        headline: "Reset Wellness",
        subheadline:
            "Sweet Deceptions - Simplifying Health Transformation with Reset Wellness",
        description: `
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Objective</span>
                    <br />
                    Elevate the visibility and appeal of Magda Sommers' unique coaching program, Reset Wellness, designed to empower individuals to reclaim their health by eliminating toxic habits, starting with sugar detoxification.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Challenge</span>
                    <br />
                    The wellness and health coaching industry is saturated with programs demanding significant lifestyle overhauls from participants, often leading to overwhelm and disengagement. Recognizing this barrier to entry, Tagline 360 sought to differentiate Reset Wellness by simplifying the initial step towards a healthier lifestyle, making it more accessible and less daunting for potential clients.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Strategic Insight</span>
                    <br />
                    Through analysis and brainstorming, Tagline 360 pinpointed the root cause of client hesitation: the overwhelming nature of broad lifestyle changes. Identifying sugar detoxification as a manageable and impactful first step, the team proposed a focused approach to introduce clients to Magda's wellness philosophy and coaching style.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Solution</span>
                    <br />
                    In collaboration with Magda Sommers, Tagline 360 developed a specialized sugar detox program, complemented by a workbook and an email drip campaign. This strategically designed entry point was aimed at demonstrating quick wins for participants, building their trust in Magda's methodology, and laying the groundwork for their commitment to more comprehensive health transformations.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Execution</span>
                    <br />
                    The campaign kicked off with targeted messaging that highlighted the detrimental effects of excessive sugar consumption and the benefits of detoxification as a foundational health reset. Interested individuals were invited to join a challenge, facilitated through daily emails providing guidance, support, and motivation. By completing the challenge, participants not only experienced immediate health improvements but were also primed for deeper engagement with Magda's paid programs.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Results</span>
                    <br />
                    The focused approach yielded impressive results, significantly increasing sign-ups for the sugar detox challenge and, subsequently, Magda's broader coaching programs. Participants reported feeling empowered by the tangible benefits of the detox, which served as a catalyst for further health improvements and a deeper commitment to Reset Wellness.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Impact</span>
                    <br />
                    This case study exemplifies the power of simplicity and focus in driving engagement and adoption in the wellness space. By honing in on sugar detoxification as an accessible starting point, Tagline 360 successfully demystified the journey towards better health, making it more approachable for a wider audience. The campaign not only boosted Magda Sommers' client base but also reinforced the effectiveness of incremental change in achieving lasting wellness transformations.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    Tagline 360's strategic insight and innovative approach to marketing Reset Wellness underscore the importance of understanding consumer barriers and crafting messages and programs that resonate with their needs and concerns. This campaign stands as a testament to the potential of targeted, simplified health initiatives to inspire action and foster lasting lifestyle changes.
                </div>
            `,
    },
    {
        key: "the-filipino-story",
        // banner: "/img/projects/main/banners/rizome-consultancy.png",
        headline: "The Filipino Story",
        subheadline:
            "​Tagline 360 Rekindles Cultural Pride Through Social Media",
        description: `
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Objective</span>
                    <br />
                    The Filipino Story aims to share the vibrant culture of the Philippines with a global audience, particularly focusing on igniting passion for Filipino heritage among younger generations living abroad. To achieve this, they partnered with Tagline 360 to develop an engaging social media campaign that would resonate deeply with this demographic.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Challenge</span>
                    <br />
                    However, despite acknowledging their heritage, many young Filipinos abroad lack a deep understanding of its traditions and significance. This presented a challenge for Tagline 360: how do you bridge this knowledge gap in an engaging and relatable way? Understanding this challenge was crucial for crafting an effective solution.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Solution</span>
                    <br />
                    Through in-depth interviews, Tagline 360 discovered a key insight: unique and often unexplained Filipino customs hold immense cultural value. Recognizing this, Tagline 360 decided to focus on customs such as "Pagmamano'' (a gesture of respect to elders), the ubiquitous "Last Supper" artwork in Filipino homes, and "Tara Kain Na" (an invitation to eat). These customs became the foundation for sparking curiosity and encouraging exploration through The Filipino Story’s platforms.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Execution</span>
                    <br />
                    Leveraging on this insight, Tagline 360 and The Filipino Story created a series of engaging social media posts that incorporate both humor and relatability– the content showcased familiar scenarios with a comedic twist. This is strategically done to capture the attention of Filipinos globally and spark conversations. Furthermore, the campaign fostered a sense of community and collective cultural exploration by crowdsourcing content from viewers who shared their experiences and interpretations of Filipino customs.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Results</span>
                    <br />
                    The campaign, while organic, yielded impressive results across social media platforms, experiencing a <strong>50,216% increase in followers on Instagram</strong>, and a <strong>54,661% rise on Facebook</strong>, and <strong>TikTok grew by 1,578%</strong>. The top Reels garnered <strong>3.1M, 2.9M, and 2.7M views on Instagram</strong>, while they reached <strong>1.8M, 597K, and 573K views on Facebook</strong>. TikTok's most viewed posts accumulated almost <strong>a million views</strong>.
                    <br /><br />
                    Additionally, the relatable content resonated widely online, fostering a vibrant community eager to learn and share. Notably, Hollywood and Filipino celebrities like Michael B. Jordan, Jamie Chung, Bretman Rock,  Lea Salonga, Piolo Pascual, and many more engaged with our narrative, further amplifying their reach and impact.

                </div>
            `,
        type: "carousel",
        src: 2,
        gallerySrc: ["/img/projects/main/banners/the-filipino-story/"],
    },
    {
        key: "rizome-bamboo",
        banner: "/img/projects/main/banners/rizome-platform.png",
        headline: "Rizome Bamboo",
        subheadline:
            "​​Crafting the Rizome Bamboo Digital Presence - The Miracle Timber Story",
        description: `
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Objective</span>
                    <br />
                    Elevate awareness of Rizome Bamboo's innovative bamboo wood products, renowned for their strength, durability, and beauty. The goal was to captivate and engage architects and engineers, encouraging them to choose Rizome Bamboo for their projects.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Challenge</span>
                    <br />
                    In a market dominated by traditional building materials, introducing an innovative product like Rizome Bamboo posed a significant challenge. The primary task was to differentiate Rizome Bamboo as not just an alternative, but a superior choice due to its unique qualities and environmental benefits.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Insight Discovery</span>
                    <br />
                    Recognizing the need to compellingly articulate the value proposition of Rizome Bamboo, Tagline 360 identified the concept of "miracle timber" as the cornerstone of the campaign. This narrative was designed to capture the imagination of architects and engineers by emphasizing the extraordinary capabilities and aesthetic appeal of bamboo wood.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Strategic Solution</span>
                    <br />
                    To bring the "miracle timber" story to life, Tagline 360 embarked on a comprehensive website redesign for Rizome Bamboo. The new website was envisioned as a digital showcase, featuring engaging content that highlighted the exceptional properties of Rizome Bamboo — its strength comparable to steel, toughness akin to concrete, and the natural beauty of hardwood.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Execution</span>
                    <br />
                    The website was meticulously crafted to serve as an educational platform, offering visitors in-depth insights into the sustainability, versatility, and innovative applications of bamboo wood. Through captivating visuals, detailed case studies, and accessible technical information, the site effectively communicated the benefits of Rizome Bamboo to a professional audience. Additionally, strategic SEO practices were implemented to enhance online visibility and drive targeted traffic to the website.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Results</span>
                    <br />
                    The revamped website successfully amplified Rizome Bamboo's online presence, resulting in a significant increase in traffic and inquiries from architects and engineers. The compelling storytelling and informative content resonated with the target audience, leading to heightened interest in Rizome Bamboo's products and a noticeable uptick in purchase considerations.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    <span class="font-bold">Impact</span>
                    <br />
                    By positioning Rizome Bamboo as the "miracle timber" and articulating its unique selling points through a dynamic digital platform, Tagline 360 effectively broadened the company's reach within the architectural and engineering communities. The campaign not only raised awareness of Rizome Bamboo's mission and innovative products but also contributed to a growing recognition of bamboo as a viable, sustainable material for modern construction projects.
                </div>
                <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                    This case study exemplifies how strategic digital storytelling and targeted content creation can transform perceptions and drive engagement in a niche market. Through the successful execution of the Rizome Bamboo website redesign, Tagline 360 demonstrated its ability to elevate innovative products and foster meaningful connections between businesses and their ideal customers.
                </div>
            `,
    },
    {
        key: "belo",
        banner: "/img/projects/main/banners/belo.png",
        headline: "Belo",
        subheadline:
            "World Peace in the Workplace: A Beauty Pageant-Inspired Conflict Management Seminar by Skills Simplified and Tagline 360 for Belo Medical Group",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction and Challenge</span>
                <br />
                In the high-pressure world of aesthetic medicine and beauty, conflicts are inevitable. Recognizing this, the Belo Medical Group, a leader in cosmetic surgery, aesthetic medicine, and beauty brands, sought the expertise of Skills Simplified and Tagline 360 to create a tailor-made conflict management training program.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Understanding the market and the client's unique needs is at the heart of what Skills Simplified does. With the Belo Group's heavy involvement in the Miss Universe pageant, the Skills Simplified team took an unprecedented approach: they designed the conflict management seminar with the theme "World Peace," executed like a beauty pageant.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The training was an innovative blend of entertainment and education, where different learning concepts related to conflict management were integrated into activities mirroring the Q&A portion of Miss Universe. Participants were not just passive listeners; they became active participants in the pageant-style seminar, handling real-world conflict scenarios, answering tough questions, and finding peaceful resolutions.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The feedback was overwhelmingly positive. Participants hailed it as the most engaging, interactive, and effective learning seminar they had ever attended. The unique approach made the often-dry subject of conflict management palatable and enjoyable, leading to better retention and application of the concepts learned.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study underscores the power of innovative and customized training programs. Skills Simplified and Tagline 360 took a traditionally mundane topic and turned it into an engaging and memorable experience, proving that creativity and relevance are key when it comes to learning. By making the training relatable and fun, Skills Simplified and Tagline 360 have truly set a new standard in corporate training.
            </div>
        `,
    },
    {
        key: "buskowitz",
        banner: "/img/projects/main/banners/buskowitz.png",
        headline: "Buskowitz",
        subheadline:
            "Lighting the Way to Critical Thinking: A Tailored Training Approach by Skills Simplified and Tagline 360 for Buskowitz",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                In a dynamic and evolving industry like solar power, the ability to think critically is as vital as the sun itself. Recognizing this, Buskowitz, a leading solar energy company, turned to Skills Simplified and Tagline 360 for a unique training solution.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                The task was not just to deliver training on critical thinking but to make it resonate with the solar power context that the staff of Buskowitz navigates every day.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                The process started with the essential pillar of Skills Simplified's approach - tailoring. The trainers embarked on a series of tailoring calls, diving into the heart of Buskowitz's business to understand its unique intricacies and challenges. This deep dive wasn't just about learning the lingo, but about understanding the mindset, the motivations, and the hurdles the team faced.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                With this knowledge in hand, Skills Simplified and Tagline 360 crafted a training program that was far from generic. The concepts of critical thinking were woven into the fabric of solar power, creating a training module that was as relevant as it was enlightening. The staff found themselves not just learning new skills but seeing their everyday work in a whole new light.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The impact was immediate and profound. The staff found the training engaging and incredibly useful, with lessons they could apply directly to their work. This project served as a testament to the power of customized training modules, demonstrating how they could be leveraged to foster critical thinking and drive business growth.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Are you looking for a training solution that goes beyond the generic and delves into the specifics of your business? Connect with Skills Simplified today. Let's create a training program that doesn't just educate but truly illuminates.
            </div>
        `,
    },
    {
        key: "magical-sunset",
        banner: "/img/projects/main/banners/magical-sunset.png",
        headline: "Magical Sunset",
        subheadline:
            "Creating Magic in Niche Markets: Tagline 360’s Marketing Consultancy for Magical Sunset",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                When Magical Sunset, a company known for crafting unique travel experiences, sought to identify new niche markets, they turned to Tagline 360. The goal was to create a bespoke product for a unique market with high spending power. Tagline 360 did not disappoint.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360, leveraging its expertise in niche marketing, identified the LGBTQ+ community as a potential market segment for Magical Sunset. Recognizing the community's increasing influence and spending power, they saw an opportunity for Maria Tsalkos, the founder and CEO of Magical Sunset, to create a unique product tailored to this audience.
                The team at Tagline 360 worked closely with Tsalkos to understand the specific needs and preferences of the LGBTQ+ community. They explored 'blue oceans' - untapped, uncontested market spaces ripe for growth, and helped Tsalkos design a bespoke Magical Sunset product that was both inclusive and appealing to the LGBTQ+ market.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                But Tagline 360's efforts didn't stop there. They also helped Magical Sunset design special webinars aimed at attracting travel agents. These webinars focused on Sustainable Travel and Authentic Travel experiences, key topics of interest in the industry, designed to assist travel agents in building their summer 2024 plans.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                These webinars served a dual purpose. Not only did they help attract travel agents to Magical Sunset's offerings, but they also positioned the company as a thought leader in the field of authentic, bespoke travel experiences. The webinars provided valuable insights and knowledge to travel agents looking to offer unique and personalized experiences to their clients.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                As a result of Tagline 360's strategic consultancy, Magical Sunset successfully managed to tap into a new, high-potential market segment, enhancing its product offering and strengthening its position in the travel industry. Moreover, through the targeted webinars, Magical Sunset was able to establish itself as a leader in creating authentic travel experiences, further solidifying its reputation and brand value.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study is a testament to Tagline 360's ability to identify and capitalize on niche markets, create tailored marketing strategies, and enhance thought leadership, demonstrating their prowess in the field of marketing consultancy.
            </div>
        `,
    },
    {
        key: "modelhunt-consultancy",
        banner: "/img/projects/main/banners/modelhunt-consultancy.png",
        headline: "ModelHunt",
        subheadline:
            "Model Mastery: Shaping Future Icons with Skills Simplified and Tagline 360 for ModelHunt Competition",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                In the world of modeling, beauty isn't just skin deep. It's about purpose, mindset, marketing savvy, and financial acumen. Recognizing this, ModelHunt, the world's first inclusive and diverse modeling competition, partnered with Skills Simplified and Tagline 360 to create a comprehensive training program for aspiring models.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Understandably, succeeding in the highly competitive modeling world requires more than just good looks. Skills Simplified rose to the challenge and constructed a four-module training program aptly named "Model Mastery"—each module designed to equip the models with essential skills to navigate the modeling industry.
                <ol class="my-5">
                    <li><strong>Model Mission:</strong> This module is aimed at helping models find their deeper purpose in modeling. It encouraged them to look beyond the superficial and discover the 'why' behind their career choice.</li>
                    <li><strong>Role Model:</strong> The second module focused on mindset training. It equipped the models with mental resilience and the right attitude to thrive in a challenging marketplace.</li>
                    <li><strong>Model Marketing:</strong> In an era where digital presence is paramount, this module taught the models how to set up and manage their digital infrastructure to promote their modeling business effectively.</li>
                    <li><strong>Model Money:</strong> The final module focused on financial literacy. It gave the models essential money management skills to help them sail through the good times and weather the economic storms.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The response to the Model Mastery program was overwhelmingly positive. The participants found the modules valuable, practical, and relevant to their career paths. The program equipped them with the necessary professional skills and empowered them personally, enabling them to approach their careers with a renewed sense of purpose and confidence.
            </div>
        `,
        type: "",
    },
    {
        key: "ncpaca",
        banner: "/img/projects/main/banners/ncpaca.png",
        headline: "NCPACA",
        subheadline:
            "Crunching Numbers and Brands: A Game-Changing Training Approach by Skills Simplified and Tagline 360 for NCPACA",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Skills Simplified, in collaboration with Tagline 360, was commissioned by the National Council of Philippine-American Canadian Accountants (NCPACA) to deliver a groundbreaking training session at their national convention.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                To reimagine accountancy through the lens of brandology and showcase how the pillars of Tagline 360's brand religion methodology could spur exponential growth in accounting businesses.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The result was an interactive training session that seamlessly integrated talks and exercises, ensuring participants were not just passive listeners but active learners. Skills Simplified crafted a program that delved into differentiating businesses, niching services, and driving growth - all tailored for the unique context of accounting firms. It was education, inspiration, and innovation rolled into one dynamic package.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The session was more than a success; it embodied Skills Simplified's mission - to create training that you enjoy and not endure. Participants left the session equipped with a new perspective on their work and invigorated by the engaging format and practical insights they could directly apply to their businesses.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This project stands as a testament to Skills Simplified's expertise in crafting informative, inspiring, and engaging unique, tailor-fit training programs. It showcases their ability to transform conventional wisdom, infuse it with fresh insights, and present it in a way that resonates with the audience.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Are you seeking speakers and trainers who can turn big corporate events into memorable, transformative experiences? Don't settle for the mundane. Connect with Skills Simplified today, and let's craft a training experience that your team will not just endure but genuinely enjoy. Your journey toward an inspiring and educational event is just a click away.
            </div>
        `,
        type: "",
    },
    {
        key: "roxbury-and-providence",
        banner: "/img/projects/main/banners/roxbury-and-providence.png",
        headline: "Innovation Center: Roxbury and Providence",
        subheadline:
            "From Underdogs to Unicorns: How Skills Simplified and Tagline 360 Ignited Entrepreneurial Success in Underrepresented Communities",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Skills Simplified, in collaboration with Tagline 360, was contracted by the Innovation Center Roxbury and Providence to deliver two pivotal courses as part of their GROW Program. This initiative was designed to nurture entrepreneurs from underrepresented communities, specifically people of color, by providing them with the essential tools to establish and grow their businesses.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                The two courses, 'Refresh Focus' and 'Refresh Marketing', were facilitated by Francis Miranda, the CEO of Skills Simplified and Tagline 360. 'Refresh Focus' guided participants through identifying their ideal consumers, an integral step towards effectively tailoring their products and services. On the other hand, 'Refresh Marketing' provided a roadmap for setting up a robust digital marketing infrastructure, a crucial aspect of business growth in today's digital age.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The response was overwhelmingly positive; both trainings received high marks, with participants expressing their enjoyment and gaining valuable insights from the sessions. Some key learning insights included:
                <ul class="my-5 ml-10" style="list-style-type:disc;">
                    <li>Understanding the importance of identifying your target audience</li>
                    <li>Learning how to create a solid digital marketing infrastructure</li>
                    <li>Recognizing the power of tailored products and services</li>
                    <li>Grasping the importance of continuous learning and adaptation in business</li>
                </ul>
                As a result of these training sessions, 40 entrepreneurs were able to build their businesses' marketing infrastructure and thrive. This case study demonstrates Skills Simplified and Tagline 360's commitment to providing enriching corporate training programs and their dedication to fostering diverse and inclusive entrepreneurship.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This potent partnership between Skills Simplified, Tagline 360, and the Innovation Center Roxbury and Providence is a compelling testament to the transformative power of well-targeted, practical corporate training and consultancy. The entrepreneurial success stories from these underrepresented communities illuminate the immense potential that can be unlocked with the right resources and opportunities.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                As we celebrate these victories, we are reminded of the many more entrepreneurs who await similar opportunities. If you or your organization require training or consultancy services to scale new heights, don't hesitate. Reach out to Skills Simplified and Tagline 360 today. Let's write your success story together!
            </div>
        `,
        type: "",
    },
    {
        key: "support-ninja",
        banner: "/img/projects/main/banners/support-ninja.png",
        headline: "SUPPORT NINJA",
        subheadline: "TRAINING THAT YOU ENJOYED AND NOT ENDURED: SUPPORT NINJA",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Support Ninja, one of the largest process outsourcing companies in the Philippines, was looking to revolutionize its customer service training program. They wanted to create an engaging and enjoyable training experience that would resonate with thousands of employees. Tagline 360 and Skill Simplified were brought on board to leverage their expertise in conceptualizing, creating, and producing robust educational training programs.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenge</span>
                <br />
                The challenge was transforming a traditional customer service training program into an engaging and enjoyable experience that would increase employee participation and comprehension. The training needed to be dynamic, resonating with the diverse learning styles of the thousands of employees.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 and Skill Simplified partnered to create a unique training program:
                <ol class="my-5 ml-5">
                    <li><strong>1. Audience Research:</strong> We started by researching the audience to identify their learning styles. This allowed us to tailor the training program to the employees, ensuring it would be engaging and effective.</li>
                    <li><strong>2. Use of Metaphors:</strong> We used metaphors that resonated with the audience to make the training relatable and exciting. This innovative approach turned the training into an experience that employees enjoyed rather than endured.</li>
                    <li><strong>3. Production of Materials and Videos:</strong> We produced all the necessary materials and videos, creating a comprehensive training program that catered to different learning styles and preferences. The training program was integrated seamlessly into the client's Learning Management System.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The revamped training program led to increased team participation and improved course comprehension. Employees were more engaged in the training, leading to a more effective learning experience. This improved the quality of customer service and boosted employee satisfaction.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study demonstrates Tagline 360 and Skill Simplified's expertise in transforming traditional training programs into engaging learning experiences. Our innovative approach, tailored to the audience's learning styles, resulted in a training program that employees enjoyed and benefited from, leading to improved customer service.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                By partnering with Tagline 360 and Skill Simplified, Support Ninja revolutionized their customer service training, resulting in increased participation and comprehension.
            </div>
        `,
        type: "",
    },
    {
        key: "3comply",
        banner: "/img/projects/main/banners/3comply.png",
        headline: "3COMPLY",
        subheadline: `EXPLAIN IT IN 5 SECONDS: TRANSFORMING 3COMPLY'S DIGITAL PRESENCE TO DRIVE CONVERSIONS`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                3Comply, a B2B business aiding companies struggling with government accreditation and data management faced significant challenges. The intricacy of their services made it difficult for potential clients to understand their offerings, leading to conversion difficulties. They needed a solution to simplify their complex services into digestible information for potential customers.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Tagline 360 tackled this challenge head-on, utilizing the brand religion methodology to create a strong, understandable, relatable brand story that resonates with the target audience.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenge</span>
                <br />
                3Comply's main challenge was the complexity of its services, which made it hard for potential customers to understand what they offered, leading to low conversion rates. They needed a way to break down their offerings into easy-to-understand formats to drive customer comprehension and, ultimately, conversions.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 decided to take a multi-pronged approach to address 3Comply's challenges:
                <ol class="my-5 ml-5">
                    <li>1. Website Redesign: Tagline 360 designed a modern, intuitive website that broke down and explained 3Comply's services. The new design focused on user experience, ensuring potential customers could easily navigate the site and understand the services offered.</li>
                    <li>2. Use of Infographics: To further simplify the understanding of 3Comply's complex services, Tagline 360 created and integrated infographics into the web design. These visual aids allowed potential customers to grasp the services quickly, enhancing their comprehension.</li>
                    <li>3. Service Tiering System: To drive faster conversions, Tagline 360 worked with 3Comply to devise a tiering system for their services. By creating different products with various price points, potential customers could more easily engage with 3Comply, leading to increased conversions.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                Tagline 360's strategic approach significantly improved 3Comply's conversion rates. By simplifying the comprehension of potential customers through website redesign, use of infographics, and service tiering, 3Comply saw a notable increase in conversions due to the enhanced ability of consumers to understand their offerings quickly.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study exemplifies Tagline 360's expertise in leveraging the brand religion methodology to transform a brand's digital presence and drive conversions. Through strategic planning and implementation, we were able to simplify 3Comply's complex services, making them easily understandable for potential customers and ultimately driving faster conversions.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                By trusting in Tagline 360's expertise, 3Comply was able to overcome its challenges and significantly improve its conversion rates - a testament to our commitment to delivering results that matter to our clients.
            </div>
        `,
        type: "",
    },
    {
        key: "millennium-center",
        banner: "/img/projects/main/banners/millennium-eye.png",
        headline: "Millennium Eye Center",
        subheadline: `Illuminating the Hidden Epidemic: Tagline 360's Innovative Strategy for Millennium Eye Center`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                When Dr. Loretta Justin, the founder of Millennium Eye Center, sought to create a unique differentiator for her optometry brand in Orlando, Florida, she turned to Tagline 360.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                The challenge was to create a brand identity that went beyond traditional eye care services and addressed a specific, modern problem: Digital Eye Damage.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360, with its creative flair and strategic thinking, crafted a compelling funnel big idea: "The Hidden Epidemic of Digital Eye Damage." This concept aimed to shed light on the increasing problem of eye damage caused by prolonged exposure to digital screens, a condition affecting both children and adults.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The agency launched an intensive social media and digital ad campaign to generate awareness about this hidden epidemic. The ads were designed to be both informative and engaging, highlighting the dangers of Digital Eye Damage and positioning Millennium Eye Center as a leader in combating it.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The result of this innovative campaign was a significant boost in brand differentiation for the Millennium Eye Center. By focusing on a specific, modern problem, the center was able to position itself as a leader in the field of eye health and wellness.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study showcases Tagline 360's ability to create powerful marketing strategies that not only differentiate brands but also address pressing societal issues. Their work with Millennium Eye Center highlights their innovative approach, strategic thinking, and commitment to creating meaningful change through marketing.
            </div>
        `,
        type: "",
    },
    {
        key: "modelhunt-international",
        banner: "/img/projects/main/banners/modelhunt-la.png",
        headline: "MODELHUNT INTERNATIONAL",
        subheadline:
            "MODELHUNT INTERNATIONAL NYC - A JOURNEY TOWARDS DIVERSITY AND INCLUSIVITY",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Modelhunt International is a modeling search dedicated to celebrating diversity and inclusivity. Their mission is to provide a platform for aspiring models of all ages, sizes, and looks to be discovered and appreciated. With a desire to expand to New York, one of the world's top fashion capitals, Modelhunt International aimed to organize an annual fashion show event featuring a diverse range of models. They partnered with Tagline 360, an experienced event planning and digital marketing company, to ensure a successful launch of their institution in New York.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                The challenge at hand was multifaceted: to establish Modelhunt International's presence in the competitive New York fashion market while advocating for diversity and inclusivity in the fashion industry. They needed to launch an annual fashion show that not only celebrated diversity but also conveyed a powerful message about representation. Tagline 360 was tasked with organizing the event, executing digital marketing strategies, and attracting participants who shared their commitment to inclusivity.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 devised a strategy that engaged Modelhunt International's existing Instagram followers and newsletter subscribers. Through a digital awareness campaign, they highlighted the benefits of participating in Modelhunt International NYC, including exposure to modeling agencies, specialized training, professional photo shoots, and the chance to be featured in magazines. Social media and email marketing generated excitement, drawing over 1700 applicants.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The event concept centered on a full-day fashion show celebrating models from diverse backgrounds. It was divided into three segments, each featuring specific categories such as Plus Size and Edgy models, Petite and Mature models, and Mainstream models. This approach provided a platform for all types of models to shine, emphasizing diversity and uniqueness. The event successfully promoted inclusivity in the fashion industry.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The Metropolitan Pavilion in Manhattan was chosen as the venue due to its central location and spaciousness. It attracted attendees from all over New York, contributing to the event's success. Tagline 360 partnered with The Make-up Academy to ensure models looked their best on the runway, enhancing the overall fashion presentation. Strong relationships with event suppliers allowed Tagline 360 to secure high-quality elements for the event while adhering to budget constraints.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The fashion show event drew 300 attendees across the three segments, serving as a testament to changing industry standards that emphasize inclusivity and diversity. Modelhunt International gained recognition in New York, with models featured in New York Fashion Week shows and signed by leading agencies. The institution's mission inspired aspiring models to break barriers and pursue their dreams.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                Through strategic planning, impeccable execution, and a dedication to diversity and inclusivity, Tagline 360 successfully launched Modelhunt International in New York. The annual fashion show showcased a diverse array of models, reinforcing the institution's commitment to inclusivity. Modelhunt International's success underscored the importance of diversity within the fashion industry, opening doors for aspiring models and paving the way for greater representation in the world of fashion.
            </div>
        `,
        type: "",
    },
    {
        key: "rosetta-languages",
        banner: "/img/projects/main/banners/rosetta-languages.png",
        headline: "ROSETTA LANGUAGES",
        subheadline: "INTERPRETING A VISION FOR SUCCESS: ROSETTA",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Rosetta Languages, an interpretation and translation service catering to local government organizations and various language communities, desired to expand its footprint and communicate its identity and services. Tagline 360 was brought on board to harness its branding and website creation expertise to achieve these goals.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                Rosetta Languages needed to effectively communicate who they were, explain their services to potential customers, and increase their market presence. They required a powerful brand mythology and a website to guide potential customers through a systematic path to purchase.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 implemented a multi-pronged strategy:
                <ol class="my-5 ml-5">
                    <li><strong>1. Brand Mythology:</strong> We began by discovering the inspiring story of Rosetta Language's founder and his love for languages. This powerful narrative was woven into the brand's mythology, forming the foundation of our branding efforts.</li>
                    <li><strong>2. Website Design:</strong> We designed a user-friendly website that systematically guided potential customers from identifying their problems to finding solutions offered by Rosetta Languages. The website was also developed to establish Rosetta Languages as experts in their field.</li>
                    <li><strong>3. Relationship Building:</strong> To foster trust and build relationships, we prominently featured the faces of Rosetta Languages' translators and interpreters on the website. This human touch added authenticity and relatability to the brand.</li>
                    <li><strong>4. Visual Representation of Company Pillars:</strong> We created compelling imagery to represent the four pillars of Rosetta Languages in an easy-to-understand manner, making the company's values and mission clear to potential customers.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The result was a new, easy-to-navigate website primed to convert potential customers. The brand mythology resonated with visitors, while the systematic path-to-purchase, relationship-building features, and clear visual representation of the company's pillars made the website an effective tool for attracting and retaining customers.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study showcases Tagline 360's expertise in branding and creating powerful, creative websites. By understanding Rosetta Languages' needs and aligning our strategies accordingly, we created a strong brand identity and an effective website that increased their market footprint.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Our work with Rosetta Languages underlines our commitment to delivering results for our clients, further establishing Tagline 360 as a trusted partner in the industry.
            </div>
        `,
        type: "",
    },
    {
        key: "the-joint",
        banner: "/img/projects/main/banners/the-joint.png",
        headline: "THE JOINT",
        subheadline: "RESONATING WITH VALUES TO GROW THE JOINT DISPENSARY",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                The Joint Dispensary, a cannabis dispensary operated by members of the Seneca Community in Salamanca, New York, was struggling in a highly competitive market. With numerous dispensaries in the area, differentiation became a significant issue.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                The Joint Dispensary was in a saturated market with little to no differentiation among competitors. They needed to define their brand and establish a unique selling proposition to stand out and attract customers.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 implemented a multi-faceted strategy to tackle The Joint Dispensary's challenges:
                <ol>
                    <li>Brand Definition: We started by defining what The Joint Dispensary stands for, connecting the brand to traditional Senecan values. We communicated these through the value proposition of a 5-star Dispensary, where all products are community-reviewed, and only 5-star products are carried.</li>
                    <li>Website Creation: Recognizing the importance of a solid online presence, we swiftly created a website communicating The Joint Dispensary's values and product offerings. The user-friendly design and easy navigation enhanced the overall customer experience.</li>
                    <li>Social Media Strategy: To drive foot traffic to the dispensary, we crafted a social media strategy not focusing on products but focusing instead on community building through weekly events and offers targeted toward adults. This strategy increased brand visibility and fostered a sense of community around the dispensary.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                Implementing our strategies led to a consistent increase in foot traffic at The Joint Dispensary. The brand saw its highest sales ever during its 4/20 day, demonstrating the effectiveness of our approach in driving customer engagement and boosting sales.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study illustrates Tagline 360's expertise in applying the brand religion methodology to define a brand's identity, differentiate it from competitors, and drive customer engagement. Our strategic approach helped The Joint Dispensary overcome its challenges and achieve record-breaking sales.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                By partnering with Tagline 360, The Joint Dispensary established a unique brand identity rooted in traditional Senecan values, created a solid online presence, and implemented a successful social media strategy that increased foot traffic and boosted sales.
            </div>
        `,
        type: "",
    },
    {
        key: "etihad",
        banner: [],
        headline: "Etihad Airways",
        subheadline: `Tagline 360 Brings Back the Luxurious Etihad A380 to New York in Style`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Etihad Airways, the prestigious flag carrier airline of the UAE, stands as an epitome of luxury in the aviation industry. With a fleet serving over 80 global destinations, Etihad is known for its opulent in-flight services. To celebrate the launch of their new Airbus A350 carrier, Etihad Airways chose to host a captivating product launch event in the heart of Manhattan's Financial District at the exclusive Cipriani Wall Street venue.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenge</span>
                <br />
                The challenge at hand was to ensure that the event aligned seamlessly with Etihad's luxurious brand experience. It was crucial to create an unforgettable evening that would leave a lasting impression on key stakeholders, including corporate executives, partners, sponsors, and devoted employees.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 crafted a comprehensive strategy designed to infuse every facet of the event with the opulence synonymous with the Etihad brand. The approach encompassed meticulous planning and execution, from venue selection and gourmet food choices to engaging top-tier event suppliers and providing exceptional client servicing. The choice of Cipriani Wall Street, with its exquisite architecture, marble columns, and sophisticated decor, set the stage for a premium event. The venue was transformed into a visually stunning and captivating environment through an immersive digital art experience.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Custom culinary menus were curated to cater to Etihad's distinct preferences, enhancing the overall experience for high-profile attendees. Event suppliers specializing in stages and furniture were handpicked to meet Etihad's rigorous standards.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Tagline 360 worked closely with Etihad to ensure that their vision and requirements were at the forefront of the event planning process. Despite the time difference between New York City and Abu Dhabi, regular updates facilitated seamless collaboration, ensuring that the event would align perfectly with Etihad's brand identity.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The launch of the new Airbus A350 carrier was an unequivocal success, attracting over 400 attendees. Tagline 360 achieved the remarkable feat of encapsulating and emulating the luxurious standards of the Etihad brand. Every element, from the breathtaking decor and lighting to the impeccable furnishings and personalized service, reflected the opulent brand experience that Etihad is celebrated for. The event not only met but surpassed all expectations, leaving a profound and indelible impression on all those in attendance.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                Etihad Airways partnered with Tagline 360 to meticulously plan and execute the launch of their new Airbus A350 carrier at the esteemed Cipriani Wall Street venue in New York City. By harmonizing every facet of the event with Etihad's reputation for luxury, from the choice of venue and culinary offerings to the engagement of top-tier event suppliers and the execution of flawless service, Tagline 360 delivered an exceptional event experience that impeccably embodied the essence of the Etihad brand.
            </div>
        `,
        type: "carousel",
        src: 9,
        gallerySrc: ["/img/projects/main/banners/etihad/gallery/"],
    },
    {
        key: "gawad-alab-ng-lahi",
        banner: [],
        // banner: "/img/projects/main/banners/gawad-alab-ng-lahi.png",
        headline: "Gawad Alab ng Lahi Awards Ceremony",
        subheadline: "Celebrating Filipino Excellence and Contribution",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                The Hiraya Foundation for Filipino-American Heritage Preservation, Inc. is a non-profit organization with a noble mission: to preserve and promote Filipino cultural heritage, traditions, history, language, and crafts within both the United States and the Philippines. In their relentless pursuit of recognizing distinguished Filipino-Americans and foreign individuals dedicated to Filipino growth, excellence, and welfare, they partnered with Tagline 360, an event agency renowned for creating unforgettable event experiences. Together, they embarked on a groundbreaking journey to organize the first-ever Gawad Alab ng Lahi Awards Ceremony within the Filipino American community.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                The challenge was twofold: firstly, to conceptualize and execute an awards ceremony that would honor and celebrate the significant contributions of remarkable individuals to the Filipino community; and secondly, to create an event that would be etched in the memories of both awardees and attendees, leaving a profound impact.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360's mission was clear: to create a memorable event for the inaugural Gawad Alab ng Lahi Awards Ceremony. The objective was to provide a platform for recognizing Filipino individuals whose dedication had left an indelible mark on the Filipino community. Tagline 360 was committed to crafting an experience that would resonate long after the event concluded.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Venue Selection:</span>
                <br />
                The Sininglahi: Baile Filipiniana project, executed flawlessly by Tagline 360, garnered immense success. It attracted 400 attendees, including distinguished guests such as Philippine ambassadors and entrepreneurs. Beyond celebrating the Philippines' dance artistry and culture, the event achieved the noble goal of accumulating $15,000 in funds to support the Hiraya Foundation's mission of preserving Filipino heritage. This project marked a significant milestone within the Filipino American community, solidifying the foundation's commitment to promoting and preserving the rich heritage of the Philippines in the United States.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Program Flow:</span>
                <br />
                Tagline 360 assumed the responsibility of conceptualizing the program flow for the awarding ceremony. Their vision revolved around shining a spotlight on the awardees, enabling each honoree to share their inspiring stories and experiences. This approach allowed attendees to gain a deeper appreciation of the awardees' outstanding contributions, fostering increased awareness and admiration within the Filipino community. By showcasing the achievements of these remarkable individuals, Tagline 360 crafted a platform that celebrated their dedication and remarkable accomplishments.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                Through their unwavering dedication and meticulous planning, Tagline 360 played a pivotal role in recognizing and celebrating the achievements of exceptional individuals who have left an indelible mark on society. The Gawad Alab ng Lahi Awards Ceremony became a stage where their remarkable contributions were acknowledged, and their stories shared, inspiring all those who attended. Tagline 360 succeeded in raising awareness of the significant role these outstanding individuals play within the Filipino community, further cementing their legacy of excellence and dedication. This event stood as a testament to the power of recognizing and celebrating Filipino excellence and contribution, emphasizing the importance of preserving and promoting cultural heritage within the Filipino American community.
            </div>
        `,
        type: "carousel",
        src: 12,
        gallerySrc: ["/img/projects/main/banners/gawad-alab-ng-lahi/gallery/"],
    },
    {
        key: "hiraya-foundation",
        banner: "/img/projects/main/banners/hiraya-foundation.png",
        headline: "Hiraya Foundation",
        subheadline: "Sininglahi Baile Filipiniana: A Celebration of Filipino Dance and Artistry",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                The Hiraya Foundation for Filipino-American Heritage Preservation, Inc. is a non-profit organization with a noble mission: to preserve and promote Filipino cultural heritage, history, language, and crafts within both the United States and the Philippines. In collaboration with Tagline 360, an event agency renowned for creating unforgettable event experiences, the foundation embarked on an ambitious venture to organize an event in New York City. Their objective was not only to showcase the dance artistry and culture of the Philippines but also to ignite a deeper appreciation and understanding of Filipino traditions, history, language, and crafts among the Filipino-American community in NYC. This remarkable event project was named "Sininglahi Baile Filipiniana."
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                The challenge at hand was multifaceted: to conceptualize and execute an event that would encapsulate the rich heritage of the Philippines, transport attendees to the Spanish-colonized era, and raise funds for the Hiraya Foundation's noble cause. Additionally, it was vital to ensure that the event's theme and atmosphere remained true to the cultural essence of the Philippines.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360, known for its expertise in crafting engaging and immersive event experiences, conceptualized an event that celebrated Filipino culture and heritage through vibrant artistry and cultural dance and music under the banner of "Sininglahi Baile Filipiniana." Inspired by the Spanish-colonized Philippines, the event theme embraced the old era and culture of the country. This theme was seamlessly woven into every element of the event, including the event theme, set-up, and program flow. The Plaza Hotel, with its architectural resonance with the medieval period in the Philippines, was chosen as the perfect venue to bring this theme to life. Tagline 360 leveraged its extensive network of suppliers to source high-quality event elements, from staging to audio-visual equipment, ensuring an exceptional experience for attendees. Talent management, including collaboration with the Philippine Ballet Theatre and securing Cher Calvin as the event's host, added an extra layer of excellence to the project.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Digital Requirements:</span>
                <br />
                The Sininglahi: Baile Filipiniana project, executed flawlessly by Tagline 360, garnered immense success. It attracted 400 attendees, including distinguished guests such as Philippine ambassadors and entrepreneurs. Beyond celebrating the Philippines' dance artistry and culture, the event achieved the noble goal of accumulating $15,000 in funds to support the Hiraya Foundation's mission of preserving Filipino heritage. This project marked a significant milestone within the Filipino American community, solidifying the foundation's commitment to promoting and preserving the rich heritage of the Philippines in the United States.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The Sininglahi: Baile Filipiniana project, executed flawlessly by Tagline 360, garnered immense success. It attracted 400 attendees, including distinguished guests such as Philippine ambassadors and entrepreneurs. Beyond celebrating the Philippines' dance artistry and culture, the event achieved the noble goal of accumulating $15,000 in funds to support the Hiraya Foundation's mission of preserving Filipino heritage. This project marked a significant milestone within the Filipino American community, solidifying the foundation's commitment to promoting and preserving the rich heritage of the Philippines in the United States.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                The Sininglahi: Baile Filipiniana event project stands as a testament to the power of collaboration, cultural preservation, and meticulous event planning. Tagline 360's unwavering commitment to encapsulating Filipino heritage in an immersive event experience resulted in an unforgettable evening that not only celebrated the rich culture and dance artistry of the Philippines but also successfully raised funds for the Hiraya Foundation's cause. This project serves as an inspiration for the Filipino-American community and highlights the importance of preserving cultural heritage in the heart of New York City.
            </div>
        `,
        type: "carousel",
        src: 9,
        gallerySrc: ["/img/projects/main/banners/hiraya-foundation/gallery/"],

    },
    {
        key: "frow",
        banner: "/img/projects/main/banners/frow.png",
        headline: "F/ROW FASHION WEEK",
        subheadline: "REVOLUTIONIZING FASHION THROUGH INNOVATION",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                FROW FASHION WEEK has emerged as a trailblazing platform, committed to propelling innovative designers and artists to the forefront of the fashion landscape. This visionary endeavor was birthed through the collaboration of Errol Isip, a seasoned fashion event specialist, and Jovito Rabelas, the Global President of Tagline 360. Their joint mission was to ignite a fashion revolution by providing a prominent stage for visionary designers and artists to shine.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                Tagline 360 encountered a series of multifaceted challenges, each holding the key to the success of FROW FASHION WEEK:
                <ol class="my-5 ml-5">
                    <li>1. Conceptualization to Execution: Tagline 360 assumed the monumental task of conceptualizing and meticulously executing every aspect of the event. This encompassed crafting a distinctive event identity, overseeing intricate organizational logistics, and ensuring the flawless realization of FROW FASHION WEEK.</li>
                    <li>2. Digital Marketing: The agency was tasked with kindling anticipation and awareness surrounding FROW FASHION WEEK while simultaneously thrusting the spotlight onto seven groundbreaking fashion designers. To this end, an organic social media campaign was devised, harnessing the immense reach and influence of various online platforms.</li>
                    <li>3. Talent Management: Tagline 360 undertook the critical role of scouting models who could breathe life into the designers' creative visions. This intricate casting process required a delicate balance to ensure that the chosen models harmoniously embodied the essence of the showcased fashion.</li>
                    <li>4. Public Relations: The agency was entrusted with curating a robust public relations campaign. This initiative was instrumental in elevating the profiles of the participating designers, increasing their visibility within the fashion realm.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                In response to these multifaceted challenges, Tagline 360 orchestrated a comprehensive strategy:
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Conceptualization to Execution: Tagline 360 meticulously crafted the event's distinctive identity and diligently oversaw the intricate organizational facets. The selection of the Pacific Design Center as the event's venue exemplified a commitment to sophistication and innovation, making it the perfect canvas for the fashion showcase.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Digital Marketing: Leveraging an organic social media marketing approach, Tagline 360 effectively kindled anticipation and enthusiasm for FROW FASHION WEEK. The campaign harnessed the expansive influence of social media platforms, generating buzz and interest around the event.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Talent Management: The agency undertook the intricate task of model scouting, ensuring that each model seamlessly embodied the creative vision of the participating designers. This casting process was executed with precision, resulting in models that perfectly complemented the showcased fashion.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Public Relations: A robust public relations campaign, masterminded by Tagline 360, skillfully directed the spotlight onto the participating designers. This initiative significantly enhanced their profiles within the fashion industry, amplifying their presence at the event.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The culmination of these collaborative efforts yielded resounding success:
                The Frow Fashion Week Event: This remarkable event witnessed the gathering of over 500 attendees who were captivated by the groundbreaking designs and artistic innovations on display. With six distinct shows featuring seven visionary designers, FROW FASHION WEEK solidified its position as an emblem of creative excellence in the fashion universe.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                The partnership between FROW FASHION WEEK and Tagline 360 stands as a testament to the transformation of visionary aspirations into triumphant realities. The event's seamless execution, the anticipation generated through digital marketing, the flawless talent management, and the diligent public relations campaign all underscore a shared commitment to ushering innovation and inclusivity into the fashion sphere. FROW FASHION WEEK is more than just an event; it signifies the remarkable achievements that materialize when creative ingenuity, visionary zeal, and meticulous execution converge, heralding an exciting new era in the world of fashion.
            </div>
        `,
        type: "",
    },
    {
        key: "magical-sunset",
        banner: "/img/projects/main/banners/magical-sunset.png",
        headline: "Magical Sunset",
        subheadline:
            "Creating Magic in Niche Markets: Tagline 360’s Marketing Consultancy for Magical Sunset",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                When Magical Sunset, a company known for crafting unique travel experiences, sought to identify new niche markets, they turned to Tagline 360. The goal was to create a bespoke product for a unique market with high spending power. Tagline 360 did not disappoint.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360, leveraging its expertise in niche marketing, identified the LGBTQ+ community as a potential market segment for Magical Sunset. Recognizing the community's increasing influence and spending power, they saw an opportunity for Maria Tsalkos, the founder and CEO of Magical Sunset, to create a unique product tailored to this audience.
                The team at Tagline 360 worked closely with Tsalkos to understand the specific needs and preferences of the LGBTQ+ community. They explored 'blue oceans' - untapped, uncontested market spaces ripe for growth, and helped Tsalkos design a bespoke Magical Sunset product that was both inclusive and appealing to the LGBTQ+ market.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                But Tagline 360's efforts didn't stop there. They also helped Magical Sunset design special webinars aimed at attracting travel agents. These webinars focused on Sustainable Travel and Authentic Travel experiences, key topics of interest in the industry, designed to assist travel agents in building their summer 2024 plans.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                These webinars served a dual purpose. Not only did they help attract travel agents to Magical Sunset's offerings, but they also positioned the company as a thought leader in the field of authentic, bespoke travel experiences. The webinars provided valuable insights and knowledge to travel agents looking to offer unique and personalized experiences to their clients.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Digital Requirements:</span>
                <br />

            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                As a result of Tagline 360's strategic consultancy, Magical Sunset successfully managed to tap into a new, high-potential market segment, enhancing its product offering and strengthening its position in the travel industry. Moreover, through the targeted webinars, Magical Sunset was able to establish itself as a leader in creating authentic travel experiences, further solidifying its reputation and brand value.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study is a testament to Tagline 360's ability to identify and capitalize on niche markets, create tailored marketing strategies, and enhance thought leadership, demonstrating their prowess in the field of marketing consultancy.
            </div>
        `,
        type: "",
    },
    {
        //PENDING WRITEUP
        key: "modelhunt-la",
        banner: "/img/projects/main/banners/modelhunt-la.png",
        headline: "MODELHUNT INTERNATIONAL LA",
        subheadline: "REVIVING DIVERSITY AND INCLUSIVITY",
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Modelhunt International is an organization committed to celebrating the rich tapestry of diversity and fostering inclusivity within the modeling industry. Their mission is to offer a stage where aspiring models of all backgrounds, ages, sizes, and appearances can have their talents acknowledged and cherished. Following their triumphant launch of Modelhunt International in New York City (NYC), the organization set its sights on rekindling the spirit of diversity and inclusivity in its birthplace - Los Angeles (LA).
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenge</span>
                <br />
                The challenge at hand was a two-fold endeavor. Firstly, the aim was to reintroduce Modelhunt International to its hometown of LA, showcasing a commitment to diversity and inclusivity within the modeling sphere. Secondly, Tagline 360 was entrusted with the task of organizing an exceptional event for Modelhunt International LA that would not only spotlight model diversity but also attract a wide range of models to partake in the competition. This dual challenge necessitated a comprehensive approach, blending event planning with social media and email marketing strategies.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 embarked on an integrated strategy that blended both the event management and digital marketing aspects. Recognizing the influence of digital platforms, they formulated a marketing campaign tailored to resonate with Modelhunt International's audience. Social media marketing initiatives underscored the benefits of participating in Modelhunt International LA, emphasizing exposure to modeling agencies, specialized training, professional photo sessions, and the potential to feature in magazines. Email marketing complemented these efforts, creating a multi-channel approach to engaging with aspiring models.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The event itself was conceptualized as a full-day fashion extravaganza, celebrating the diversity of models from various backgrounds. The event was segmented into three distinct shows, each dedicated to specific model categories such as petite, mature, edgy, mainstream, and more. This meticulous division provided a dedicated platform for every model type, amplifying the spirit of inclusivity and diversity within the fashion industry.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The Hotel Roosevelt in West Hollywood, LA, was the chosen venue for this spectacular event. Its iconic location set the stage for a memorable evening that attracted over 1500 model applicants, with more than 50 finalists selected to participate. Tagline 360 orchestrated a seamless transition between each segment of the fashion show, ensuring that the diversity and inclusivity message shone brightly throughout.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The event was nothing short of a triumph. Over 1500 models applied for the competition, and more than 50 finalists were selected, highlighting the overwhelming response and enthusiasm. The Hotel Roosevelt in West Hollywood, LA, provided the ideal backdrop, and the three distinct fashion shows showcased models of every kind, ranging from petite to mature, edgy, mainstream, and more.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                Through meticulous planning and execution, Tagline 360 successfully resurrected Modelhunt International in LA. The annual fashion show served as a tribute to diversity and inclusivity, exemplifying the institution's unwavering commitment to these values. Modelhunt International's success not only revitalized its presence but also paved the way for aspiring models to embrace their dreams while reinforcing the fashion industry's need for greater representation and inclusivity. This event marked a significant step towards a more diverse and inclusive future in the world of fashion.
            </div>
        `,
        type: "",
    },
    {
        key: "heroes-of-hearing",
        banner: "/img/projects/main/banners/heroes-of-hearing.png",
        headline: "Heroes of Hearing",
        subheadline: `Amplifying Life’s Experiences: Tagline 360's Innovative Approach for Heroes of Hearing`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Heroes of Hearing, a hearing wellness clinic based in Tucson, Arizona, was grappling with a challenge. People hesitated to seek help for hearing loss and invest in hearing aids, viewing it as a sign of weakness or limitation. They turned to Tagline 360 for a solution, and the agency delivered innovative and impactfully.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 created a powerful funnel big idea: "Upgrade Your Hearing. Upgrade Your Life." This concept hinged on reframing the way people viewed hearing aids. Instead of seeing them as a symbol of degradation, Tagline 360 positioned them as a piece of technology akin to an iPhone or an intelligent vacuum - an investment that enhances life, not a statement of limitation.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                This fresh perspective was communicated through a comprehensive marketing funnel. It started with engaging ads that challenged the stigma around hearing aids, presenting them as a means to upgrade one's life. These ads were designed to capture attention and challenge preconceived notions about hearing aids.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The agency also crafted compelling social media content that further reinforced this message. The content was designed to drive consumers to seek a technical consultation with Heroes of Hearing, helping them understand how the right technology could unlock a better life.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The result was a campaign that resonated deeply with the target audience. It effectively addressed their fears and hesitations, encouraging them to take the first step toward improving their hearing wellness. This led to an increase in consultations at Heroes of Hearing, demonstrating the campaign's success.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study underscores Tagline 360's ability to create transformative marketing strategies that address client challenges and shift consumer perspectives. Through their innovative approach, they turned a perceived limitation into a life-enhancing upgrade, contributing to the success of Heroes of Hearing.
            </div>
        `,
        type: "",
    },
    {
        key: "national-diversity-fairs",
        banner: "/img/projects/main/banners/national-diversity-fairs.png",
        headline: "National Diversity Fairs",
        subheadline: `Empowering Diversity: Tagline 360's Strategic Approach to Enhance National Diversity Fairs' Impact`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                National Diversity Fairs (NDF), an organization committed to aiding students from underrepresented communities in their journey to graduate school, needed a robust strategy to increase its outreach and impact. This is where Tagline 360 stepped in, offering their expertise in marketing and digital infrastructure to help NDF reach its objectives.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                The primary challenge was the organization of NDF's first STEM, Business, and Social Sciences Fair. The fair aimed to provide a platform for students to interact with university and college admission officials directly and gain insights into the admission processes and strategies. Tagline 360 was tasked with setting up the student sign-up infrastructure and driving student participation through strategic advertising.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Firstly, Tagline 360 set up an efficient and user-friendly sign-up portal for students. This portal was designed to be intuitive and easy-to-navigate, ensuring a smooth registration process for students interested in attending the fair.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                To drive student registrations, Tagline 360 developed a multi-faceted marketing strategy. They set up paid ads targeted at potential participants, highlighting the unique opportunity that the fair presented. These ads were strategically placed to reach the maximum number of students from underrepresented communities.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                In addition to paid ads, Tagline 360 also leveraged the power of social media to inform students about the fair. They crafted highly engaging social media posts and ads that not only promoted the event but also provided valuable insights into the processes and strategies to get into graduate school. This dual-purpose approach served to attract students while also establishing NDF as a valuable resource for grad school aspirants.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The result of this well-orchestrated strategy was impressive. National Diversity Fairs saw over 200 participants in the year’s NDF fairs, all driven through the strategic use of ads and content created by Tagline 360. This significant increase in participation not only helped NDF achieve its aim of aiding students from underrepresented communities but also demonstrated the power of strategic advertising in driving engagement and participation.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study is a testament to Tagline 360's ability to create and execute effective marketing strategies tailored to their client's needs. Their innovative approach and understanding of digital infrastructure played a crucial role in the success of National Diversity Fairs' inaugural STEM, Business and Social Sciences Fair.
            </div>
        `,
        type: "",
    },
    {
        key: "kloopify",
        banner: "/img/projects/main/banners/kloopify.png",
        headline: "Kloopify",
        subheadline: `Crafting a Green Narrative: Tagline 360's Social Media Strategy for Kloopify`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction and Challenge</span>
                <br />
                In the era of conscious capitalism, businesses are increasingly seeking ways to make their operations more sustainable. Kloopify, a B2B data and insighting tool, is designed to help procurement officers achieve this goal. When founders Daniela and Ignacio Osio sought to establish themselves as thought leaders in sustainable procurement, they turned to Tagline 360.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360's creative team devised a unique concept: "Procurement Greenfluencers." This approach aimed to position Daniela and Ignacio as advocates for the planet and as champions of profitability through sustainable and carbon-neutral supply chains.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Understanding that business benefits primarily drive procurement officers, Tagline 360 designed the messaging around these touchpoints. The agency developed a content strategy that balanced the emphasis on sustainability, clearly articulating its impact on profitability.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The social media strategy comprised of a blend of educational carousels thought leadership, LinkedIn articles, and blog posts. Each piece of content was meticulously crafted to pique curiosity, establish leadership, and encourage procurement officials to book an appointment with Kloopify.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Tagline 360 ensured that each post was informative and engaging, fostering a sense of community among followers and prompting meaningful discussions around sustainable procurement.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                This strategic approach increased visibility for Kloopify, positioning Daniela and Ignacio as thought leaders in their field. It also led to a rise in engagement rates and appointment bookings, demonstrating the effectiveness of a well-crafted social media strategy.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study underscores Tagline 360's ability to harness the power of social media to create compelling narratives that resonate with target audiences. By aligning the brand's values with its communication strategy, Tagline 360 helped Kloopify carve out a distinctive space in the realm of sustainable procurement.
            </div>
        `,
        type: "",
    },
    {
        key: "rizome",
        banner: "/img/projects/main/banners/rizome.png",
        headline: "Rizome Bamboo",
        subheadline: `Building the Future with Bamboo: Tagline 360's Strategic Campaign for Rizome`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Russell Smith, CEO and founder of Rizome Bamboo, had a clear vision: to position bamboo not as an alternative, but as the primary choice for construction material. He approached Tagline 360 to help establish Rizome as 'the miracle timber' and spark interest among builders in this sustainable and versatile resource.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                One unique challenge faced by the agency was Rizome's presence both in the US and the Philippines. This necessitated a tailored approach for each market, with specific content designed to resonate with the distinct audiences in both countries.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 undertook the challenge and designed an innovative campaign titled "Rizome of Miracles". The campaign revolved around highlighting the economic, functional, environmental, and community benefits of bamboo, showcasing it as a miraculous solution for modern construction needs.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The agency developed a series of thought leadership pieces that positioned Rizome as the miracle timber. These pieces were disseminated through various social media posts, articulating the diverse advantages of using bamboo in construction, from its rapid growth and strength to its environmental benefits.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                To further amplify Rizome's message and establish Russell as a leader in the field of alternative construction materials, Tagline 360 also crafted LinkedIn articles. These articles were designed to generate interest and foster collaboration between Russell and key players in the construction industry.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                By focusing on the "Rizome of Miracles" concept, Tagline 360 built a comprehensive campaign that highlighted the multiple facets of bamboo's miracles. This strategic approach not only helped establish Rizome as a front-runner in the construction industry, but it also positioned Russell Smith as a thought leader in the realm of sustainable building materials.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                This case study serves as a testament to Tagline 360's ability to craft strategic narratives that not only resonate with diverse audiences but also effectively position a brand and its leader at the forefront of their industry.
            </div>
        `,
        type: "",
    },
    {
        key: "the-joint-social",
        banner: "/img/projects/main/banners/the-joint-social.png",
        headline: "The Joint",
        subheadline: `Lighting Up Organic Engagement: Tagline 360's Social Media Campaign for The Joint Cannabis Dispensary`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                In the heart of Salamanca, New York, lies The Joint, a unique cannabis dispensary owned by members of the Seneca tribe. With the challenge to drive awareness and footfall without resorting to traditional advertising due to regulatory constraints, they turned to Tagline 360.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                Tagline 360 took on the task with a clear strategy: create organic social media posts that were as unique, engaging, and captivating as The Joint itself.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Using humor as their secret weapon, the agency crafted witty, tongue-in-cheek content that roused curiosity about the dispensary and its products among the local audience. This clever approach resulted in a significant increase in store visits and brand recognition.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The agency's creativity shone even brighter as they geared up for 420, the biggest cannabis holiday. Tagline 360 developed unique social media promotions that built excitement around the event, resulting in The Joint recording its most successful 420 holiday sales in years.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                Despite the restrictions on advertising, Tagline 360 was able to leverage the power of organic social media posts to promote The Joint effectively. The success of this campaign is a testament to the agency's ability to think outside the box and use humor and wit to connect with audiences and drive engagement.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Conclusions</span>
                <br />
                This case study demonstrates how Tagline 360's strategic and creative approach can overcome regulatory challenges and successfully drive awareness and footfall for a unique business like The Joint Cannabis Dispensary.
            </div>
        `,
        type: "",
    },
    {
        key: "ingrid-denter",
        banner: "/img/projects/main/banners/ingrid-denter.png",
        headline: "Ingrid Dinter",
        subheadline: `Creating a Fluid Brand Identity: Tagline 360's Innovative Approach for Ingrid Dinter Institute`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction and Challenge</span>
                <br />
                Ingrid Dinter, an expert in Emotional Freedom Techniques (EFT) Tapping, approached Tagline 360 with a vision to create a unique brand identity that would position her as a thought leader in the field. Understanding the client's goals and the need for a distinctive brand, the agency took on the challenge and created the Ingrid Dinter Institute.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 began the branding process by designing a new logo that encapsulated the essence of EFT tapping. The logo combined elements of flowing water with the rhythmic action of EFT tapping - represented by a stylized water droplet. This innovative design symbolized the pulses of tapping and the regenerative and healing power of water, perfectly capturing the transformative nature of EFT tapping.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                But the agency didn't stop at the logo. Recognizing the importance of a consistent and cohesive brand image, they extended this water design theme across the entire brand. From the website design to marketing materials, the fluidity of water became a central element of the Ingrid Dinter Institute's visual identity.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The agency's creativity shone even brighter as they geared up for 420, the biggest cannabis holiday. Tagline 360 developed unique social media promotions that built excitement around the event, resulting in The Joint recording its most successful 420 holiday sales in years.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Result</span>
                <br />
                This unified and distinctive branding not only set the Ingrid Dinter Institute apart from competitors but it also clarified the brand's value proposition. The clear representation of the EFT tapping process in the logo and overall design made it easy for potential clients to understand what the institute offers, leading to increased brand recognition and conversions.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Conclusions</span>
                <br />
                This case study showcases Tagline 360's ability to translate a client's vision into a creative and effective brand identity. Their innovative approach to the Ingrid Dinter Institute's branding demonstrates their expertise in creating visual identities that not only stand out but also clearly communicate the brand's value.
            </div>
        `,
        type: "",
    },
    {
        key: "fara-branding",
        banner: "/img/projects/main/banners/fara-branding.png",
        headline: "Filipino Americans for Racial Action (FARA)",
        subheadline: `Designing for Advocacy: Tagline 360's Branding Strategy for Filipino Americans for Racial Action (FARA)`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction and Challenge</span>
                <br />
                Filipino Americans for Racial Action (FARA) is an organization committed to combating racial inequalities and fostering a community of advocates for fair resource allocation. When they needed a brand identity that could encapsulate their mission and inspire action, they turned to Tagline 360.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Understanding the gravity of FARA's cause, Tagline 360 set out to design a logo that could effectively represent their fight against racial inequality. The result was a powerful symbol of arms locked together in unity, a visual testament to the strength of collective action.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                But the agency's work didn't stop at the logo. They leveraged this strong visual identity to create a dynamic website that told FARA's story in an engaging and compelling way. The website not only provided information about the organization and its mission but also served as a platform for visitors to get involved by volunteering or sharing their own experiences with racial inequality.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                This user-centric approach ensured that the website was not just an informational hub but a catalyst for action, encouraging visitors to contribute to FARA's mission in meaningful ways.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Result</span>
                <br />
                The branding strategy developed by Tagline 360 for FARA is a testament to their ability to understand their clients' needs and translate them into impactful visual identities and digital experiences. By creating a cohesive brand identity that resonates with FARA's mission, Tagline 360 helped the organization reach a wider audience, inspire action, and continue their fight against racial inequality.
            </div>
        `,
        type: "",
    },
    {
        key: "hiraya-branding",
        banner: "/img/projects/main/banners/hiraya-branding.png",
        headline: "Hiraya",
        subheadline: `Preserving Heritage through Design: Tagline 360's Branding Strategy for Hiraya Foundation`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction and Challenge</span>
                <br />
                The Hiraya Foundation is a nonprofit organization dedicated to preserving Filipino-American cultural heritage. To support their mission, they needed a strong brand identity and an online platform. Tagline 360 was chosen for the task.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Understanding the importance of Hiraya's mission, Tagline 360 began by designing a powerful logo that encapsulated the essence of Filipino culture and art. The logo, depicting a sun on a boat riding over waves, became an emblem of the journey and resilience of the Filipino community.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                For the branding materials, the agency decided to incorporate patterns from unique Filipino textiles. These textiles are endangered due to the decline of artisans who create them, making them a poignant symbol of the cultural heritage Hiraya aims to preserve. By using these patterns, Tagline 360 effectively highlighted the organization's mission and created a visual link between the foundation and the culture it seeks to protect.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                With this strong visual framework in place, the agency designed Hiraya's website. The site serves as a comprehensive portal of information and a platform for engagement, offering news about upcoming activities and opportunities for Filipino Americans to participate and contribute. Reflecting the organization's elegance and commitment, the website became an essential tool in advancing Hiraya's mission.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Result</span>
                <br />
                Tagline 360's work with Hiraya Foundation demonstrates their expertise in creating meaningful brand identities and digital platforms. By harnessing elements of Filipino culture and art in their designs, they effectively communicated Hiraya's mission and helped the organization connect with their target audience. This case study exemplifies how strategic design can be used to amplify a brand's message and drive engagement.
            </div>
        `,
        type: "",
    },
    {
        key: "gawad-branding",
        banner: "/img/projects/main/banners/gawad-branding.png",
        headline: "Gawad Alab ng Lahi",
        subheadline: `Illuminating Excellence: Tagline 360's Branding Strategy for the Gawad Alab ng Lahi Awards`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction and Challenge</span>
                <br />
                The Gawad Alab ng Lahi Awards is a prestigious recognition program celebrating the exceptional contributions of Filipinos in the United States. Tasked with creating the brand and website for this esteemed initiative, Tagline 360 took on the challenge with enthusiasm and creativity.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                The agency started by designing a powerful logo that perfectly encapsulated the spirit of the awards. Combining elements of the Philippine flag with a flame, which is what 'Alab' means, they crafted a symbol that was both visually striking and deeply meaningful.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Building on this strong visual foundation, Tagline 360 turned their attention to the website. In collaboration with the Philippine Consulate in New York and the Hiraya Foundation, they designed a site that reflected the values of the awards program and the rich cultural heritage of the Filipino community.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                One of the key design elements was the use of Baybayin, a pre-colonial writing system used by early Filipinos. This nod to the past was seamlessly integrated with a modern design aesthetic, creating a fusion of classical Filipino creativity and contemporary transformations.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Result</span>
                <br />
                The resulting website was not only visually stunning but also a poignant reflection of the incredible contributions of Filipinos to today's world.
                Tagline 360's work on the Gawad Alab ng Lahi Awards is a testament to their ability to create meaningful and engaging brand identities and digital experiences. By skillfully blending elements of Filipino history and culture with modern design principles, they were able to create a brand and website that truly honored the exceptional contributions of Filipinos in the United States.
            </div>
        `,
        type: "",
    },
    {
        key: "rizome-branding",
        banner: "/img/projects/main/banners/rizome-branding.png",
        headline: "Rizome Bamboo",
        subheadline: `Reimagining Bamboo: Tagline 360's Branding Strategy for Rizome`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Introduction</span>
                <br />
                Rizome Bamboo, a forward-thinking company providing sustainable construction material, approached Tagline 360 with a unique challenge: to transform the perception of bamboo from being seen as a cheap alternative to a high-quality, elegant, and strong building material.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 accepted the challenge and embarked on a mission to create a leadership brand around Rizome that would establish it as the 'miracle timber' for builders, architects, and designers. The agency decided to use the rich mythology of Rizome bamboo as the foundation for this transformation.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The agency also created a unique iconography that symbolized the three core strengths of bamboo - its extraordinary strength, beauty, and sustainability. This iconography was integrated into all marketing materials, serving as a constant reminder of bamboo's remarkable qualities.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                However, changing the perception of bamboo was not just about highlighting its strengths. It was equally important to present it as a refined, high-class product. To achieve this, Tagline 360 relied on subtle imagery and elegant designs. The sophisticated visual language served to elevate the brand, positioning Rizome bamboo as a premium choice, not a cheap alternative.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Result</span>
                <br />
                This strategic branding effort was a significant shift in how Rizome and its bamboo were perceived. No longer seen as merely an alternative, Rizome bamboo was recognized for its extraordinary characteristics, and the brand was firmly established as a leader in the field of sustainable construction materials.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Conclusions</span>
                <br />
                This case study exemplifies how Tagline 360's creative and strategic approach can successfully transform a brand's image and position it as a leader in its industry. By leveraging the rich mythology of Rizome bamboo and integrating it into elegant designs, the agency was able to effectively communicate the value of this 'miracle timber'.
            </div>
        `,
        type: "",
    },
    {
        key: "the-joint-platform",
        banner: "/img/projects/main/banners/the-joint-platform.png",
        headline: "THE JOINT",
        subheadline: `RESONATING WITH VALUES TO GROW THE JOINT DISPENSARY`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                The Joint Dispensary, a cannabis dispensary operated by members of the Seneca Community in Salamanca, New York, was struggling in a highly competitive market. With numerous dispensaries in the area, differentiation became a significant issue.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                The Joint Dispensary was in a saturated market with little to no differentiation among competitors. They needed to define their brand and establish a unique selling proposition to stand out and attract customers.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 implemented a multi-faceted strategy to tackle The Joint Dispensary's challenges:
                <ol class="my-5 ml-5">
                    <li>1. <b>Brand Definition</b>: We started by defining what The Joint Dispensary stands for, connecting the brand to traditional Senecan values. We communicated these through the value proposition of a 5-star Dispensary, where all products are community-reviewed, and only 5-star products are carried.</li>
                    <li>2. <b>Website Creation</b>: Recognizing the importance of a solid online presence, we swiftly created a website communicating The Joint Dispensary's values and product offerings. The user-friendly design and easy navigation enhanced the overall customer experience.</li>
                    <li>3. <b>Social Media Strategy</b>: To drive foot traffic to the dispensary, we crafted a social media strategy not focusing on products but focusing instead on community building through weekly events and offers targeted toward adults. This strategy increased brand visibility and fostered a sense of community around the dispensary.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                Implementing our strategies led to a consistent increase in foot traffic at The Joint Dispensary. The brand saw its highest sales ever during its 4/20 day, demonstrating the effectiveness of our approach in driving customer engagement and boosting sales.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study illustrates Tagline 360's expertise in applying the brand religion methodology to define a brand's identity, differentiate it from competitors, and drive customer engagement. Our strategic approach helped The Joint Dispensary overcome its challenges and achieve record-breaking sales.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                By partnering with Tagline 360, The Joint Dispensary established a unique brand identity rooted in traditional Senecan values, created a solid online presence, and implemented a successful social media strategy that increased foot traffic and boosted sales.
            </div>
        `,
        type: "",
    },
    {
        key: "magical-sunset-platform",
        banner: "/img/projects/main/banners/magical-sunset-platform.png",
        headline: "MAGICAL SUNSET",
        subheadline: `REVENGE TRAVEL: A STRATEGY FOR MAGICAL SUNSET`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Magical Sunset, a travel company specializing in bespoke luxury catamaran tours in Greece, had a unique product and a goal to shift from the B2C to the B2B market with a focus on travel agencies. The challenge was to generate increased inquiries for their product and establish partnerships with travel agencies..
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                Magical Sunset had developed a fantastic product that provided travelers with a unique, non-touristy experience of Greece through engaging Greek Mythology and Storytelling. However, they needed help transitioning from a B2C to a B2B market and a powerful marketing strategy to attract travel agencies to their product.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 developed a comprehensive strategy to address Magical Sunset's challenges:
                <ol class="my-5 ml-5">
                    <li>1. <b>Email Marketing Campaign</b>: We created a powerful email marketing campaign designed to highlight the unique features of Magical Sunset's tours. Our emails targeted traditional travel agencies, proposing that Magical Sunset's tours should be an essential part of their product line-up.</li>
                    <li>2. <b>Focus on Revenge Travel</b>: Our communication strategy focused on the growth of revenge travel — the trend of people traveling more extensively after being restricted due to the pandemic. This approach resonated with travel agencies looking to offer their customers unique experiences post-pandemic.</li>
                    <li>3. <b>Tactical Effort for LGBT Travel Agencies</b>: In collaboration with Magical Sunset, we developed a unique product exclusively designed for the LGBT community. We tested a tactical effort to target LGBT travel agencies, positioning Magical Sunset's tour as an inclusive and appealing option for their customers.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The strategies implemented by Tagline 360 increased brand partnerships with travel agencies, preparing Magical Sunset for a successful summer season in 2024. The email marketing campaign, the focus on revenge travel, and the targeted effort towards LGBT travel agencies effectively boosted inquiries for Magical Sunset's product. They solidified their transition to a B2B market.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study demonstrates Tagline 360's expertise in creating communication, branding, and marketing strategies that deliver results. By understanding the client's needs and developing a tailored strategy, we positioned Magical Sunset as a desirable partner for travel agencies, resulting in increased brand partnerships and setting the stage for a successful season.
            </div>
        `,
        type: "",
    },
    {
        key: "rosetta-languages-platform",
        banner: "/img/projects/main/banners/rosetta-languages.png",
        headline: "ROSETTA LANGUAGES",
        subheadline: `INTERPRETING A VISION FOR SUCCESS: ROSETTA LANGUAGES`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Rosetta Languages, an interpretation and translation service catering to local government organizations and various language communities, desired to expand its footprint and communicate its identity and services. Tagline 360 was brought on board to harness its branding and website creation expertise to achieve these goals.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Challenge</span>
                <br />
                Rosetta Languages needed to effectively communicate who they were, explain their services to potential customers, and increase their market presence. They required a powerful brand mythology and a website to guide potential customers through a systematic path to purchase.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Solutions</span>
                <br />
                Tagline 360 implemented a multi-pronged strategy:
                <ol class="my-5 ml-5">
                    <li>1. <b>Brand Mythology</b>: We began by discovering the inspiring story of Rosetta Language's founder and his love for languages. This powerful narrative was woven into the brand's mythology, forming the foundation of our branding efforts.</li>
                    <li>2. <b>Website Design</b>: We designed a user-friendly website that systematically guided potential customers from identifying their problems to finding solutions offered by Rosetta Languages. The website was also developed to establish Rosetta Languages as experts in their field.</li>
                    <li>3. <b>Relationship Building</b>: To foster trust and build relationships, we prominently featured the faces of Rosetta Languages' translators and interpreters on the website. This human touch added authenticity and relatability to the brand.</li>
                    <li>4. <b>Visual Representation of Company Pillars</b>: We created compelling imagery to represent the four pillars of Rosetta Languages in an easy-to-understand manner, making the company's values and mission clear to potential customers.</li>
                </ol>
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">The Results</span>
                <br />
                The result was a new, easy-to-navigate website primed to convert potential customers. The brand mythology resonated with visitors, while the systematic path-to-purchase, relationship-building features, and clear visual representation of the company's pillars made the website an effective tool for attracting and retaining customers.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                This case study showcases Tagline 360's expertise in branding and creating powerful, creative websites. By understanding Rosetta Languages' needs and aligning our strategies accordingly, we created a strong brand identity and an effective website that increased their market footprint.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                Our work with Rosetta Languages underlines our commitment to delivering results for our clients, further establishing Tagline 360 as a trusted partner in the industry.
            </div>
        `,
        type: "",
    },
    {
        key: "modelhunt-international-event",
        // banner: "/img/projects/main/banners/modelhunt-international.png",
        banner: [],
        headline: "Modelhunt International",
        subheadline: `Modelhunt International NYC - A Journey Towards Diversity and Inclusivity`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Modelhunt International is a modeling search dedicated to celebrating diversity and inclusivity. Their mission is to provide a platform for aspiring models of all ages, sizes, and looks to be discovered and appreciated. With a desire to expand to New York, one of the world's top fashion capitals, Modelhunt International aimed to organize an annual fashion show event featuring a diverse range of models. They partnered with Tagline 360, an experienced event planning and digital marketing company, to ensure a successful launch of their institution in New York.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenge</span>
                <br />
                The challenge at hand was multifaceted: to establish Modelhunt International's presence in the competitive New York fashion market while advocating for diversity and inclusivity in the fashion industry. They needed to launch an annual fashion show that not only celebrated diversity but also conveyed a powerful message about representation. Tagline 360 was tasked with organizing the event, executing digital marketing strategies, and attracting participants who shared their commitment to inclusivity.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Solutions</span>
                <br />
                Tagline 360 devised a strategy that engaged Modelhunt International's existing Instagram followers and newsletter subscribers. Through a digital awareness campaign, they highlighted the benefits of participating in Modelhunt International NYC, including exposure to modeling agencies, specialized training, professional photo shoots, and the chance to be featured in magazines. Social media and email marketing generated excitement, drawing over 1700 applicants.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The event concept centered on a full-day fashion show celebrating models from diverse backgrounds. It was divided into three segments, each featuring specific categories such as Plus Size and Edgy models, Petite and Mature models, and Mainstream models. This approach provided a platform for all types of models to shine, emphasizing diversity and uniqueness. The event successfully promoted inclusivity in the fashion industry.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The Metropolitan Pavilion in Manhattan was chosen as the venue due to its central location and spaciousness. It attracted attendees from all over New York, contributing to the event's success. Tagline 360 partnered with The Make-up Academy to ensure models looked their best on the runway, enhancing the overall fashion presentation. Strong relationships with event suppliers allowed Tagline 360 to secure high-quality elements for the event while adhering to budget constraints.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Results</span>
                <br />
                The fashion show event drew 300 attendees across the three segments, serving as a testament to changing industry standards that emphasize inclusivity and diversity. Modelhunt International gained recognition in New York, with models featured in New York Fashion Week shows and signed by leading agencies. The institution's mission inspired aspiring models to break barriers and pursue their dreams.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                Through strategic planning, impeccable execution, and a dedication to diversity and inclusivity, Tagline 360 successfully launched Modelhunt International in New York. The annual fashion show showcased a diverse array of models, reinforcing the institution's commitment to inclusivity. Modelhunt International's success underscored the importance of diversity within the fashion industry, opening doors for aspiring models and paving the way for greater representation in the world of fashion.
            </div>
        `,
        type: "carousel",
        src: 6,
        gallerySrc: ["/img/projects/main/banners/modelhunt-international-event/gallery/"],
    },
    {
        key: "frow-event",
        banner: [],
        // banner: "/img/projects/main/banners/frow-event.png",
        headline: "F/ROW LA S/S 2024",
        subheadline: `F/ROW Fashion Week - Revolutionizing Fashion Through Innovation`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                F/ROW FASHION WEEK has emerged as a trailblazing platform, committed to propelling innovative designers and artists to the forefront of the fashion landscape. This visionary endeavor was birthed through the collaboration of Errol Isip, a seasoned fashion event specialist, and Jovito Rabelas, the Global President of Tagline 360. Their joint mission was to ignite a fashion revolution by providing a prominent stage for visionary designers and artists to shine.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenges</span>
                <br />
                Tagline 360 encountered a series of multifaceted challenges, each holding the key to the success of F/ROW FASHION WEEK:
                <ol class="my-5 ml-5">
                    <li>1. <b>Conceptualization to Execution</b>: Tagline 360 assumed the monumental task of conceptualizing and meticulously executing every aspect of the event. This encompassed crafting a distinctive event identity, overseeing intricate organizational logistics, and ensuring the flawless realization of F/ROW FASHION WEEK.</li>
                    <li>2. <b>Digital Marketing</b>: The agency was tasked with kindling anticipation and awareness surrounding F/ROW FASHION WEEK while simultaneously thrusting the spotlight onto seven groundbreaking fashion designers. To this end, an organic social media campaign was devised, harnessing the immense reach and influence of various online platforms.</li>
                    <li>3. <b>Talent Management</b>: Tagline 360 undertook the critical role of scouting models who could breathe life into the designers' creative visions. This intricate casting process required a delicate balance to ensure that the chosen models harmoniously embodied the essence of the showcased fashion.</li>
                    <li>4. <b>Public Relations</b>: The agency was entrusted with curating a robust public relations campaign. This initiative was instrumental in elevating the profiles of the participating designers, increasing their visibility within the fashion realm.</li>
                </ol>

            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Solution</span>
                <br />
                In response to these multifaceted challenges, Tagline 360 orchestrated a comprehensive strategy:
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <b>Conceptualization to Execution</b>: Tagline 360 meticulously crafted the event's distinctive identity and diligently oversaw the intricate organizational facets. The selection of the Pacific Design Center as the event's venue exemplified a commitment to sophistication and innovation, making it the perfect canvas for the fashion showcase.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <b>Digital Marketing</b>: Leveraging an organic social media marketing approach, Tagline 360 effectively kindled anticipation and enthusiasm for F/ROW FASHION WEEK. The campaign harnessed the expansive influence of social media platforms, generating buzz and interest around the event.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <b>Talent Management</b>: The agency undertook the intricate task of model scouting, ensuring that each model seamlessly embodied the creative vision of the participating designers. This casting process was executed with precision, resulting in models that perfectly complemented the showcased fashion.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <b>Public Relations</b>: A robust public relations campaign, masterminded by Tagline 360, skillfully directed the spotlight onto the participating designers. This initiative significantly enhanced their profiles within the fashion industry, amplifying their presence at the event.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Results</span>
                <br />
                The culmination of these collaborative efforts yielded resounding success:
                <br />
                <b>The F/ROW Fashion Week Event:</b> This remarkable event witnessed the gathering of over 500 attendees who were captivated by the groundbreaking designs and artistic innovations on display. With six distinct shows featuring seven visionary designers, F/ROW FASHION WEEK solidified its position as an emblem of creative excellence in the fashion universe.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                The partnership between F/ROW FASHION WEEK and Tagline 360 stands as a testament to the transformation of visionary aspirations into triumphant realities. The event's seamless execution, the anticipation generated through digital marketing, the flawless talent management, and the diligent public relations campaign all underscore a shared commitment to ushering innovation and inclusivity into the fashion sphere. F/ROW FASHION WEEK is more than just an event; it signifies the remarkable achievements that materialize when creative ingenuity, visionary zeal, and meticulous execution converge, heralding an exciting new era in the world of fashion.
            </div>
        `,
        type: "carousel",
        src: 11,
        gallerySrc: ["/img/projects/main/banners/frow-event/gallery/"],
    },
    {
        key: "frow-event-nyc",
        banner: [],
        // banner: "/img/projects/main/banners/frow-event.png",
        headline: "F/ROW NYC S/S 2025",
        subheadline: `F/ROW Fashion Week - Revolutionizing Fashion Through Innovation`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                F/ROW FASHION WEEK has emerged as a trailblazing platform, committed to propelling innovative designers and artists to the forefront of the fashion landscape. This visionary endeavor was birthed through the collaboration of Errol Isip, a seasoned fashion event specialist, and Jovito Rabelas, the Global President of Tagline 360. Their joint mission was to ignite a fashion revolution by providing a prominent stage for visionary designers and artists to shine.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenges</span>
                <br />
                Tagline 360 encountered a series of multifaceted challenges, each holding the key to the success of F/ROW FASHION WEEK:
                <ol class="my-5 ml-5">
                    <li>1. <b>Conceptualization to Execution</b>: Tagline 360 assumed the monumental task of conceptualizing and meticulously executing every aspect of the event. This encompassed crafting a distinctive event identity, overseeing intricate organizational logistics, and ensuring the flawless realization of F/ROW FASHION WEEK.</li>
                    <li>2. <b>Digital Marketing</b>: The agency was tasked with kindling anticipation and awareness surrounding F/ROW FASHION WEEK while simultaneously thrusting the spotlight onto seven groundbreaking fashion designers. To this end, an organic social media campaign was devised, harnessing the immense reach and influence of various online platforms.</li>
                    <li>3. <b>Talent Management</b>: Tagline 360 undertook the critical role of scouting models who could breathe life into the designers' creative visions. This intricate casting process required a delicate balance to ensure that the chosen models harmoniously embodied the essence of the showcased fashion.</li>
                    <li>4. <b>Public Relations</b>: The agency was entrusted with curating a robust public relations campaign. This initiative was instrumental in elevating the profiles of the participating designers, increasing their visibility within the fashion realm.</li>
                </ol>

            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Solution</span>
                <br />
                In response to these multifaceted challenges, Tagline 360 orchestrated a comprehensive strategy:
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <b>Conceptualization to Execution</b>: Tagline 360 meticulously crafted the event's distinctive identity and diligently oversaw the intricate organizational facets. The selection of the Pacific Design Center as the event's venue exemplified a commitment to sophistication and innovation, making it the perfect canvas for the fashion showcase.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <b>Digital Marketing</b>: Leveraging an organic social media marketing approach, Tagline 360 effectively kindled anticipation and enthusiasm for F/ROW FASHION WEEK. The campaign harnessed the expansive influence of social media platforms, generating buzz and interest around the event.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <b>Talent Management</b>: The agency undertook the intricate task of model scouting, ensuring that each model seamlessly embodied the creative vision of the participating designers. This casting process was executed with precision, resulting in models that perfectly complemented the showcased fashion.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <b>Public Relations</b>: A robust public relations campaign, masterminded by Tagline 360, skillfully directed the spotlight onto the participating designers. This initiative significantly enhanced their profiles within the fashion industry, amplifying their presence at the event.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Results</span>
                <br />
                The culmination of these collaborative efforts yielded resounding success:
                <br />
                <b>The F/ROW Fashion Week Event:</b> This remarkable event witnessed the gathering of over 500 attendees who were captivated by the groundbreaking designs and artistic innovations on display. With six distinct shows featuring seven visionary designers, F/ROW FASHION WEEK solidified its position as an emblem of creative excellence in the fashion universe.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                The partnership between F/ROW FASHION WEEK and Tagline 360 stands as a testament to the transformation of visionary aspirations into triumphant realities. The event's seamless execution, the anticipation generated through digital marketing, the flawless talent management, and the diligent public relations campaign all underscore a shared commitment to ushering innovation and inclusivity into the fashion sphere. F/ROW FASHION WEEK is more than just an event; it signifies the remarkable achievements that materialize when creative ingenuity, visionary zeal, and meticulous execution converge, heralding an exciting new era in the world of fashion.
            </div>
        `,
        type: "carousel",
        src: 9,
        gallerySrc: ["/img/projects/main/banners/frow-event-nyc/gallery/"],
    },
    {
        key: "modelhunt-la-event",
        // banner: "/img/projects/main/banners/modelhunt-la-event.png",
        banner: [],
        headline: "Modelhunt International",
        subheadline: `Modelhunt International La - Reviving Diversity and Inclusivity`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Introduction</span>
                <br />
                Modelhunt International is an organization committed to celebrating the rich tapestry of diversity and fostering inclusivity within the modeling industry. Their mission is to offer a stage where aspiring models of all backgrounds, ages, sizes, and appearances can have their talents acknowledged and cherished. Following their triumphant launch of Modelhunt International in New York City (NYC), the organization set its sights on rekindling the spirit of diversity and inclusivity in its birthplace - Los Angeles (LA).
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenge</span>
                <br />
                The challenge at hand was a two-fold endeavor. Firstly, the aim was to reintroduce Modelhunt International to its hometown of LA, showcasing a commitment to diversity and inclusivity within the modeling sphere. Secondly, Tagline 360 was entrusted with the task of organizing an exceptional event for Modelhunt International LA that would not only spotlight model diversity but also attract a wide range of models to partake in the competition. This dual challenge necessitated a comprehensive approach, blending event planning with social media and email marketing strategies.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Solution</span>
                <br />
                Tagline 360 embarked on an integrated strategy that blended both the event management and digital marketing aspects. Recognizing the influence of digital platforms, they formulated a marketing campaign tailored to resonate with Modelhunt International's audience. Social media marketing initiatives underscored the benefits of participating in Modelhunt International LA, emphasizing exposure to modeling agencies, specialized training, professional photo sessions, and the potential to feature in magazines. Email marketing complemented these efforts, creating a multi-channel approach to engaging with aspiring models.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The event itself was conceptualized as a full-day fashion extravaganza, celebrating the diversity of models from various backgrounds. The event was segmented into three distinct shows, each dedicated to specific model categories such as petite, mature, edgy, mainstream, and more. This meticulous division provided a dedicated platform for every model type, amplifying the spirit of inclusivity and diversity within the fashion industry.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                The Hotel Roosevelt in West Hollywood, LA, was the chosen venue for this spectacular event. Its iconic location set the stage for a memorable evening that attracted over 1500 model applicants, with more than 50 finalists selected to participate. Tagline 360 orchestrated a seamless transition between each segment of the fashion show, ensuring that the diversity and inclusivity message shone brightly throughout.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Results</span>
                <br />
                The event was nothing short of a triumph. Over 1500 models applied for the competition, and more than 50 finalists were selected, highlighting the overwhelming response and enthusiasm. The Hotel Roosevelt in West Hollywood, LA, provided the ideal backdrop, and the three distinct fashion shows showcased models of every kind, ranging from petite to mature, edgy, mainstream, and more.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Conclusion</span>
                <br />
                Through meticulous planning and execution, Tagline 360 successfully resurrected Modelhunt International in LA. The annual fashion show served as a tribute to diversity and inclusivity, exemplifying the institution's unwavering commitment to these values. Modelhunt International's success not only revitalized its presence but also paved the way for aspiring models to embrace their dreams while reinforcing the fashion industry's need for greater representation and inclusivity. This event marked a significant step towards a more diverse and inclusive future in the world of fashion.
            </div>
        `,
        type: "carousel",
        src: 4,
        gallerySrc: ["/img/projects/main/banners/modelhunt-la-event/gallery/"],

    },
    {
        key: "etihad-boston-abd-inaugural",
        banner: [],
        headline: "Etihad Airways",
        subheadline: `Etihad's Luxurious Airbus A350 Launch At Cipriani Wall Street`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Objective</span>
                <br />
                Etihad Airways, a leading UAE airline known for luxury, is making efforts to expand their network and boost brand awareness in a new market. To position itself as the preferred choice for travelers between the Middle East and the United States, the airline marked its inaugural Abu Dhabi to Boston flight. To celebrate this, they partnered with Tagline 360 to host a memorable launch event, which aligns with their strategy of increasing presence in key markets through new direct routes.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenge</span>
                <br />
                Even for an established airline like Etihad Airways, entering a new market required more than showcasing their premium services. The challenge was to create excitement and anticipation for the unique Etihad Airways experience, setting the airline apart from established competitors in the Boston area.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Solution</span>
                <br />
                To address this, Tagline 360 applied their expertise in impactful event planning to craft a strategic approach for Etihad Airways’ Boston debut. They designed an immersive event that showcased Etihad’s luxury offerings and effectively distinguished the airline from its competitors, generating excitement and anticipation for the new route.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Execution</span>
                <br />
                Tagline 360 meticulously designed the event to celebrate their first direct flight from Abu Dhabi to Boston. To showcase what passengers can expect, the event highlighted Etihad Airways’ premium offerings through interactive displays, a virtual aircraft tour, and demonstrations of unique services like the First Apartment and Business Studio. VIP guests, including travel industry professionals, influencers, and key stakeholders, experienced Etihad AIrways’ hospitality firsthand at a formal reception, guided aircraft tours, and a high-profile gala dinner.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Results</span>
                <br />
                With these executions, Etihad Airways effectively established their presence in the Boston market, significantly boosting brand awareness through various marketing and media channels. The event successfully engaged potential customers and industry stakeholders, leading to increased interest and bookings for their new route to Boston.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Impact</span>
                <br />
                The partnership with Tagline 360 proved instrumental in Etihad Airways’ successful market entry. The well-executed event not only boosted their brand visibility but also showcased the airline’s commitment to providing a world-class travel experience. This positive reception positions Etihad Airways for continued growth and fosters customer loyalty in the new Boston market, paving the way for future success for their business.
            </div>
        `,
        type: "carousel",
        src: 12,
        gallerySrc: ["/img/projects/main/banners/etihad-boston-abd-inaugural/gallery/"],
    },
    {
        key: "etihad-a380s-return",
        banner: [],
        headline: "Etihad Airways",
        subheadline: `Tagline 360 Brings Back the Luxurious Etihad A380 to New York in Style`,
        description: `
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Objective</span>
                <br />
                To reassert Etihad Airways’ presence in the competitive transatlantic market and boost brand visibility, the airline celebrated the return of its luxurious A380 to New York. Building on the success of the Abu Dhabi-Boston launch, Etihad Airways once again partnered with Tagline 360 to host a high-profile event that would reinforce its commitment to premium travel experiences.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Challenge</span>
                <br />
                Re-entering the New York market presented a significant challenge for Etihad Airways. They needed to create a memorable event that would recapture public attention and re-introduce the A380 amidst established competitors. With this, they tapped Tagline 360 yet again to create an effective execution.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Solution</span>
                <br />
                Drawing on their extensive experiences in event planning, Tagline 360 developed a comprehensive strategy to ensure a successful comeback. To capture the media’s attention and ignite excitement among potential passengers seeking a luxurious travel experience, the agency hosted an unforgettable high-profile event.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Execution</span>
                <br />
                Tagline 360 crafted an immersive event experience to showcase the A380’s luxurious amenities, including the Residence Suite, First Apartments, and Business Studios. Interactive displays, virtual reality tours, and live demonstrations highlighted these features. VIP guests, such as travel influencers, industry leaders, and loyal Etihad customers, were invited to experience the A380 firsthand through a grand reception, guided aircraft tours, and a gala dinner.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Results</span>
                <br />
                The event was a success, as it significantly increased Etihad Airways’ brand visibility across multiple platforms. Through this, it re-engaged previous customers, attracted new potential passengers, and generated a surge in inquiries and bookings for the A380 routes. The sophisticated consumer journey and the event’s high-end elements served as a fitting preview of the world-class experience offered by Etihad’s A380 airliner.
            </div>
            <div class="sm:text-lg text-md text-[#9C1212] text-justify mb-5">
                <span class="font-bold">Impact</span>
                <br />
                The event orchestrated by Tagline 360 not only marked a pivotal moment for Etihad Airways’ return to New York but also reinforced their position as a leader in luxury travel. The positive reception and renewed customer interest solidified Etihad Airways’ presence in the competitive transatlantic market, setting the stage for continued growth in the years to come.
            </div>
        `,
        type: "carousel",
        src: 5,
        gallerySrc: ["/img/projects/main/banners/etihad-a380s-return/gallery/"],
    },
];

export { ProjectPosts, ProjectPostInner };
