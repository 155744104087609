import { Fade } from "react-awesome-reveal";
import HubspotContactForm from "src/components/forms/HubSpotForms/HubSpotContactForm";
import { Carousel } from "react-responsive-carousel";
import "./Bottom.css";
import Desktop from "./device/desktop";
import Ipad from "./device/ipad";
import Mobile from "./device/mobile";

const group = (items, n) =>
  items.reduce((acc, x, i) => {
    const idx = Math.floor(i / n);
    acc[idx] = [...(acc[idx] || []), x];
    return acc;
  }, []);

const Slides = ({ length, groupIndex }) => {
  const items = Array.from({ length }, (_, index) => index + 1);

  return (
    // <div>
    //   {group(items, 3).map((children, index) => (
    //     <div key={index} className='grid grid-cols-3 gap-2'>
    //       {children.map((x, i) => (
    //         <div className="w-full h-full col-span-1 flex items-center my-3" key={i}>
    //           <img
    //             src={`/img/projects/logos/_${x}.png`}
    //             className="mx-auto lg:max-w-[150px]"
    //             sizes="(max-width: 575px) 300px, 400px"
    //             // srcSet={`${e.img} 400w, ${e.img} 300w`}
    //             alt=""
    //           />
    //           {/* <span key={i}>{x}</span> */}
    //         </div>
    //       ))}
    //     </div>
    //   ))}
    // </div>
    <div>
      {group(items, 3).map((children, index) => (
        <div key={index} className="group-of-3">
          {children.map((x, i) => (
            <span key={i}>{x}</span>
          ))}
        </div>
      ))}
      <p>Group Index: {groupIndex}</p>
    </div>
  );
};

const Bottom = () => {
  return (
    <>
      <div className="desktop-logo">
        <Desktop />
      </div>
      <div className="ipad-logo">
        <Ipad />
      </div>
      <div className="mobile-logo">
        <Mobile />
      </div>
    </>
  );
};
export default Bottom;
