import { Fade } from "react-awesome-reveal";
import { Carousel } from "react-responsive-carousel";

const mobile = [
  {
    title: "",
    img: "/img/projects/logos/updated/1.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/2.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/3.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/4.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/5.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/6.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/7.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/8.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/9.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/10.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/11.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/12.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/13.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/14.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/15.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/16.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/17.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/18.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/19.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/20.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/21.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/22.png",
  },
  {
    title: "",
    img: "/img/projects/logos/updated/23.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/HEROES-OF-HEARING.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/BPI.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/GLOBE-BUSINESS.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/KLOOPIFT.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/GAWAD.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/M-and-Millenium.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/BELO.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/SUPPORT-NINJA.png",
  },
  {
    title: "",
    img: "/img/projects/logos/2023-01/RIZOME-LOGO.png",
  },
];

// Function to split the items into chunks of 4
const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const items = chunkArray(mobile, 2);

const Bottom = () => {
  const totalGroups = Math.ceil(23 / 3);

  return (
    <section
      id="project_bottom_mobile"
      className="bg-[#FFDCC3] md:py-[2rem] md:mb-0 mb-16"
    >
      <Fade cascade={1000}>
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          stopOnHover={true}
          // autoPlay={true}
          infiniteLoop={true}
          interval={2500}
          emulateTouch={true}
          showIndicators={false}
          className="mx-auto max-w-[90%] mobile-logo"
        >
          {items.map((e, i) => (
            <div className="w-full p-[10px] h-full flex items-center justify-center gap-5" key={i}>
              {
                e.map((item, j) => (
                  <div className="flex items-center justify-center" key={j}>
                    <div className="">
                      <img
                        src={item.img}
                        className="max-w-full max-h-[60px]  object-contain"
                        alt=""
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          ))}
        </Carousel>
      </Fade>
    </section>
  );
};
export default Bottom;
