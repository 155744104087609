import { Fade } from "react-awesome-reveal";
const pillars = [
  {
    title: "Beacons of Brand Liturgies",
    description:
      "Our evangelizers will initiate you to a process of bringing out the essence of your brand. Our knowledge of Brandology allows us to create memorable and contagious brand rituals – helping you create powerful customer experiences.",
    image: "/img/landing/p-1-min.png",
  },
  {
    title: "Movers of Culture",
    description:
      "We are passionate about developing timeless customer experiences for brands. We align, develop, and craft your unique brand culture and experiences that set you apart from your competitors.",
    image: "/img/landing/p-2-min.png",
  },
  {
    title: "Builders of Customer Relationship Management",
    description:
      "Your brand’s foundation is built around a robust CRM design to ensure predictability and accountability in its victory. We champion for success and customer relationships that turn followers into brand believers.",
    image: "/img/landing/p-3-min.png",
  },
  {
    title: "Agents of Technology",
    description:
      "We utilize technology, and combine it with the human touch, to create a powerful digital, and on-ground experience for your customers",
    image: "/img/landing/p-4-min.png",
  },
];
const Pillars = () => {
  return (
    <section
      id="pillars"
      className="relative flex justify-center bg-[#FFDCC3] md:py-[8rem] py-4 px-5"
    >
      <Fade>
        <div className="container mx-auto flex justify-center items-center flex-col w-[100vw]">
          <div className="pt-[1rem]">
            <div className="w-fit md:pr-0 md:pl-0 px-[4.5rem]">
              <h3 className="title md:text-3xl text-2xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[55px] md:after:-right-[100px] after:-right-[55px]">
                <span>THE TAGLINE 360 PILLARS OF MASTERY</span>
              </h3>
            </div>
            <div className="w-fit lg:mb-16 mb-5 md:pr-0 md:pl-0 px-[2rem]">
              <div className="text-primary text-lg text-center">
                {/* It takes a definitive set of ideologies to build legendary
                brands. <br className="md:block hidden" /> Here is our way of
                achieving success to ensure your brand transformation. */}
                Expertise and mastery are requirements on building legendary brands <br className="md:block hidden" />
                Tagline 360 has mastery over these pillars that allows us to
                achieve <br className="md:block hidden" />
                success and results for our clients’ brand transformation.
              </div>
            </div>
          </div>
          <Fade cascade={true} damping={1}>
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-y-4 gap-y-10 mb-10 max-w-[1200px] px-2">
              {pillars.map((e, i) => {
                return (
                  <div className="group grid md:grid-cols-[30%_70%] grid-cols-[30%_70%] items-center" key={`pillars-${i}`}>
                    <div className="p-2">
                      <div className="rounded-lg overflow-hidden lg:w-[200px] w-[120px] relative stained-glass stained-glass-pillars">
                        <img src={e.image} alt="" />
                      </div>
                    </div>
                    <div className="lg:pl-[4rem] pl-10 pr-2">
                      <div className="md:text-2xl text-lg text-[#6E1363] leading-[1.75rem] font-semibold mb-3">
                        {e.title}
                      </div>
                      <div className="text-md text-[#9C1212]">
                        {e.description}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Fade>
        </div>
      </Fade>
    </section>
  );
};
export default Pillars;
