import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { IoClose } from "react-icons/io5";
import ReactHtmlParser from 'react-html-parser';
import { Fade, Bounce } from "react-awesome-reveal";
import { Carousel } from "react-responsive-carousel";

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // minWidth: '768px',
    // minHeight: '90vh',
    // overflow: 'hidden',
    padding: '0'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.85)',
    zIndex: 1050,
  }
}

Modal.setAppElement('#root');

const CarouselItems = ({ post, selected }) => {
  var indents = [];
  for (let i = 1; i <= post.src; i++) {
    indents.push(
      <div key={i}>
        <img
          src={`${post.gallerySrc}/_${i}.jpg`}
          alt={`Banner`}
        />
      </div>
    );
  }
  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      stopOnHover={true}
      autoPlay={true}
      infiniteLoop={true}
      interval={2000}
      emulateTouch={true}
      selectedItem={selected - 1}
      className="gallery-carousel overflow-hidden"
    >
      {indents}
    </Carousel>
  );
};

const EventGalleryModal = ({ show, close, post, selected }) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(selected);

  const OpenModal = () => {
    setModalIsOpen(true);
  };

  const CloseModal = () => {
    setModalIsOpen(false);
  };

  const toggleLoader = () => {
    setLoading(!loading);
  };

  return (
    <>
      <Modal
        isOpen={show}
        style={customStyles}
        className="lg:max-w-[768px] w-full m-auto max-h-[90vh] overflow-auto md:absolute relative top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] outline-none"
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <Fade>
          <div className="md:w-10/12 w-11/12 block mx-auto bg-[#FFF3EA]">
            <div className="relative">
              <div className="absolute right-0 flex justify-end w-full">
                <button className="text-4xl text-[#C9BBBB] p-3 z-20" onClick={close}>
                  <IoClose color='#FFFFFF' />
                </button>
              </div>
              <div className='overflow-hidden max-h-full'>
                <div className="overflow-scroll">
                  <div className='select-none'>
                    <CarouselItems post={post} selected={selected} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default EventGalleryModal;
