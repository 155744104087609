import ReactHtmlParser from "react-html-parser";

export default function Bottom() {
  const content = [
    {
      title: "Errolyn Salazar",
      subtitle: "Senior Project Coordinator for Events",
      img: "/img/whoweare/under4",
    },
    {
      title: "Earl Manuel",
      subtitle: "Brand Evangelist / <br />Account Manager",
      img: "/img/whoweare/under2",
    },
    // {
    //   title: "Rhoze Ampuan",
    //   subtitle: "Brand Evangelist / <br />Account Manager",
    //   img: "/img/whoweare/rhoze-2",
    // },
    {
      title: "Darl Barlongay",
      subtitle: "Brand Evangelist / <br />Social Media Manager",
      img: "/img/whoweare/darl",
    },
    {
      title: "Erika De Jesus",
      subtitle: "Brand Evangelist / <br />Art Director",
      img: "/img/whoweare/erika",
    },
  ];

  return (
    <div className="grid md:grid-cols-3 md:gap-10 gap-5 max-w-[1200px] md:mt-10">
      {content.map((e, i) => {
        return (
          <div key={e.title} className={`md:w-auto ${i == content.length - 1 ? 'col-start-2' : ''}`}> {/* center the last part*/}
            <div className="relative stained-glass-- stained-glass-messenger flex flex-col w-[100%] mx-auto">
              <div className="icon-wrapper text-center">
                <img
                  src={`${e.img}.png`}
                  className="mx-auto lg:max-w-[300px]"
                  sizes="(max-width: 575px) 300px, 400px"
                  srcSet={`${e.img}.png 400w, ${e.img}.png 300w`}
                  alt=""
                />
              </div>
            </div>
            <div className="text-center text-[#6E1363] leading-2 md:text-[23px] text-xl uppercase">
              <h4>{ReactHtmlParser(e.title)}</h4>
            </div>
            <div className=" mb-3 text-center text-[#6E1363] leading-2 md:text-[20px] text-md md:mb-3 uppercase">
              <h4>{ReactHtmlParser(e.subtitle)}</h4>
            </div>
          </div>
        );
      })}
    </div>
  );
}
