import { Fade } from "react-awesome-reveal";
import WorkWithUsModal from "../modal/WorkWithUsModal";
import { useState } from "react";

const services = [
  //   {
  //     title: "BRAND RELIGION RETREAT",
  //     description:
  //       "Let’s kick start your brand religion by fleshing out your brand statement with a comprehensive 3-hour session.",
  //     long_description: `
  //         <ul className='text-[#9C1212] list-disc'>
  //             <li><span className='text-bold'>Brand Mythology</span> - The purpose is spelled out as an experience to provide a strong emotional connection to the consumer.</li>
  //             <li><span className='text-bold'>Consumer Path to Purchase</span> - Systematic escalation of the consumer experience to deliver the emotion we want them to feel.</li>
  //             <li><span className='text-bold'>Brand Rituals Starter Kit</span> - The Brand Metaphor and start-up brand rituals across different touchpoints.</li>
  //             <li><span className='text-bold'>Conversion Technology</span> - Technology Stack Proposals on technology infrastructure to help clients nurture and convert their consumers.</li>
  //         </ul>
  //         `,
  //     price: `
  //             <div className='font-bold text-2xl'>$2,500</div>
  //             <div className='text-md'>(One time payment)</div>
  //         `,
  //     icon_path: "/img/work-with-us/WWU-Religious-Fantastic.png",
  //     region: "na",
  //     portalId: "21865908",
  //     formId: "86a84e69-cf3e-4ee3-8fd6-0fd9056e8a18",
  //   },
  {
    title: "ACOLYTE",
    description:
      "Looking to automate a dynamic consumer experience to your loyal fans? Our basic package is designed to get your brand religion infrastructure up and running.",
    long_description: `
            <ul className='text-[#9C1212] list-disc'>
                <li>3 Blog Articles / Month</li>
                <li>Blog Promotion</li>
                <li>Social Publishing and Monitoring</li>
                <li>Keyword Research</li>
                <li>On-Page SEO</li>
                <li>1 Lead Generation Campaign / Quarter</li>
                <li>Automated Email Newsletter to Promote New Products and Services</li>
                <li>6 Emails Builds Per Year (Creation, Send, Workflow Automation)</li>
                <li>Hubspot Set Up and Training</li>
                <li>
                  <br/>
                  <i>Hubspot subscription is separate depending on the package that the client needs</i>
                </li>
            </ul>
        `,
    price: `
      <div className='font-bold text-2xl'>$ 2,500 / month</div>
      <div className='font-bold text-2xl'>$2,250 / month (Billed Annually)</div>
    `,
    icon_path: "/img/work-with-us/WWU-Acolyte.png",
    region: "na",
    portalId: "21865908",
    formId: "931be1ce-8fcc-4d1a-bf64-f3fdae99bb13",
  },
  {
    title: "FOLLOWER",
    description:
      "Grow your business exponentially with our mid-range package. Let’s use your database to take your brand religion to the next level by actively nurturing your existing clients.",
    long_description: `
            <ul className='text-[#9C1212] list-disc'>
                <li>8 Blog Articles / Month</li>
                <li>Blog Promotion</li>
                <li>Social Publishing and Monitoring</li>
                <li>Keyword Research</li>
                <li>On-Page SEO</li>
                <li>1 Lead Generation Campaign/Month</li>
                <li>Automated Email Newsletter to Promote New Products and Services</li>
                <li>Ten Emails Builds Per Year (Creation, Send, Workflow Automation Set Up)</li>
                <li>Mapping Smart Content to Buying Cycle</li>
                <li>Comprehensive Brand Ritual Design for On-Ground and Online (On-ground execution production costs can be added)</li>
                <li>Quarterly Company Newsletter</li>
                <li>Hubspot Set-up and Training</li>
                <li>
                  <br/>
                  <i>Hubspot subscription fees are separate and determined according to client needs</i>
                </li>
            </ul>
        `,
    price: `
            <div className='font-bold text-2xl'>$ 5,000 / month</div>
            <div className='font-bold text-2xl'>$ 4,500 / month (Billed Annually)</div>
        `,
    icon_path: "/img/work-with-us/WWU-Believer.png",
    region: "na",
    portalId: "21865908",
    formId: "ec990061-7cc1-4c40-a4c6-49142b272360",
  },
  {
    title: "TRUE BELIEVER",
    description:
      "This powerhouse service with aggressive growth goals and complex needs can convert consumers into ardent followers. We’ll help turn your products and services into an overall legendary brand.",
    long_description: `
            <ul className='text-[#9C1212] list-disc'>
                <li>20 Blog Articles / Month</li>
                <li>Blog Promotion</li>
                <li>Social Publishing and Monitoring</li>
                <li>Keyword Research</li>
                <li>On-Page SEO</li>
                <li>1 Lead Generation Campaign/Month</li>
                <li>Automated Email Newsletter to Promote New Products and Services</li>
                <li>12 Emails Builds per year (Creation, Send, Workflow, Automation, Set-up)</li>
                <li>Mapping Smart Content to Buying Cycle</li>
                <li>Comprehensive Brand Ritual Design for On-Ground and Online (On ground execution production costs can be added)</li>
                <li>Customer Service through Hubspot System Set-up</li>
                <li>One Customer Service Seat</li>
                <li>Chatbot Programming and Setup</li>
                <li>Quarterly Company Newsletter</li>
                <li>Website Update and Design</li>
                <li>Hubspot Set-up and Training</li>
                <li>
                  <br/>
                  <i>Hubspot subscription fees are separate and determined according to client needs</i>
                </li>
            </ul>
        `,
    price: `
        <div className='font-bold text-2xl'>$ 10,000 / month</div>
        <div className='font-bold text-2xl'>$ 9,200 / month (Billed Annually)</div>
        `,
    icon_path: "/img/work-with-us/WWU-Religious-Fantastic.png",
    region: "na",
    portalId: "21865908",
    formId: "e61e47a8-e095-490a-9b47-b8f4e3756ece",
  },
];
const Convert = (props) => {
  // ** Props
  const { modal, showModal, setShowModal } = props;

  // ** States
  // const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  const handleClick = (i) => {
    setShowModal(true);
    setSelected(services[i]);
  };

  return (
    <section
      id="convertWithUs"
      className="relative flex justify-center bg-[#FFDEC1] md:py-[8rem] py-4 px-5"
    >
      <div className="upper-left-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade 
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="left"
          >
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
              className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="upper-right-star w-[100%] h-[150px]">
        <div className="relative ">
          <Fade
            cascade={true}
            fraction={1}
            damping={2}
            duration={300}
            direction="right"
          >
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
              className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="container mx-auto flex justify-center items-center flex-col w-[100vw] pb-[4.5rem]">
        <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-16">
          <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
            <span>CONVERT WITH US NOW</span>
          </h3>
        </div>
        <div className="sm:text-lg text-md text-[#9C1212] text-center mb-4">
          {/* There are levels of commitment that our brand evangelists can provide for your brand's success. <br className="md:block hidden" /> Choose your package and create your brand ritual now! */}
          Our Brandology packages serve as an entire marketing and sales team to
          help your business succeed. <br className="md:block hidden" />
          We’ll generate awareness, nurture prospects, and convert customers,
          <br className="md:block hidden" />
          while you focus on client servicing. Each Tagline 360 tier includes:
        </div>

        <div className="grid md:grid-cols-2 md:mb-16 mb-10">
          <div className="flex items-center">
            <ul
              className="px-10 text-lg text-[#9C1212]"
              style={{ listStyleType: "unset" }}
            >
              <li>Dedicated Account Manager</li>
              <li>Marketing Strategist</li>
              <li>Editorial Content Calendar</li>
            </ul>
          </div>
          <div className="flex items-center">
            <ul
              className="px-10 text-lg text-[#9C1212]"
              style={{ listStyleType: "unset" }}
            >
              <li>Annual Marketing Plan</li>
              <li>Weekly Status Calls</li>
              <li>Implementation Team</li>
            </ul>
          </div>
        </div>

        <Fade duration={2000} cascade={true} damping={1}>
          <div className="grid md:grid-cols-3 md:gap-10 gap-5 md:mb-16 mb-10 max-w-[1200px]">
            {services.map((e, i) => {
              return (
                // <div key={e.title} className="relative stained-glass bg-[#F6ECB3] stained-glass-recieve lg:w-[400px] lg:h-[400px] md:w-[300px] md:h-[300px] w-[300px] h-[300px] flex flex-col justify-center md:py-10 py-5 px-[50px]" style={{ backgroundImage: "url('img/others/convert-with-us-tile-background.svg')", backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundPosition: 'center' }}>
                <div key={e.title} className="w-[400px] h-[400px]">
                  <div
                    className="relative stained-glass stained-glass-recieve flex flex-col w-[83%] h-[98%] mx-auto"
                    style={{
                      backgroundImage:
                        "url('img/others/convert-with-us-tile-background.svg')",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                      backgroundPosition: "initial",
                      backgroundColor: "#F6ECB3",
                      borderRadius: "1.25rem",
                    }}
                  >
                    <div
                      className="px-8 my-10 z-[99]"
                      style={{ height: "inherit" }}
                    >
                      <div className="icon-wrapper text-center">
                        <img
                          src={e.icon_path}
                          className="mx-auto max-w-[40%]"
                          alt=""
                        />
                      </div>
                      <div className="font-bold h-[50px] mb-3 text-center text-[#6E1363] leading-2 md:text-[24px] text-xl md:mb-3 mb-2">
                        {e.title}
                      </div>
                      <div className="text-sm h-[100px] mb-5 text-[#9C1212] leading-2">
                        {e.description}
                      </div>
                      <div className="text-center">
                        <button
                          onClick={() => handleClick(i)}
                          className="w-[80%] rounded-full bg-[#155D86] text-white hover:bg-black px-5 py-1 w-40 transition ease-in duration-200 z-[99] text-center lg:text-md text-sm"
                        >
                          <span>CHECKOUT PRICES</span>
                        </button>
                      </div>
                    </div>
                    {/* <div className="icon-wrapper">
                                                <img src={e.icon_path} className="max-w-[30%] max-h-[30%] my-2" alt="" />
                                            </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </Fade>
      </div>
      <div className="bottom-left-star z-9 w-[100%] h-[150px]">
        <div className="relative ">
          <Fade cascade={true} fraction={1} damping={1} duration={500}>
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7888-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7887-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-left/IMG_7889-min.PNG"
              className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      <div className="bottom-right-star z-9 w-[100%] h-[150px]">
        <div className="relative ">
          <Fade cascade={true} fraction={1} damping={1} duration={500}>
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7885-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7884-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
            <img
              src="/img/landing/stained-glass/lower-right/IMG_7886-min.PNG"
              className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
              alt=""
            />
          </Fade>
        </div>
      </div>
      {showModal && (
        <WorkWithUsModal
          show={showModal}
          close={() => setShowModal(false)}
          selected={selected}
          modal={modal}
        />
      )}
    </section>
  );
};
export default Convert;
