import { useState } from "react";
import LengendaryBrandModal from "../modal/LengendaryBrandModal";
import ReactHtmlParser from "react-html-parser";
import { Fade } from "react-awesome-reveal";
import { useEffect } from "react";
import Vimeo from "src/components/vimeo";
import { ProjectPosts } from "src/components/posts/ProjectPosts";
import Project from "src/pages/Projects/Project";
import { Carousel } from "react-responsive-carousel";
// import Image1 from "/img/whoweare/under4";
import "./project.scss";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";
import GoBack from "../nav/GoBack";

const Manifestitions = ({ posts, hasPartners }) => {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState();

    const body = document.querySelector("body");
    useEffect(() => {
        if (showModal) {
            body.style.overflow = "hidden";
        } else {
            body.style.overflow = "initial";
        }
    }, [showModal]);

    const CarouselItems = ({ data }) => {
        var indents = [];
        for (let i = 1; i <= data.src; i++) {
            indents.push(
                <div key={i}>
                    <img
                        src={`/img/projects/${data.id}/_${i}.jpg`}
                        alt={`${data.title} Banner`}
                    />
                </div>
            );
        }
        console.log(data);
        return (
            <Carousel
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                stopOnHover={true}
                autoPlay={true}
                infiniteLoop={true}
                interval={2000}
                emulateTouch={true}
                className="overflow-hidden"
            >
                {indents}
            </Carousel>
        );
    };

    return (
        <>
            <section
                id="manifestation"
                className="relative bg-[#FFDEC1] md:py-[8rem] py-4 overflow-hidden"
            >
                <div className="upper-left-star w-[100%] h-[150px]">
                    <div className="relative ">
                        <Fade
                            cascade={true}
                            fraction={1}
                            damping={2}
                            duration={300}
                            direction="left"
                        >
                            <img
                                src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
                                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
                                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
                                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>
                <div className="upper-right-star w-[100%] h-[150px]">
                    <div className="relative ">
                        <Fade
                            cascade={true}
                            fraction={1}
                            damping={2}
                            duration={300}
                            direction="right"
                        >
                            <img
                                src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
                                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
                                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
                                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>
                <div
                    className="container mx-auto flex justify-center items-center flex-col px-5"
                    id="project_header"
                >
                    <div className="md:w-[40%] w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] md:mt-0 mt-[80px]">
                        <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                            <span>
                                ENGAGING EVENTS THAT RESONATE WITH TODAY’S
                                GENERATION
                            </span>
                        </h3>
                    </div>
                    <div className="sm:text-lg text-md text-[#9C1212] text-center mb-16">We are
                        dedicated to crafting rituals and experiences that
                        elevate brands and <br className="lg:block hidden" />
                        mastering how we adapt to the ever-changing industry.  <br className="lg:block hidden" />
                        Here are the remarkable productions we’ve executed
                        showcasing our expertise.
                    </div>
                </div>
                <div className={`max-w-[1920px] block mx-auto`}>
                    <div
                        id="projects"
                        className="flex flex-wrap justify-center md:gap-x-16 gap-x-7 md:gap-y-8 gap-y-4 md:mx-8 mx-5"
                    >
                        {posts.map((e, i) => {
                            return (
                                <div key={e.title} className="md:w-auto">
                                    <div className="relative stained-glass-- stained-glass-messenger flex flex-col w-[100%] mx-auto">
                                        <Link to={e.src}>
                                            <img
                                                src={`${e.img}`}
                                                className="mx-auto lg:max-w-[300px]"
                                                sizes="(max-width: 575px) 300px, 400px"
                                                srcSet={`${e.img} 400w, ${e.img} 300w`}
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div>
                        {/* Partners
                        {hasPartners ? (
                            <Bottom />
                        ) : (
                            <div className="mb-10 self-end mt-10">
                                <GoBack />
                            </div>
                        )} */}
                        <div className="desktop-logo">
                            <div className="bottom-left-star z-9 w-[100%] h-[150px]">
                                <div className="relative ">
                                    <Fade
                                        cascade={true}
                                        fraction={1}
                                        damping={1}
                                        duration={500}
                                    >
                                        <img
                                            src="/img/landing/stained-glass/lower-left/IMG_7888-min.PNG"
                                            className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                            alt=""
                                        />
                                        <img
                                            src="/img/landing/stained-glass/lower-left/IMG_7887-min.PNG"
                                            className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                            alt=""
                                        />
                                        <img
                                            src="/img/landing/stained-glass/lower-left/IMG_7889-min.PNG"
                                            className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                            alt=""
                                        />
                                    </Fade>
                                </div>
                            </div>
                            <div className="bottom-right-star z-9 w-[100%] h-[150px]">
                                <div className="relative ">
                                    <Fade
                                        cascade={true}
                                        fraction={1}
                                        damping={1}
                                        duration={500}
                                    >
                                        <img
                                            src="/img/landing/stained-glass/lower-right/IMG_7885-min.PNG"
                                            className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                            alt=""
                                        />
                                        <img
                                            src="/img/landing/stained-glass/lower-right/IMG_7884-min.PNG"
                                            className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                            alt=""
                                        />
                                        <img
                                            src="/img/landing/stained-glass/lower-right/IMG_7886-min.PNG"
                                            className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                            alt=""
                                        />
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showModal && (
                    <LengendaryBrandModal
                        show={true}
                        close={() => setShowModal(false)}
                        selected={selected}
                    />
                )}
            </section>
        </>
    );
};
export default Manifestitions;
