import React, { useState, useEffect } from 'react';
import { ProjectPosts, ProjectPostInner } from 'src/components/posts/ProjectPosts';
import Vimeo from 'src/components/vimeo';
import ReactHtmlParser from "react-html-parser";
import { Carousel } from 'react-responsive-carousel';
import Error404 from "src/pages/404"
import Media from './Media';
import GoBack from 'src/components/nav/GoBack';

import { useNavigate } from 'react-router-dom'


function InnerPage2() {
  const [post, getPost] = useState({});

  const url = window.location.href;
  const array = url.split('/');
  const key = array[array.length - 1];

  useEffect(() => {

    try {
      getPost(ProjectPostInner.find(post => post.key === key) || null);
    } catch (err) {
      console.log(err)
    }

  }, [])

  if (post === null)
    return <Error404 />;

  return (
    <div className="max-w-[1920px] block mx-auto">
      <div id="projects" >
        <section
          id="titleSection"
          className="relative bg-[#FFDEC1]"
        >
          <div className="mx-auto bg-[#000000] md:mb-10">
            <div className="container- mx-auto max-w-[1080px]-">
              <div className="block overflow-hidden">
                <Media data={post} />
              </div>
            </div>
          </div>

          <div className="container mx-auto flex justify-center items-center flex-col max-w-[1080px] px-5">
            <div className="w-fit md:pr-0 md:pl-0 px-[2rem] sm:mt-0 mt-10 mb-10">
              <h3 className="md:text-2xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                <span>{ReactHtmlParser(post.headline)}</span>
              </h3>
              <h4 className="md:text-xl text-lg text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                <span>{ReactHtmlParser(post.subheadline)}</span>
              </h4>
            </div>
            <div className="sm:text-lg text-md text-[#9C1212] text-justify mb-16">
              {ReactHtmlParser(post.description)}
            </div>
            <div className='mb-10 self-end'>
              <GoBack />
            </div>
          </div>
        </section>
      </div>
    </div>


  );
}

export default InnerPage2;
