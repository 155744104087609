import React, { useState, useEffect } from 'react';
import Layout from "src/pages/Layout"
import Home from "src/pages/Home"
import WorkWithUs from "src/pages/WorkWithUs"
import About from "src/pages/About"
import Projects from 'src/pages/Projects';
// import Project from 'src/pages/Projects/Project';
import InnerProjects from 'src/pages/Projects/[inner]';
import InnerPage from 'src/pages/Projects/[inner]/InnerPage';
import WhoWeAre from 'src/pages/WhoWeAre';
import Consultancy from 'src/pages/Constultancy';
import Branding from 'src/pages/Branding';
import Platform from 'src/pages/Platform';
import Activation from 'src/pages/Activation';
import Event from 'src/pages/Event';
import Production from 'src/pages/Production';
// import Landing from 'src/pages/Landing-12212022';
import Blogs from 'src/pages/Blogs';
import Error404 from "src/pages/404"
import RouteProgress from 'src/components/routes/RouteProgress';

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                // behavior: 'fast'
            });
        }, 0);
    }, [pathname]);

    return null;
};


export default function App() {
    // const url = window.location.href;
    // const array = url.split('/');
    // const key = array[array.length - 1];

    // useEffect(() => {
    //     console.log(array)
    //     const handleRouteChangeComplete = () => {
    //         setTimeout(() => {
    //             window.scroll({
    //                 top: 0,
    //                 left: 0,
    //                 behavior: 'smooth'
    //             });
    //         }, 0);
    //     };

    //     window.addEventListener('popstate', handleRouteChangeComplete);

    //     return () => {
    //         window.removeEventListener('popstate', handleRouteChangeComplete);
    //     };
    // }, [array]);

    return (
        <>
            <BrowserRouter>
                <RouteProgress />
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Layout />} >
                        <Route index element={<Home />} />
                        <Route path="work-with-us" element={<WorkWithUs />} />
                        <Route path="about-us" element={<About />} />
                        <Route path="who-we-are" element={<WhoWeAre />} />
                        <Route path="projects" element={<Projects />} />
                        <Route path="projects/:id" element={<InnerProjects />} />
                        <Route path="projects/:id/:id" element={<InnerPage />} />
                        {/* <Route path="Landing" element={<Landing />} /> */}
                        <Route path="blogs" element={<Blogs />} />
                        <Route path="consultancy" element={<Consultancy />} />
                        <Route path="branding" element={<Branding />} />
                        <Route path="platform-development" element={<Platform />} />
                        <Route path="activations" element={<Activation />} />
                        <Route path="events/" element={<Event />} />
                        <Route path="events/:id/" element={<InnerPage />} />
                        <Route path="*" element={<Error404 />} />
                        <Route path="production" element={<Production/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}
