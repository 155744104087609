import React, { useState, useEffect, useRef } from "react";
import { Fade, Bounce } from "react-awesome-reveal";
import GoBack from 'src/components/nav/GoBack';
// import "@styles/carousel.css"
import "../../../styles/carousel.css"


export default function InnerPageVideo({ post }) {

    const [activeIndex, setActiveIndex] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const [carouselActive, setCarouselActive] = useState(false);

    const [showVideo, setShowVideo] = useState(false);
    const [selected, setSelected] = useState();

    const handleClick = () => {
        setShowVideo(true);
    };

    const playButton = "/img/production/play-button.png";

    return (
        <div className="relative bg-[#FFDEC1] md:py-[8rem] py-4 overflow-hidden">
            <div className="upper-left-star w-[100%] h-[150px]">
                <div className="relative ">
                    <Fade
                        cascade={true}
                        fraction={1}
                        damping={2}
                        duration={300}
                        direction="left"
                    >
                        <img
                            src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
                            className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                            alt=""
                        />
                        <img
                            src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
                            className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                            alt=""
                        />
                        <img
                            src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
                            className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                            alt=""
                        />
                    </Fade>
                </div>
            </div>
            <div className="upper-right-star w-[100%] h-[150px]">
                <div className="relative ">
                    <Fade
                        cascade={true}
                        fraction={1}
                        damping={2}
                        duration={300}
                        direction="right"
                    >
                        <img
                            src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
                            className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                            alt=""
                        />
                        <img
                            src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
                            className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                            alt=""
                        />
                        <img
                            src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
                            className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                            alt=""
                        />
                    </Fade>
                </div>
            </div>
            <div
                className="container mx-auto flex justify-center items-center flex-col px-5"
                id="project_header"
            >
                {!!post.headline &&
                    <div className="w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] md:mt-0 mt-[80px]">
                        <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                            <span>
                                {post.headline}
                            </span>
                        </h3>
                    </div>
                }
                {!!post.subheadline &&
                    <div className="sm:text-lg text-md text-[#9C1212] text-center sm:mb-16 mb-5">
                        {post.subheadline}
                    </div>
                }
            </div>
            <div className={`max-w-[1920px] flex justify-center mx-auto px-5`}>
                <div className="md:mb-0 mb-[100px]">
                    <div className="stained-glass-- stained-glass-messenger relative">
                        <div
                            className={`cursor-pointer  relative z-10`}
                            onClick={handleClick}
                        >
                            <Fade>
                                <div className="group">
                                    <div className="lg:max-w-[760px] ipad-pro">
                                        <img src={post.videoThumb} alt="" />
                                    </div>
                                    <img
                                        src={playButton}
                                        alt=""
                                        className="absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] md:w-[90px] w-14 z-40 playButton"
                                    />
                                </div>
                            </Fade>
                        </div>
                        {(!!post.video && showVideo) &&
                            <video
                                id="video"
                                className={`h-[82%] w-[93%] object-cover top-[9%] left-[3.35%] outline outline-1 outline-red-500 rounded-md absolute ${showVideo ? 'z-20' : 'z-0'}`}
                                controls
                                autoPlay
                                preload="yes"
                                playsInline
                            >
                                <source src={post.video} type="video/mp4" />
                            </video>
                        }
                    </div>
                    <div className='mt-16'>
                        <div>
                            <GoBack />
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div className="bottom-left-star z-9 w-[100%] h-[150px]">
                    <div className="relative ">
                        <Fade
                            cascade={true}
                            fraction={1}
                            damping={1}
                            duration={500}
                        >
                            <img
                                src="/img/landing/stained-glass/lower-left/IMG_7888-min.PNG"
                                className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/lower-left/IMG_7887-min.PNG"
                                className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/lower-left/IMG_7889-min.PNG"
                                className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>
                <div className="bottom-right-star z-9 w-[100%] h-[150px]">
                    <div className="relative ">
                        <Fade
                            cascade={true}
                            fraction={1}
                            damping={1}
                            duration={500}
                        >
                            <img
                                src="/img/landing/stained-glass/lower-right/IMG_7885-min.PNG"
                                className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/lower-right/IMG_7884-min.PNG"
                                className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/lower-right/IMG_7886-min.PNG"
                                className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    )
}