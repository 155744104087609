import { useState } from "react";
import LengendaryBrandModal from "../modal/LengendaryBrandModal";
import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import WorkWithUsModal from "../modal/WorkWithUsModal";
import ReactHtmlParser from "react-html-parser";
import Bottom from "./Messenger-bottom";
import Carousel from "./LandingCarousel";

const Manifestitions = ({ posts, hasPartners }) => {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState();

    const body = document.querySelector("body");
    useEffect(() => {
        if (showModal) {
            body.style.overflow = "hidden";
        } else {
            body.style.overflow = "initial";
        }
    }, [showModal]);

    return (
        <>
            <section
                id="manifestation"
                className="relative bg-[#FFDEC1] md:py-[8rem] py-4 overflow-hidden"
            >
                <div className="upper-left-star w-[100%] h-[150px]">
                    <div className="relative ">
                        <Fade
                            cascade={true}
                            fraction={1}
                            damping={2}
                            duration={300}
                            direction="left"
                        >
                            <img
                                src="/img/landing/stained-glass/upper-left/IMG_7880-min.PNG"
                                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/upper-left/IMG_7878-min.PNG"
                                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/upper-left/IMG_7879-min.PNG"
                                className="absolute top-0 left-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>
                <div className="upper-right-star w-[100%] h-[150px]">
                    <div className="relative ">
                        <Fade
                            cascade={true}
                            fraction={1}
                            damping={2}
                            duration={300}
                            direction="right"
                        >
                            <img
                                src="/img/landing/stained-glass/upper-right/IMG_7881-min.PNG"
                                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/upper-right/IMG_7882-min.PNG"
                                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                            <img
                                src="/img/landing/stained-glass/upper-right/IMG_7883-min.PNG"
                                className="absolute top-0 right-0 md:max-w-[250px] max-w-[100px]"
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>
                <div
                    className="container mx-auto flex justify-center items-center flex-col px-5"
                    id="project_header"
                >
                    <div className="lg:w-[40%] md:w-[60%] w-fit mb-3 md:pr-0 md:pl-0 px-[2rem] md:mt-0 mt-[50px]">
                        <h3 className="title md:text-3xl text-xl text-center text-[#6E1363] md:before:-left-[100px] before:-left-[75px] before:-width-[60px] md:after:-right-[100px] after:-right-[75px] uppercase">
                            <span>
                                Videos that Captivate and Speak to Today’s
                                Generation
                            </span>
                        </h3>
                    </div>
                    <div className="sm:text-lg text-md text-[#9C1212] text-center">
                        Our videos are storytelling masterpieces, crafted to
                        resonate with today's generation
                        <br class="lg:block hidden" /> and make a lasting impact
                        - perfect for electrifying events and social media
                        engagement
                    </div>
                </div>
                <div className={`max-w-[1920px] block mx-auto`}>
                    <div
                        id="projects"
                        className="flex flex-wrap justify-center md:gap-x-16 gap-x-7 md:gap-y-8 gap-y-4 md:mx-8 mx-5"
                    >
                        <Fade duration={2000} cascade={true} damping={1}>
                            <div className="mb-3 max-w-[1040px]">
                                <Carousel />
                            </div>
                        </Fade>
                    </div>

                    <div>
                        <div className="bottom-left-star z-9 w-[100%] h-[150px]">
                            <div className="relative ">
                                <Fade
                                    cascade={true}
                                    fraction={1}
                                    damping={1}
                                    duration={500}
                                >
                                    <img
                                        src="/img/landing/stained-glass/lower-left/IMG_7888-min.PNG"
                                        className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                        alt=""
                                    />
                                    <img
                                        src="/img/landing/stained-glass/lower-left/IMG_7887-min.PNG"
                                        className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                        alt=""
                                    />
                                    <img
                                        src="/img/landing/stained-glass/lower-left/IMG_7889-min.PNG"
                                        className="absolute bottom-0 left-0 md:max-w-[250px] max-w-[100px]"
                                        alt=""
                                    />
                                </Fade>
                            </div>
                        </div>
                        <div className="bottom-right-star z-9 w-[100%] h-[150px]">
                            <div className="relative ">
                                <Fade
                                    cascade={true}
                                    fraction={1}
                                    damping={1}
                                    duration={500}
                                >
                                    <img
                                        src="/img/landing/stained-glass/lower-right/IMG_7885-min.PNG"
                                        className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                        alt=""
                                    />
                                    <img
                                        src="/img/landing/stained-glass/lower-right/IMG_7884-min.PNG"
                                        className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                        alt=""
                                    />
                                    <img
                                        src="/img/landing/stained-glass/lower-right/IMG_7886-min.PNG"
                                        className="absolute bottom-0 right-0 md:max-w-[250px] max-w-[100px]"
                                        alt=""
                                    />
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Manifestitions;
