import Home from 'src/pages/Home';
export const PrimaryMenu = [
    {
        label: 'Work With Us',
        path: '/work-with-us',
        element: Home
    },
    {
        label: 'About Us',
        path: '/about-us'
    },

    {
        label: 'Who We Are',
        path: '/who-we-are'
    },

    {
        label: 'Our Projects',
        path: '/projects'
    },
    {
        label: 'Events',
        path: '/events'
    },
    {
        label: 'Production',
        path: '/production',
    },
    {
        label: 'Coaching & Trainings',
        path: 'https://skills-simplified.com/courses',
    },
    {
        label: 'Blogs',
        path: '/blogs'
    },

    // {
    //     label: 'Blogs',
    //     path: '#'
    // },
    // {
    //     label: 'Contact Us',
    //     path: '#'
    // },
    // {
    //     label: 'Book now',
    //     path: '#'
    // },
]

